<div id="canvas">
    <div id="box_wrapper">
        <section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
            <div class="container">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <h2>Advisory Board Members</h2>
                  <ol class="breadcrumb greylinks color4">
                    <li> <a href="index.html"> Home </a> </li>
                    <li class="active">About Us</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section class="ls section_padding_top_50 section_padding_bottom_50">
            <div class="container">
              <div class="row pb-20">
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/ShenTong.jpg" alt="ShenTong" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Shen Tong</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> He has been an impact investor with a family office, serial entrepreneur, social activist, writer/poet, public speaker, and mentor. <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/shentong" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/EstefaniaAlmeidaCordero.jpg" alt="EstefaniaAlmeidaCordero" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Estefania Almeida Cordero</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> Global Portfolio Manager at Brinc.io <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/estefania-almeida-cordero-b55a5428" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pb-20">
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/ManavGupta.jpg" alt="Manav Gupta" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Manav Gupta</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i>
                         Founder & CEO at Brinc.io <i class="fa fa-quote-right" aria-hidden="true"></i>
                         Manav works with startups, corporates and manufacturers from all over the world 
                         to meet market demand for connected devices and is familiar with the hurdles hardware 
                         companies face in getting products that drive deep consumer engagement to market. 
                      </p>
                      <a href="https://hk.linkedin.com/in/manavg" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/TomStevens.jpg" alt="Tom Stevens" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Tom Stevens</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> NY, Wash DC, Illinois and UK Attorney, US and EU Investor. Professor, Bologna, Italy. <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/tom-stevens-08877b10" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pb-20">
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/DavidPostolski.jpg" alt="David Postolski" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>David Postolski</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> Partner, Gearhart Law; CLE Officer- ABA IP Law Section; Faculty- Parsons School of Design. <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/davidpostolski" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/SaraRGross.jpg" alt="Sara R Gross" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Sara R. Gross</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> Founder & Lead. Harvesting Lives/CosechandoVidas/Jeevan Samposhan: Regenerative Agriculture and Livelihoods Initiative. <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/saragross" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pb-20">
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/DimoDimov.jpg" alt="Dimo Dimov" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Dimo Dimov</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> Spatial Architect & Remote Sensing Expert @ Geocledian GmbH. <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/dimo-dimov-b34461ba" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/hatkinson.jpeg" alt="hatkinson" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Hatkinson</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> Making Food Work: Digital media pro building sustainable food+tech businesses, driving local food system change. <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/hatkinson" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pb-20">
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/ScottSmedresman.jpg" alt="Scott Smedresman" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Scott Smedresman</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> Partner at McCarter & English, LLP. <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/scottsmedresman" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/ChristineRico.jpg" alt="Christine Rico" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Christine Rico</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> CFO & Advisory services for women business leaders seeking financial success & social impact I Certified Profit First Professional. <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/christinerico" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pb-20">
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/VRajesh.jpg" alt="V Rajesh" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>V Rajesh</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> Expertise in the field of Trademark I Patent I Copyright I Industrial Design with the experience of over 20 years. <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/velandyrajesh" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/dr-ramesh-mittal.jpg" alt="Dr. Ramesh Mittal" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Dr. Ramesh Mittal</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> Director at CCS National Institue of Agricultural Marketing. <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/dr-ramesh-mittal-70206420" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pb-20">
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/SatKumarTomer.jpg" alt="Sat Kumar Tomer" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Sat Kumar Tomer</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> He has been an impact investor with a family office, serial entrepreneur, social activist, writer/poet, public speaker, and mentor. <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://in.linkedin.com/in/sat-kumar-tomer" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/Manohar Reddy.jpeg" alt="Manohar Reddy" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Manohar Reddy</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> Advisory for Government Relations Business, A.P<i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pb-20">
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/RaghuVamshiReddyPidatala.jpg" alt="Raghu Vamshi Reddy Pidatala" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Raghu Vamshi Reddy Pidatala</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> Chief Executive Officer at Blueprint. <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/raghu-vamshi-reddy-pidatala-0b4a3a73" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/JohnsonKorlapati.jpg" alt="JohnsonKorlapati" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Johnson Korlapati</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> Graphic Designer at Microsoft <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/johnson-korlapati-36a79b131" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pb-20">
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/SureshKadari.jpg" alt="SureshKadari" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Suresh Kadari</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> Technocrat | AI Practitioner | Psychologist | Educator <i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      <a href="https://www.linkedin.com/in/kadarisuresh" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/Manohar Reddy.jpeg" alt="Manohar Reddy" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Manohar Reddy</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> Director of government relations<i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                      
                    </div>
                  </div>
                </div> -->
                <!-- <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/Kishore.jpeg" alt="Kishore" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Kishore</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> 
                        Escalation Head<i class="fa fa-quote-right" aria-hidden="true"></i> </p>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </section>
    </div>
</div>