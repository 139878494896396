<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2>Government</h2>
        <ol class="breadcrumb greylinks color4">
          <li> <a href="index.html"> Home </a> </li>
          <li> <a href="solutions.html"> Solutions </a> </li>
          <li class="active">Government</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50">
  <div class="container">
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon13.png"
              alt="retail" /></a></p>
        <h5>Digitization of agriculture services</h5>
        <p class="grey show-less-div-1">Real-time Governance with e-agriculture services, Big data integration for the
          Government to get control over rural-urban connectivity and building the synergy gaps to departmental bodies,
          these are the actual facilitation of IT-assets to Agriculture. The IT service integrations is for proactive
          cluster specific decisions, gaining sustainability & accountability of seasonal data of agriculture ecosystem.
        </p>
        <div class="vid-slider">
          <div class="vid-wrapper">
            <div class="vid item">
                <iframe width="213" height="119" src="https://www.youtube.com/embed/feMGoFMBsMQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="video-popup">
          <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span class="close-video"></span>
          </div>
        </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Digitization of agriculture services')" >Enquiry Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon1.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>Farmer market place</h5>
        <p class="grey show-less-div-1">Grading, Quality Assurance, procurement, sales, marketing, commodity analysis,
          cluster report on farm to fork supply chain, rural-urban logistics facilitations, warehouse digitization,
          weather stations & National commodity market signage’s in rural centres.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/CsJJgNJdnws" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Farmer market place')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon2.png"
              alt="Farmer community centre" /></a></p>
        <h5>Farmer stock exchange</h5>
        <p class="grey show-less-div-1">Real-time governance for Commodity prediction at better negotiation on the
          indices is to monitor for a better revenue to the government through marketing and selling out plan by
          facilitating good margins in favour of farmer and understanding import & export market with a better control
          over supply & demand indices.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/ObaSnNTNcZg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Farmer stock exchange')">Enquiry
            Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon10.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>Pre and post-harvest production planning</h5>
        <p class="grey show-less-div-1">HireMyFarmer aggregates both pre and post-harvest services through IT such
          as in pre-
          harvest, the bio pests and fertilizers companies, seed companies, rental machineries will be
          facilitated to support harvesting. The post-harvest aggregation such as cold storages,
          manufacturing units, warehouses, logistics, packaging, agriculture marketing for cluster specific farmers
          where regional wise development can be planned.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/rNv-XjdFRi0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Pre and post-harvest production planning')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon14.png"
              alt="Farmer community centre" /></a></p>
        <h5>Yearly crop report for departmental bodies</h5>
        <p class="grey show-less-div-1">HireMyFarmer facilitates ease of access for yearly crop reports to departmental
          bodies on the health of the crop harvested details, helps to keep track of the sustainability which helps
          department for production planning, respective decisions can be taken through farmer community centres.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/U6oN5OJr0uw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Yearly crop report for departmental bodies')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon15.png"
              alt="Farmer community centre" /></a></p>
        <h5>Employment opportunities at rural places</h5>
        <p class="grey show-less-div-1">Next Generation farmers are not visible and more over farmer as a profession is
          not being opted to carry forward to next generation. HireMyFarmer encourages farmers profession and provides
          advance technologies to manage the farm, where rural job’s are created to make sure these aggregation of
          services is well administrated through our technology solution.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/--x02v9GFwo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Employment opportunities at rural places')">Enquiry
            Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon12.png"
              alt="market" /></a></p>
        <h5>Farmer community centre</h5>
        <p class="grey show-less-div-1">Community based farmer network with a Digital Kiosk to provision the farmers
          orders during pre/post-harvest through centre authorities to make sure the sustainability of farmers margins
          at early stage itself.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/x-Hh7BFCfhc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Farmer community centre')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon16.png"
              alt="market" /></a></p>
        <h5>Data facilitation to government</h5>
        <p class="grey show-less-div-1">HireMyFarmer works on the Agriculture data with a big data initiative, where
          Agriculture ecosystem resources are considered in various parameters and we are ready to facilitate you with
          the actual data and prediction reports along with satellite imagery, soil track, weather track, Disaster
          management alerts, pests alerts & archival of root cause of disaster interval’s farmer as a unit.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/I_aHfZF7PQg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Data facilitation to government')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon17.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>Geo-tagging & Geo-fencing services</h5>
        <p class="grey show-less-div-1">HireMyFarmer services keeps track of productive farming lands and farmland
          related pre/post-harvest service, making sure that the data is facilitated to provisioned authorities and to
          keep track of the location-based services at respective community centre administration.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/raRo96tiawI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Geo-tagging & Geo-fencing services')">Enquiry
            Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon18.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>Enhancing agriculture productivity</h5>
        <p class="grey show-less-div-1">Improvement of Yield is not gaining the numbers in production, it’s giving birth
          to the healthy standards to improve bond between farmer – soil (Mother nature) by following good agriculture
          practices to bring in sustainability. Yield can be improved based on the predictive reports and real-time data
          where farming land can be planned based on the diagnostic report in proactive basis.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/fk2VeYYPL9g" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Enhancing agriculture productivity')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon19.png"
              alt="Farmer community centre" /></a></p>
        <h5>Farmer outsourcing services</h5>
        <p class="grey show-less-div-1">Small Hold Farmers are ready to farm with government support where productive
          farmlands and land owners have to negotiate on a friendly note in parallel to make sure to achieve
          sustainability in farmers income, these kinds of service integration can be availed through HireMyFarmer.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/H4hPJbjYLFI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Farmer outsourcing services')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon20.png"
              alt="market" /></a></p>
        <h5>Crop prediction analysis</h5>
        <p class="grey show-less-div-1">Requested crop commodity patterns can be tracked and monitored to make sure the
          season based diagnostic reports are exchanged by research and departmental bodies</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/I_aHfZF7PQg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Crop prediction analysis')">Enquiry
            Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon33.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>Global partnerships and CSR activities</h5>
        <p class="grey show-less-div-1">HireMyFarmer partnering globally with satellite imagery services, event
          management, agriculture forecasting reports from research institutes to Government bodies, Agri fintech
          initiation, plant-based meets & FMCG collaboration, food security initiatives, bringing NGO’s to add value to
          rural regions along with awareness programs, CSR funds to government, rural employment and farmerpreneurship
          from HireMyFarmer.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/KIyeb2zuvK4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Global partnerships and CSR activitie')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon23.png"
              alt="market" /></a></p>
        <h5>Farmer network operation services</h5>
        <p class="grey show-less-div-1">HireMyFarmer is building technology for farmers so that these services are
          available all the time to network of farmers and make sure the location-based services are utilized and
          organized even during disasters.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/POLOIc7cMEc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Farmer network operation services')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon22.png"
              alt="Farmer community centre" /></a></p>
        <h5>FPO integration services</h5>
        <p class="grey show-less-div-1">HireMyFarmer does has a Virtual FPO services which caters the cluster of farmers
          based on the commodity in favour of farmers but not as a controlled Farmer Producer Organization.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/kvKrQnS9u90" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('FPO integration services')">Enquiry
            Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon24.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>Post-harvest infrastructure services</h5>
        <p class="grey show-less-div-1">Marketing, Cold storages, warehouses, processing units, logistics, packaging
          services aggregated and facilitated by HireMyFarmer technology solution, where farmer as a service can be
          availed for free of cost only during post-harvest. </p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/mGi4oiehJ7I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Post-harvest infrastructure services')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon25.png"
              alt="market" /></a></p>
        <h5>Post-harvest processing unit services</h5>
        <p class="grey show-less-div-1">Cluster of farmers can avail these services at free of cost to make sure their
          Agriculture marketing caters to urban areas without being a hurdle in farmers post-harvest journey, where
          cluster of farmers are facilitated with dedicated processing units.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/mGi4oiehJ7I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Post-harvest processing unit services')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon29.png"
              alt="Farmer community centre" /></a></p>
        <h5>Farmer advisory services</h5>
        <p class="grey show-less-div-1">Farm advisory comes up with maturity and experience in farming but understanding
          nature and soil is not so easy. Farmer has complete privileges to choose and follow his mentor through our
          technology and again these services are integrated with dedicated Kiosk supports in rural support centres.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/R11NmEAOlJk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Farmer advisory services')">Enquiry
            Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon27.png"
              alt="market" /></a></p>
        <h5>Research scholars and integration services</h5>
        <p class="grey show-less-div-1">Research Knowledge and scholars play a major role in Agriculture ecosystem in
          bringing in new policy suggestions to policy makers, there are institutes who are ready to collab and work
          along with government & semi corporate bodies in order to bring in sustainability in Agri- ecosystem CGIAR,
          ICRISAT, CIMIT, World Banks, Melinda gates foundation, CII.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/OvVa39DoVIM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Research scholars and integration services')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon28.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>Fund facilitation support to Agri based start ups</h5>
        <p class="grey show-less-div-1">Agri-tech companies are the next future but most of the talented entrepreneurs
          are not been brought into to lime light of Investors or business, we are here to take every single Agri-tech
          based start-up along with us to grow together by introducing them to our network.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/3i_V0oj9qTI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Fund facilitation support to Agri based start ups')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon26.png"
              alt="Farmer community centre" /></a></p>
        <h5>Post-harvest rental machinery services</h5>
        <p class="grey show-less-div-1">Location based services are activated and triggered based on the need of the
          farmers, this will be acknowledged by farmers community centres.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/mGi4oiehJ7I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Post-harvest rental machinery services')">Enquiry
            Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon30.png"
              alt="market" /></a></p>
        <h5>Drone management services</h5>
        <p class="grey show-less-div-1">HireMyFarmer Integration of remote devices through API Field mapping, is to
          account for larger farming land by agriculture drones for precision farming. Agriculture drones market for
          software services to grow at higher CAGR including camera systems are likely to account for the largest share
          of the agriculture technology as per prediction of forecast reports.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/U_W-czVWEMw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Drone management services')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon31.png"
              alt="Farmer community centre" /></a></p>
        <h5>ZBNF integration services</h5>
        <p class="grey show-less-div-1">Premium farmers are high in demand as they are much connected to the soil &
          nature, though the yield of production of those farming lands are less but high in protein and a larger scope
          in passing the export markets. HireMyFarmer goal is to make sure even a common man can afford for his quality
          lifestyle not just by adding “organic” to their lifestyle but also by supporting these ZBNF Farmers.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/odSqnBraXrc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('ZBNF integration services')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon32.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>Agriculture marketing services</h5>
        <p class="grey show-less-div-1">Goods and raw materials from the field to the final consumer. It includes
          handling of product at the farm, initial processing, grading, and packing in order to maintain and enhance
          quality and avoid wastage.
          HireMyFarmer services orchestrated in order to enhance the farm-to-fork supply chain.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/EU2TdaMecI4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Agriculture marketing services')">Enquiry
            Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon21.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>Hub and spoke administrative services in rural places</h5>
        <p class="grey show-less-div-1">HireMyFarmer Solutions & services can be deployed in community centres based on
          each cluster-based farmer network to make sure pre/post-harvest activities of each individual farmers are
          tracked to orchestrate the farmer as a service for post-harvest services especially in Agriculture marketing.
        </p>
        <div class="vid-slider">
          <div class="vid-wrapper">
            <div class="vid item">
                <iframe width="213" height="119" src="https://www.youtube.com/embed/62Mg31fxawo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="video-popup">
          <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span class="close-video"></span>
          </div>
        </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Hub and spoke administrative services in rural places')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon34.png"
              alt="market" /></a></p>
        <h5>Integration of GAP standards in agriculture ecosystem</h5>
        <p class="grey show-less-div-1">Good Agriculture best Practices for standardization of agriculture services, in
          terms of food along with safety standards by considering Hazard Analysis and critical control points (HACCP)
          in every step of the service operations of HireMyFarmer to prevent hazard pathogens.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/f9owQDEo2jo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Integration of GAP standards in agriculture ecosystem')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon35.png"
              alt="Farmer community centre" /></a></p>
        <h5>International institute knowledge transfer services</h5>
        <p class="grey show-less-div-1">HireMyFarmer connects the best agriculture practices from global researchers and
          institutes such as CGIAR, ICRISAT, CIMIT to regional farming hubs which in turn standardise the agriculture
          ecosystem.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/5Ol5k3jM4NQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('International institute knowledge transfer services')">Enquiry
            Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government"><img src="assets/images/icon36.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>Digitization fund services</h5>
        <p class="grey show-less-div-1">HireMyFarmer acts as a bridge to fulfil the gap between the central government &
          regional government bodies for digitization services, the protocol initiation of access to funding services to
          support regional & national initiatives in agriculture ecosystem.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/yoCYCldVmKE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-government" class="btn btn-success btn-sm"
          (click)="showEnquiry('Digitization fund services')">Enquiry
            Now</a></p>
      </div>
    </div>
  </div>
</section>
<div class="modal trans-05" tabindex="-1" role="dialog" id="enquiry-government">
  <div class="modal-dialog" role="document">
    <form id="frmContact" novalidate="novalidate" #myform = "ngForm" >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{selectedEnquiry}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetButton()"> 
            <span aria-hidden="true">&times;</span> </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="name" [(ngModel)]="enquiryModel.name" name="name" 
                placeholder="Full Name" title="Name" class="form-control" aria-required="true" required>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="designation" [(ngModel)]="enquiryModel.designation" 
                name="designation" placeholder="Designation" title="Designation" 
                class="form-control" aria-required="true" required>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="phone" [(ngModel)]="enquiryModel.phone" name="phone" 
                [pattern]="mobNumberPattern" #mobNumber="ngModel"
                placeholder="Phone Number" title="Phone" class="form-control phone" aria-required="true" 
                (focusout)="onFocusOutEvent($event)" 
                required>
              </div>
              <div class="form-group" *ngIf="mobNumber.errors" >  
                <div *ngIf="mobNumber.errors.pattern">  
                  Mobile number not valid.  
                </div>   
              </div>
              <div class="form-group" *ngIf="!otpHide">
                <input type="text" id="otp" [(ngModel)]="otp" name="otp" 
                placeholder="Enter OTP" title="OTP" class="form-control phone" aria-required="true" required>
              </div> 
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input ngModel type="text" id="email" [(ngModel)]="enquiryModel.emailId" 
                name="emailId" placeholder="Email ID" title="Email" class="form-control email" 
                aria-required="true" required  [pattern]="emailPattern" #emailPtrn="ngModel">
              </div>
              <div class="form-group" *ngIf="emailPtrn.errors" >  
                <div *ngIf="emailPtrn.errors.pattern">  
                  Invalid email is entered.  
                </div>   
              </div>
              <div class="form-group" *ngIf="!otpHide">
                <button class="btn btn-primary" (click)="verifyOtp()" >Veriy OTP</button>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <textarea  id="comment-content" [(ngModel)]="enquiryModel.message" name="message" 
                rows="3" placeholder="Message" class="form-control"></textarea>
              </div>
            </div>
          </div>  
        </div>
        <span >{{response.message}}</span>
        <div class="modal-footer" >
          
          <button type="submit" class="btn btn-primary" 
            value="Submit" [disabled]="clicked" (click)="saveEnquiry(myform.value)">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>
