<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2>Insurance</h2>
        <ol class="breadcrumb greylinks color4">
          <li> <a href="index.html"> Home </a> </li>
          <li> <a href="solutions.html"> Solutions </a> </li>
          <li class="active">Insurance</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50">
  <div class="container">
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-insurance"><img src="assets/images/icon50.png" alt="Crop insurance" /></a></p>
        <h5>Crop insurance</h5>
        <p class="grey show-less-div-1">HireMyFarmer make sures every farmer follows the compliance and provisions for the crop insurance policy by keeping track of farming lands for continuous monitoring of the on-boarded farmer. Insurance companies can actually monitor those farming lands with updated real-time information.</p>
        <div class="vid-slider">
          <div class="vid-wrapper">
            <div class="vid item">
                <iframe width="213" height="119" src="https://www.youtube.com/embed/-h5db9dDWDk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="video-popup">
          <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span class="close-video"></span>
          </div>
        </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-insurance" class="btn btn-success btn-sm"
          (click)="showEnquiry('Crop insurance')">Enquiry Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-insurance"><img src="assets/images/icon51.png" alt="Farmer community centre" /></a></p>
        <h5>Seasonal based insurance</h5>
        <p class="grey show-less-div-1">HireMyFarmer provides the flexibility for an insurer and insurance company to validate few things at harvest level, at the same time it provides the estimates for the insurer along with status of the claim.</p>
        <div class="vid-slider">
          <div class="vid-wrapper">
            <div class="vid item">
                <iframe width="213" height="119" src="https://www.youtube.com/embed/NpLQwUDo4Q8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="video-popup">
          <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span class="close-video"></span>
          </div>
        </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-insurance" class="btn btn-success btn-sm"
          (click)="showEnquiry('Seasonal based insurance')">Enquiry Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-insurance"><img src="assets/images/icon52.png" alt="Data facilitation" /></a></p>
        <h5>Data facilitation</h5>
        <p class="grey show-less-div-1">HireMyFarmer reduces the inspection cost for the insurancecompany and third-parties by facilitating location-based farmer community centres to provision the data to generate report for further processing of claims.</p>
        <div class="vid-slider">
          <div class="vid-wrapper">
            <div class="vid item">
                <iframe width="213" height="119" src="https://www.youtube.com/embed/gGEBPkoyQgg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="video-popup">
          <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span class="close-video"></span>
          </div>
        </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-insurance" class="btn btn-success btn-sm"
          (click)="showEnquiry('Data facilitation')">Enquiry Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-insurance"><img src="assets/images/icon53.png" alt="Disaster management reports" /></a></p>
        <h5>Disaster management reports farmer as a unit</h5>
        <p class="grey show-less-div-1">HireMyFarmer archives the reason for disaster of each individual small hold farmer's farm land, every farmer has a different reason for failure of crop but till date it was considered cluster as a unit. But, with our technological solution we would like to share the individual farmland report where farmer as a unit is tracked.</p>
        <div class="vid-slider">
          <div class="vid-wrapper">
            <div class="vid item">
                <iframe width="213" height="119" src="https://www.youtube.com/embed/ROpRq71f_Y8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="video-popup">
          <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span class="close-video"></span>
          </div>
        </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-insurance" class="btn btn-success btn-sm"
           (click)="showEnquiry('Disaster management reports farmer as a unit')">Enquiry Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-insurance"><img src="assets/images/icon54.png" alt="Rapid insurance services" /></a></p>
        <h5>Rapid insurance services to small hold farmers</h5>
        <p class="grey show-less-div-1">Small hold farmers, usually they undergo lot of problems because they are dependent on multiple factors where they have to negotiate at each step of harvested season till it reaches to market. Small hold farmers mostly been supported by societies, NBFC's, Micro finance companies , but these services doesn't come up with a risk coverage. HireMyFarmer bridges those gaps to make sure that small hold farmers are safe guarded at right intervals in every season.</p>
        <div class="vid-slider">
          <div class="vid-wrapper">
            <div class="vid item">
                <iframe width="213" height="119" src="https://www.youtube.com/embed/nBE4YAbDKCs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="video-popup">
          <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span class="close-video"></span>
          </div>
        </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-insurance" class="btn btn-success btn-sm"
           (click)="showEnquiry('Rapid insurance services to small hold farmers')">Enquiry Now</a></p>
      </div>
    </div>
  </div>
</section>
    <div class="modal trans-05" tabindex="-1" role="dialog" id="enquiry-insurance">
        <!-- <div class="modal-dialog" role="document">
          <form id="frmContact" method="POST" novalidate="novalidate">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Crop insurance</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <input type="text" id="name" name="name" placeholder="Full Name" title="Name" class="form-control" aria-required="true" required>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <input type="text" id="designation" name="designation" placeholder="Designation" title="Designation" class="form-control" aria-required="true" required>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <input type="text" id="phone" name="phone" placeholder="Phone Number" title="Phone" class="form-control phone" aria-required="true" required>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <input type="text" id="email" name="email" placeholder="Email ID" title="Email" class="form-control email" aria-required="true" required>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <textarea id="comment-content" name="content" rows="3" placeholder="Message" class="form-control"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div> -->
        <div class="modal-dialog" role="document">
          <form id="frmContact" novalidate="novalidate" #myform = "ngForm" >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{selectedEnquiry}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetButton()"> 
                  <span aria-hidden="true">&times;</span> </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <input type="text" id="name" [(ngModel)]="enquiryModel.name" name="name" 
                      placeholder="Full Name" title="Name" class="form-control" aria-required="true" required>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <input type="text" id="designation" [(ngModel)]="enquiryModel.designation" 
                      name="designation" placeholder="Designation" title="Designation" 
                      class="form-control" aria-required="true" required>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <input type="text" id="phone" [(ngModel)]="enquiryModel.phone" name="phone" 
                      [pattern]="mobNumberPattern" #mobNumber="ngModel"
                      placeholder="Phone Number" title="Phone" class="form-control phone" aria-required="true" 
                      (focusout)="onFocusOutEvent($event)" 
                      required>
                    </div>
                    <div class="form-group" *ngIf="mobNumber.errors" >  
                      <div *ngIf="mobNumber.errors.pattern">  
                        Mobile number not valid.  
                      </div>   
                    </div>
                    <div class="form-group" *ngIf="!otpHide">
                      <input type="text" id="otp" [(ngModel)]="otp" name="otp" 
                      placeholder="Enter OTP" title="OTP" class="form-control phone" aria-required="true" required>
                    </div> 
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <input ngModel type="text" id="email" [(ngModel)]="enquiryModel.emailId" 
                      name="emailId" placeholder="Email ID" title="Email" class="form-control email" 
                      aria-required="true" required  [pattern]="emailPattern" #emailPtrn="ngModel">
                    </div>
                    <div class="form-group" *ngIf="emailPtrn.errors" >  
                      <div *ngIf="emailPtrn.errors.pattern">  
                        Invalid email is entered.  
                      </div>   
                    </div>
                    <div class="form-group" *ngIf="!otpHide">
                      <button class="btn btn-primary" (click)="verifyOtp()" >Veriy OTP</button>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <textarea  id="comment-content" [(ngModel)]="enquiryModel.message" name="message" 
                      rows="3" placeholder="Message" class="form-control"></textarea>
                    </div>
                  </div>
                </div>  
              </div>
              <span >{{response.message}}</span>
              <div class="modal-footer" >
                
                <button type="submit" class="btn btn-primary" 
                  value="Submit" [disabled]="clicked" (click)="saveEnquiry(myform.value)">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      
