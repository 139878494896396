import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../oauth2/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginModel : LoginDetails;
  constructor(private authService: AuthService, private router: Router) { }
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  ngOnInit(): void {
    this.loginModel = new LoginDetails();
  }

  letMeLogin(loginDetails: LoginDetails){
    console.log("logind details "+loginDetails.userId);
    console.log("logind details "+loginDetails.password);
    this.authService.login(loginDetails)
      .subscribe(() => {
        
        this.router.navigate(['/secure.html']).then(_ => console.log('You are secure now!'));
        // this.authService.redirectUrl = '/secure';
      }, (err: any) => {
        console.log(err);
        
      });
  }
}


export class LoginDetails {
  userId: String;
  password: String;
}
