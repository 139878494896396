<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h2>Login</h2>
          <ol class="breadcrumb greylinks color4">
            <li> <a href="index.html"> Home </a> </li>
            <!--<li> <a href="services.html"> Services </a> </li>-->
            <li class="active">Login</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="ls section_padding_top_50 section_padding_bottom_50 loginbg">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-20 login">
          <div class="col-sm-12 col-12 pb-20">
            <h4 class="text-center logtitle">Forgot Password</h4>
          </div>
          <form id="frmContact" novalidate="novalidate" #loginForm = "ngForm">
            <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
                <div class="form-group">
                    <input type="text" id="email" name="emailId" placeholder="Email ID" title="Name"
                      class="form-control loginfileds" aria-required="true" required
                      [(ngModel)]="emailId" [pattern]="emailPattern" #emailPtrn="ngModel">
                  </div>
                  <div class="form-group" *ngIf="emailPtrn.errors" >  
                    <div *ngIf="emailPtrn.errors.pattern">  
                      Invalid email is entered.  
                    </div>   
                  
                  </div>
            </div>
            <!-- <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
              <div class="form-group">
                <input type="password" id="password" name="password" placeholder="Enter your password"
                  title="Designation" class="form-control loginfileds" aria-required="true" required
                  [(ngModel)]="loginModel.password"> 
              </div>
            </div> -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="form-group lbutton">
                <button type="button" (click)="sendForgotPasswordDetails()" class="btn btn-primary" >Submit</button>
              </div>
            </div>
            <span class="frmmsg">{{response.message}}</span>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <a href="register" class="lglink">Sign Up</a>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <a href="forgot" class="lglink">Forgot Password?</a>
              </div>
            </div>
          </form>
        </div>
        
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          
          <img src="assets/images/agriculture-industry.jpg" alt="Community" class="img-responsive" />
        </div>
      </div>
    </div>
  </section>