<div id="canvas">
    <div id="box_wrapper">
        <section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
            <div class="container">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <h2>Executive Team</h2>
                  <ol class="breadcrumb greylinks color4">
                    <li> <a href="index.html"> Home </a> </li>
                    <li class="active">About Us</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section class="ls section_padding_top_50 section_padding_bottom_50">
            <div class="container">
              <div class="row pb-20">
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/moin-mohammed.jpg" alt="moin mohammed" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Moin Mohammed</h6>
                      <div class="italic desg"> Founder &  Director of Operations at HireMyFarmer </div>
                      <p>Having 8+ years of IT experience in gathering Software requirements and Develop / Implementation of EAI based Technologies...</p>
                      <a href="https://www.linkedin.com/in/moin-mohammed-2a184a107" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/KiranValluri.jpg" alt="KiranValluri" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Kiran Valluri</h6>
                      <div class="italic desg"> CTO </div>
                      <p>Having 10+ years of Experience in Architecture, implementation of Distributed, Enterprise and cloud based applications</p>
                      <a href="https://www.linkedin.com/in/kiran-valluri-a3146823" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pb-20">
              <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/VisishtaChalla.jpg" alt="VisishtaChalla" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Visishta Challa</h6>
                      <div class="italic desg"> Executive Operations Head </div>
                      <p>Developing and implementing strategis Adherence of the organization's daily activities, policies and legal guidelines.
                        Managing fundraisong efforts, maintaining relations with external authorities.
                      </p>
                      <a href="https://www.linkedin.com/in/visishta-challa-420718172" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div><!--VV-->
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/Aditya.jpg" alt="B Saha (Aditya)" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>B Saha (Aditya)</h6>
                      <div class="italic desg"> IPR Consultant </div>
                      <p>Patent Drafting, Patent Prosecution, Patent Illustrations, Patent & Technology Landscape, State of the Art Assessment...</p>
                      <a href="https://www.linkedin.com/in/adityasahabizrights" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <!---->
              </div>
              <div class="row pb-20">
              <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/Venkat-Gurram.jpg" alt="Venkat Gurram" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Venkat Gurram</h6>
                      <div class="italic desg">Application Integration Head</div>
                      <p>Having 12+ years of experience in Application Integration, Supporting and Implementation process</p>
                      <a href="https://www.linkedin.com/in/Venkat-Gurram" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/manoj.jpg" alt="Manoj Kumar" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Sugumaran Manoj Kumar</h6>
                      <div class="italic desg"> Legal Advisor </div>
                 </div>
                  </div>
                </div>
                </div>
              
              <div class="row pb-20">
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/M-A-Rizwan.JPG" alt="M.A.Rizwan" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>M.A.Rizwan</h6>
                      <div class="italic desg"> Community Manager </div>
                      <p>Having 5+ years of experience in supply chain functions through strategy, 
                        resource optimization, profitability maximization, 
                        and KPIs resulting in the maximization of customer satisfaction.</p>
                      
                      <a href="https://www.linkedin.com/in/rizwan-rizwan-529ba21b8" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/Ahmed-Hussain.jpg" alt="Armaan" class="img-responsive" /> 
                      <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Ahmed Hussain</h6>
                      <div class="italic desg">Chief Human Resource Head </div>
                      <p></p>
                    </div>
                  </div>
                </div>
                                
              </div>
              <div class="row pb-20">
              <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/DN-Armaan.jpg" alt="Armaan" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Armaan</h6>
                      <div class="italic desg"> CMO </div>
                      <p>Having 8+ years of experience in Planning, Development and execution of an Organization's Marketing and Advertising initiatives</p>
                      
                       <a href="https://www.linkedin.com/in/dn-armaan-12b0411ba" target="_blank">
                        <i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/Vamshi Krishna.JPG" alt="Vamshi Krishna" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Vamshi Krishna</h6>
                      <div class="italic"> Post-Harvest Operations Manager </div>
                      <p>Manufacturing Operations Engineer at HireMyFarmer...</p>
                      <a href="https://www.linkedin.com/in/vamshi-krishna-054960128" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="row pb-20"></div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/Kishore.jpeg" alt="Kishore" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Kishore</h6>
                      <p> <i class="fa fa-quote-left" aria-hidden="true"></i> 
                        Escalation Head<i class="fa fa-quote-right" aria-hidden="true"></i> </p>                      
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="testim-item">
                    <div class="client-pic"> <img src="assets/images/RajaChandraSekar.jpg" alt="Raja Chandra Sekar" class="img-responsive" /> <span class="client-name"></span> </div>
                    <div class="client-text">
                      <h6>Raja Chandra Sekar</h6>
                      <div class="italic"> Front-End Developer </div>
                      <p>Having Good Expierence in translation of the UI/UX design wireframes to visual elements of the application and bridge the gap between graphical design and technical implementation</p>
                      <a href="https://www.linkedin.com/in/r-rajachandrasekhar" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
                
                
              </div>
              
            </div>
          </section>
    </div>
</div>