<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2>Login</h2>
        <ol class="breadcrumb greylinks color4">
          <li> <a href="index.html"> Home </a> </li>
          <!--<li> <a href="services.html"> Services </a> </li>-->
          <li class="active">Login</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50 loginbg">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-20 login">
        <div class="col-sm-12 col-12 pb-20">
          <h4 class="text-center logtitle">Login</h4>
        </div>
        <form id="frmContact" method="POST" novalidate="novalidate" #loginForm = "ngForm">
          <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
            <div class="form-group">
              <input type="text" id="userId" name="userId" placeholder="Enter your registered phone number" 
              title="Name" class="form-control loginfileds" aria-required="true" required
              [(ngModel)]="loginModel.userId" >
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
            <div class="form-group">
              <input type="password" id="password" name="password" placeholder="Enter your password"
                title="Designation" class="form-control loginfileds" aria-required="true" required
                [(ngModel)]="loginModel.password"> 
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="form-group lbutton">
              <button type="button" class="btn btn-primary" (click)="letMeLogin(loginForm.value)">Submit</button>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
              <a href="register" class="lglink">Sign Up</a>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
              <a href="forgot-password" class="lglink">Forgot Password?</a>
            </div>
          </div>
        </form>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <!-- <ul class="timeline grey">
            <li>HireMyFarmer community centre facilitates technology to the service providers where the farmers network will be reached out with a click of a button.</li>
            <li>Pre-harvest, Post-harvest agri ecosystem information along with cluster specific farmers operations will be tracked through satellite imagery services.</li>
            <li>These centres will have access to huge network of farmers base covering all available crops for both the seasons.</li>
            <li>These centres will be an integration channel to manage and operate pre/post-harvest activities for a cluster based operation to operate farmers in specific region.</li>
          </ul>-->
        <img src="assets/images/agriculture-industry.jpg" alt="Community" class="img-responsive" />
      </div>
    </div>
  </div>
</section>