import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SatServiceService {
  apiUrl: string = 'https://micro.satyukt.com/api/info?key=LLOz6xAeWAIX1vxIgVLjKaHWSlQNASLqH_wRORcxOkY=&startDate=&endDate=&plotName=';
  
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  errorMsg: string;

  constructor(private http: HttpClient) { }
  getSatDetails(plotNumber: Number):Observable<SatDetails[]>{
    return this.http.get<SatDetails[]>(this.apiUrl+plotNumber).pipe();
  }
}

export class SatDetails {
  Date: number;
  cl: number;
  png: PngOrZonalStats;
  tiles_url_evi: string;
  tiles_url_ndvi: string;
  tiles_url_sm: string;
  zonalStats: PngOrZonalStats;
}
export class PngOrZonalStats {
  evi: string;
  ndvi: string;
  sm: string;
}

