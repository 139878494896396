<div >
    <div class="map-form">
        <div>
            <div>
                <label for="dac">Farmer Search</label>
            </div>
            <div>
                <input type="text" id="farmerId" name="farmerId" placeholder="Please enter farmer Id"
                [(ngModel)]="farmerId">
            </div>
            <div class="row">
                <div>
                    <button type="button" id="search" name="search" class="btn btn-success ser"
                    (click)="getFarmerLandDetailsDetailsById()" >Search</button>
                </div>
                <div>
                    <button type="reset" id="resetb" name="resetb" 
                    class="btn btn-success resetb"  (click)="resetForm()">Reset</button>
                </div>
                <notifier-container  ></notifier-container>
            </div>
        </div>
        <div class="analysis-form">
            <div>
                <label for="dac">Analysis</label>
            </div>
            <!-- <div>
                <input type="text" id="farmerId" name="farmerId" placeholder="Please enter farmer Id">
            </div> -->
            <div class="row">
                <div>
                    <button type="button" id="search" name="search" class="btn btn-success crop-analysis">Crops</button>
                </div>
                <div>
                    <button type="button" id="resetb" name="resetb" class="btn btn-success crop-analysis">Irrigation</button>
                </div>
            </div>
        </div>
    </div>
    <div id="lmap" class="lmap"></div>
</div>

<div id="popup" class="gm-style gm-style-iw-c gm-style-iw"
     style="padding-right: 0px; padding-bottom: 0px; max-width: 500px; max-height: 261px; min-width: 0px;"
 title="NDVI">
    <div id="popup-closer"></div>
    <div id="popup-content"></div>
    <div id="popup-farmer-details">
           <div id="popup-farmer-details-img"></div> 
           <div id="popup-farmer-details-soil-type"></div> 
           <div id="popup-farmer-details-land-info"></div> 
           <div id="popup-farmer-details-crop-history"></div> 
           <div id="popup-farmer-details-irrigation-type"></div> 
           <div id="popup-farmer-details-crop-insurance"></div> 
    </div>
</div>