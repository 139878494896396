<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2>Login</h2>
        <ol class="breadcrumb greylinks color4">
          <li> <a href="index.html"> Home </a> </li>
          <!--<li> <a href="services.html"> Services </a> </li>-->
          <li class="active">SignUp</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50 loginbg">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-20 login">
        <div class="col-sm-12 col-12 pb-20">
          <h4 class="text-center logtitle">Sign Up</h4>

        </div>
        <form id="frmContact" novalidate="novalidate" #registrationForm = "ngForm">
          <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
            <div class="form-group">
              <input type="text" id="name" name="name" placeholder="Name" title="name" 
              class="form-control loginfileds" aria-required="true" required 
              [(ngModel)]="registrationModel.name"> 
            </div>
          </div> 
          <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
            <div class="form-group">
              <input type="text" ng2TelInput [ng2TelInputOptions]="{initialCountry: 'in'}" required
              [(ngModel)]="registrationModel.phoneNumber" name="phoneNumber" id="iPhone" 
              class="form-control phone" (countryChange)="onCountryChange($event)" 
              [pattern]="mobNumberPattern" #mobNumber="ngModel"
              (focusout)="onPhoneFocusOutEvent($event)"/>
            </div>
            <div class="form-group" *ngIf="mobNumber.errors" >  
              <div *ngIf="mobNumber.errors.pattern">  
                Mobile number not valid.  
              </div>  
          </div>
          </div>
          <!-- <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
            <div class="form-group">
             <input type="text" id="phone" [(ngModel)]="registrationModel.phoneNumber" name="phoneNumber" 
              [pattern]="mobNumberPattern" #mobNumber="ngModel"
              placeholder="Phone Number" title="phoneNumber" class="form-control phone" aria-required="true" 
              (focusout)="onPhoneFocusOutEvent($event)" 
              required>
            </div>
            <div class="form-group" *ngIf="mobNumber.errors" >  
              <div *ngIf="mobNumber.errors.pattern">  
                Mobile number not valid.  
              </div>   
          </div>
          </div> -->
          <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
            <div class="form-group" *ngIf="!otpHide">
              <input type="text" id="otp" [(ngModel)]="otp" name="otp" 
              placeholder="Enter OTP" title="OTP" class="form-control phone" aria-required="true" required>
            </div> 
            <div class="form-group" *ngIf="!otpHide">
              <button class="btn btn-primary" (click)="verifyOtp()" >Veriy OTP</button>
            </div>

          </div>
          <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
            <div class="form-group">
              
              <input type="text" id="email" name="emailId" placeholder="Email ID" title="Name"
                class="form-control loginfileds" aria-required="true" required
                [(ngModel)]="registrationModel.emailId" [pattern]="emailPattern" #emailPtrn="ngModel"
                (focusout)="onEmailFocusOutEvent($event)" >
            </div>
            <div class="form-group" *ngIf="emailPtrn.errors" >  
              <div *ngIf="emailPtrn.errors.pattern">  
                Invalid email is entered.  
              </div>   
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
            <div class="form-group">
              <input type="password" id="password" name="password" placeholder="Password" title="password"
                class="form-control loginfileds" aria-required="true" required
                [(ngModel)]="registrationModel.password">
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
            <div class="form-group">
              <input type="password" id="password" name="confirmPassword" placeholder="Please Confirm Your Password" 
              title="password" class="form-control loginfileds" aria-required="true" required
                [(ngModel)]="registrationModel.confirmPassword">
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
            <div class="form-group">
              <select [(ngModel)]="selectedCategory" (change)="showOptionvalues(selectedCategory)" 
                      class="form-control loginfileds" [ngModelOptions]="{standalone: true}">
                <option *ngFor="let category of categories" 
                [ngValue]="category" >{{category.name}}</option>
              </select>  
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
            <div class="form-group">
              <div class="corporate box" *ngIf="showGovernment">
                <div><input type="checkbox" id="dac" name="dac" value="Digitization of Agriculture Services">
                  <label for="dac"> Digitization of Agriculture Services</label>
                </div>
                <div>
                  <input type="checkbox" id="fmpg" name="fmpg" value="Farmer Market Place">
                  <label for="fmpg"> Farmer Market Place</label>
                </div>
                <div>
                  <input type="checkbox" id="fseg" name="fseg" value="Farmer Stock Exchange">
                  <label for="fseg"> Farmer Stock Exchange</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
            <div class="form-group" *ngIf="showCorporate">
              <div class="corporate box">
                <div>
                  <input type="checkbox" id="fmp" name="fmp" value="Farmer Market place">
                  <label for="fmp">Farmer Market place</label>
                </div>
                <div>
                  <input type="checkbox" id="fse" name="fse" value="Farmer Stock exchange">
                  <label for="fse">Farmer Stock exchange</label></div>
                <div>
                  <input type="checkbox" id="fcc" name="fcc" value="Farmer Community centre">
                  <label for="fcc">Farmer Community centre</label></div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6">
            <div class="form-group">
              <div class="banking box" *ngIf="showBanking">banking</div>
            </div>
          </div>
          <span class="frmmsg">{{response.message}}</span>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="form-group lbutton" >
              <input type="checkbox" id="iAgree" name="iAgree" [(ngModel)]="registrationModel.consent"
              value=" I Agree Terms and Conditions">
                  <label for="iAgree"> I Agree Terms and Conditions</label>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="form-group lbutton" *ngIf="!submitHide">
              <button type="button" class="btn btn-primary"
              [disabled]="clicked" (click)="register(registrationForm.value)">Submit</button>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
              <a href="login" class="lglink">Login</a>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
              <a href="forgot-password" class="lglink">Forgot Password?</a>
            </div>
          </div>
        </form>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <img src="assets/images/agriculture-industry.jpg" alt="Community" class="img-responsive" />
      </div>
    </div>
  </div>
</section>