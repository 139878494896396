import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-board-members-advisors',
  templateUrl: './board-members-advisors.component.html',
  styleUrls: ['./board-members-advisors.component.css']
})
export class BoardMembersAdvisorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
