<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2>Testimonials</h2>
        <ol class="breadcrumb greylinks color4">
          <li> <a href="index.html"> Home </a> </li>
          <li> Resources</li>
          <li class="active">Testimonials</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="ls ms section_padding_top_50 section_padding_bottom_50">
  <div class="container">
    <div class="row pb-20">
      <blockquote class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pb-20">
        <div class="testimonial_box positon_relative secondary_color_00b2f2 montserrat_font bg_color_fff">
          <p class="text-center padding_top_25"><img src="assets/images/DineshKumar.png" class="img-circle"
              alt="Dinesh Kumar" width="100" /></p>
          <p class="black">Showcasing farmer profiles with their services helps departmental
            bodies for aggregating services in agriculture ecosystem.</p>
          <h6 class="font_size_18 font_weight_700 text-center">Dinesh Kumar, IAS</h6>
          <p class="small grey pb-20 text-center small-text highlight">Managing Director, AP Fibernet</p>
          <div class="shape_box">
            <figure> <img src="assets/images/inverted_comass.png" alt=""> </figure>
          </div>
        </div>
      </blockquote>
      <blockquote class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pb-20">
        <div class="testimonial_box positon_relative secondary_color_00b2f2 montserrat_font bg_color_fff">
          <p class="text-center padding_top_25"><img src="assets/images/Sarabjot.png" class="img-circle" alt="Sarabjot"
              width="100" /></p>
          <p class="black">Collaboration ofAgriculture and Fintechin onesingle
            ecosystem forfarmers benefits is the new predicted Fintech Era in agri investment and big data ecosystem.
          </p>
          <h6 class="font_size_18 font_weight_700 text-center">Sarabjot Singh Anand</h6>
          <p class="small grey pb-20 text-center small-text highlight">Co-Founder & Chief Data Scientist</p>
          <div class="shape_box">
            <figure> <img src="assets/images/inverted_comass.png" alt=""> </figure>
          </div>
        </div>
      </blockquote>
      <blockquote class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pb-20">
        <div class="testimonial_box positon_relative secondary_color_00b2f2 montserrat_font bg_color_fff">
          <p class="text-center padding_top_25"><img src="assets/images/ysjagan.png" class="img-circle"
              alt="Y S Jagan Mohan Reddy" width="100" /></p>
          <p class="black">This kind of a system needs to be integrated to RBK centres to
            administer and control Pre and Post-harvest activities.</p>
          <h6 class="font_size_18 font_weight_700 text-center">Y S Jagan Mohan Reddy</h6>
          <p class="small grey pb-20 text-center small-text highlight">Hon'ble Chief Minister, Andhra Pradesh</p>
          <div class="shape_box">
            <figure> <img src="assets/images/inverted_comass.png" alt=""> </figure>
          </div>
        </div>
      </blockquote>
    </div>
    <div class="row pb-20">
      <blockquote class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pb-20">
        <div class="testimonial_box positon_relative secondary_color_00b2f2 montserrat_font bg_color_fff">
          <p class="text-center padding_top_25"><img src="assets/images/ShenTong1.png" class="img-circle"
              alt="Shen Tong1" width="100" /></p>
          <p class="black">This is the project which can a global access to PRI and social
            impact funds where this will create a disruption in the market for future.</p>
          <h6 class="font_size_18 font_weight_700 text-center">Shen Tong</h6>
          <p class="small grey pb-20 text-center small-text highlight">Managing Partner, Founder, Investor</p>
          <div class="shape_box">
            <figure> <img src="assets/images/inverted_comass.png" alt=""> </figure>
          </div>
        </div>
      </blockquote>
      <blockquote class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pb-20">
        <div class="testimonial_box positon_relative secondary_color_00b2f2 montserrat_font bg_color_fff">
          <p class="text-center padding_top_25"><img src="assets/images/Anjaneyulu.png" class="img-circle"
              alt="Anjaneyulu" width="100" /></p>
          <p class="black">Supporting small hold farmers during the
            agriculture marketing layer at post-harvest services adds more value to a small hold farming community by
            connecting us to direct business.</p>
          <h6 class="font_size_18 font_weight_700 text-center">Anjaneyulu</h6>
          <p class="small grey pb-20 text-center small-text highlight">Farmer</p>
          <div class="shape_box">
            <figure> <img src="assets/images/inverted_comass.png" alt=""> </figure>
          </div>
        </div>
      </blockquote>
      <blockquote class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pb-20">
        <div class="testimonial_box positon_relative secondary_color_00b2f2 montserrat_font bg_color_fff">
          <p class="text-center padding_top_25"><img src="assets/images/Sahara.png" class="img-circle"
              alt="M Sahara Jabeen" width="100" /></p>
          <p class="black">Being a school teacher, I feel really proud that my
            son being an engineer is creating a value chain for farmers profession by contributing his innovation to the
            society for a change in farmer's life.</p>
          <h6 class="font_size_18 font_weight_700 text-center">M Sahara Jabeen</h6>
          <p class="small grey pb-20 text-center small-text highlight">School Teacher & Founder's Mother, Chittoor AP</p>
          <div class="shape_box">
            <figure> <img src="assets/images/inverted_comass.png" alt=""> </figure>
          </div>
        </div>
      </blockquote>
    </div>
    <div class="row">
      <blockquote class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pb-20">
        <div class="testimonial_box positon_relative secondary_color_00b2f2 montserrat_font bg_color_fff">
          <p class="text-center padding_top_25"><img src="assets/images/Stevens.png" class="img-circle" alt="Stevens"
              width="100" /></p>
          <p class="black">Social Impact vending machines are great idea which
            will resolve universal problem where small hold farmers are getting assigned to dedicated machines and these
            IOT machines will act as hubs for branding the farmer's product at reasonable price for customers and better
            margins for farmers.</p>
          <h6 class="font_size_18 font_weight_700 text-center">Tom Stevens</h6>
          <p class="small grey pb-20 text-center small-text highlight">NY, Wash DC, Illinois and UK Attorney, US and EU Investor</p>
          <div class="shape_box">
            <figure> <img src="assets/images/inverted_comass.png" alt=""> </figure>
          </div>
        </div>
      </blockquote>
      <blockquote class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pb-20">
        <div class="testimonial_box positon_relative secondary_color_00b2f2 montserrat_font bg_color_fff">
          <p class="text-center padding_top_25"><img src="assets/images/DalalAlGhawas.png" class="img-circle"
              alt="Dalal AlGhawas" width="100" /></p>
          <p class="black">HireMyFarmer vision to integrate food safety
            standards in farm to fork supply chain by adding the actual food quality standards which is accessible &
            traceable for better nutrition with an innovative way for research bodies to work on food science, food
            formulations, food technologies" for the future.</p>
          <h6 class="font_size_18 font_weight_700 text-center">Dr. Dalal AlGhawas</h6>
          <p class="small grey pb-20 text-center small-text highlight">Program Director at Big Idea Ventures</p>
          <div class="shape_box">
            <figure> <img src="assets/images/inverted_comass.png" alt=""> </figure>
          </div>
        </div>
      </blockquote>
    </div>
  </div>
</section>