<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h2>Awards & Recognition</h2>
                <ol class="breadcrumb greylinks color4">
                    <li> <a href="index.html"> Home </a> </li>
                    <li> <a href="gallery.html"> Gallery </a> </li>
                    <li class="active">Awards & Recognition</li>
                </ol>
            </div>
        </div>
    </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6">
                <div class="project-col">
                    <div class="hover-box"> <img class="fixed-hight280 fixed-hight210"
                            src="assets/images/gallery/thumb-1.jpg" alt="HireMyFarmer" />
                        <div class="hover-box-content">
                            <ul class="icon">
                                <li><a class="gallery" href="assets/images/gallery/gallery-1.jpg"
                                        data-lightbox="lightbox"
                                        data-title="Mr.JA Chowdary Ex- Chief Advisory to Chief Minister recognized our project and connected us to few Departments"><i
                                            class="fa fa-search"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="project-col">
                    <div class="hover-box"> <img class="fixed-hight280 fixed-hight210"
                            src="assets/images/gallery/thumb-2.jpg" alt="HireMyFarmer" />
                        <div class="hover-box-content">
                            <ul class="icon">
                                <li><a class="gallery" href="assets/images/gallery/gallery-2.jpg"
                                        data-lightbox="lightbox" data-title="Mr.Gireesh Challa, Founder of &ldquo;Notary
                                        Mama&rdquo; one of the brilliant enterprenrial mind who introduced us to TIE Chapter
                                        Delhi and groomed &ldquo;HireMyFarmer&rdquo; under his Mentorship"><i
                                            class="fa fa-search"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="project-col">
                    <div class="hover-box"> <img class="fixed-hight280 fixed-hight210"
                            src="assets/images/gallery/thumb-3.jpg" alt="HireMyFarmer" />
                        <div class="hover-box-content">
                            <ul class="icon">
                                <li><a class="gallery" href="assets/images/gallery/gallery-3.jpg"
                                        data-lightbox="lightbox" data-title="Maharashtra government has recognized us and we stood in Top 3 startup's
"><i class="fa fa-search"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="project-col">
                    <div class="hover-box"> <img class="fixed-hight280 fixed-hight210"
                            src="assets/images/gallery/thumb-11.jpg" alt="HireMyFarmer" />
                        <div class="hover-box-content">
                            <ul class="icon">
                                <li><a class="gallery" href="assets/images/gallery/gallery-11.jpg"
                                        data-lightbox="lightbox"
                                        data-title="Launched HireMyFarmer in INDIAN AGRICULTURAL RESEARCH INSTITUTE, PUSA, NEW DELHI, where We had to present our model with in Agri Ministrial bodies and jury to provision our participation of innovation to get acknowledged by central government of INDIA"><i
                                            class="fa fa-search"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>