<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h2>Case Study</h2>
          <ol class="breadcrumb greylinks color4">
            <li> <a href="index.html"> Home </a> </li>
            <li class="active">Case Study</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="ls section_padding_top_50">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 pt-20"> <img src="assets/images/canada.jpg" alt="canada" class="img-responsive" /> </div>
        <div class="col-md-12 col-sm-12">
          <div class="wrapper">
            <div class="container">
              <div class="bs-example bs-example-tabs" role="tabpanel" data-example-id="togglable-tabs">
                <ul id="myTab" class="nav nav-tabs nav-tabs-responsive" role="tablist">
                  <li role="presentation" class="active"> <a href="#home" id="home-tab" role="tab" data-toggle="tab" aria-controls="program" aria-expanded="true"> <span class="text">What this program offers</span> </a> </li>
                  <li role="presentation" class="next"> <a href="#eligible" role="tab" id="eligible-tab" data-toggle="tab" aria-controls="profile"> <span class="text">Who is eligible</span> </a> </li>
                  <li role="presentation"> <a href="#apply" role="tab" id="apply-tab" data-toggle="tab" aria-controls="apply"> <span class="text">How to apply</span> </a> </li>
                  <li role="presentation"> <a href="#after" role="tab" id="after-tab" data-toggle="tab" aria-controls="after"> <span class="text">After you apply</span> </a> </li>
                  <li role="presentation"> <a href="#contact" role="tab" id="contact-tab" data-toggle="tab" aria-controls="contact"> <span class="text">Contact Info</span> </a> </li>
                </ul>
                <div id="myTabContent" class="tab-content">
                  <div role="tabpanel" class="tab-pane fade in grey active" id="home" aria-labelledby="home-tab">
                    <h6>Step 1. What this program offers</h6>
                    <p>The Farm Debt Mediation Service offers financial counselling and mediation services to farmers who are having difficulties meeting their financial obligations. It is a free and voluntary service for both producers and for creditor(s). The service helps bring producers and their creditor(s) together with a mediator in a neutral forum to reach a mutually acceptable solution.</p>
                    <p>The service is private and confidential. It is an economical alternative to trying to resolve financial disputes in court. The majority of cases that have gone through the mediation process have resulted in a signed agreement between farmer and creditor(s).</p>
                    <p>The Farm Debt Mediation Service is divided into two processes: Section 5 (1) (a) and Section 5 (1) (b). The main difference between the two is that Section 5 (1) (a) includes a stay of proceedings and Section 5 (1) (b) does not. For more details about each section, please see How to apply.</p>
                    <p>As soon as we confirm that you are eligible for the Service, a qualified financial consultant will be assigned to work with you throughout the mediation process.</p>
                    <p>Once the financial consultant has helped you develop a recovery plan, a meeting will be arranged with you, your creditor(s) and a mediator.</p>
                    <p>It is the mediator's responsibility to ensure a fair and unbiased mediation process and to remain neutral throughout all discussions. Confidentiality is extremely important to the quality and effectiveness of the service. Information obtained from you and your creditor(s) is used for the sole purpose of mediation and is shared only among the parties involved in the mediation process.</p>
                    <p>The mediator has no decision making power and is at the meeting only to help you and your creditor(s) reach your own mutually acceptable settlement. The mediator will lead a discussion encouraging both the producer and the creditor(s) to participate; help the parties to communicate effectively; and help to explore and clarify options for settlement. When the parties agree upon a solution, the mediator will draw-up an agreement, get it signed, and give a copy to each of the participants.</p>
                  </div>
                  <div role="tabpanel" class="tab-pane fade grey" id="eligible" aria-labelledby="eligible-tab">
                    <h6>Step 2. Who is eligible</h6>
                    <p>To be eligible for the Farm Debt Mediation Service, you must farm commercially and meet one of the following two criteria:</p>
                    <ul>
                      <li>You have stopped or are no longer able to make your payments on time.</li>
                      <li>If sold, the value of your property would not be enough to cover the cost of your debts.</li>
                    </ul>
                  </div>
                  <div role="tabpanel" class="tab-pane fade grey" id="apply" aria-labelledby="apply-tab">
                    <h6>Step 3. How to apply</h6>
                    <p>The Farm Debt Mediation Act is divided in two sections. The question below will help determine which section best suits your needs. </p>
                    <p>Have you received a Notice of Intent by Secured Creditor or notice of other recovery action from your creditor(s)? If so, and you have not applied for a mediation under Section 5 (1) (a) within the past two years, Section 5 (1) (a) may be the appropriate application for you. This section provides a financial review, mediation and a stay of proceedings</p>
                    <p>If you have not received a Notice of Intent by Secured Creditor or notice of other recovery action, but you can foresee financial difficulties and you have not applied for a mediation under Section 5 (1) (b) within the past two years, Section 5 (1) (b) may be the appropriate application for you. This section provides a financial review and mediation without a stay of proceedings.</p>
                    <p>Once you have decided which section is best-suited to your situation or if you want to discuss your situation, you can contact your nearest Farm Debt Mediation Office at 1-866-452-5556.</p>
                    <p>You should complete and send the application form to your Farm Debt Mediation Service (FDMS) office as soon as possible:</p>
                    <h6><a href="https://www.agr.gc.ca/eng/agricultural-programs-and-services/farm-debt-mediation-service/step-3-how-to-apply/?id=1538136685779" target="_blank">Application for Mediation</a></h6>
                    <p>By acting as soon as it is clear that you are in financial difficulty, you will keep the lines of communication with your creditor(s) open and it will be easier to discuss issues in an atmosphere of trust. The earlier you contact the <a href="https://www.agr.gc.ca/eng/agricultural-programs-and-services/farm-debt-mediation-service/contact-information/?id=1538136828038" target="_blank">Farm Debt Mediation Service office</a> nearest you, the sooner you can start developing a recovery plan for the future.</p>
                    <p>For applicants participating in the Advanced Payments Program (APP), please see the <a href="https://www.agr.gc.ca/eng/agricultural-programs-and-services/farm-debt-mediation-service/information-for-producers-applying-to-the-farm-debt-mediation-service-fdms-with-an-outstanding-advances-under-the-advance-payment-program-app/?id=1538136789566" target="_blank">APP/ FDMS Information Page.</a></p>
                    <h6>Section 5 (1) (a) Mediation with a stay of proceedings</h6>
                    <p>Creditors who send you a Notice of Intent by Secured Creditor under Section 21 of the Farm Debt Mediation Act must wait 15 business days before they can begin or continue any action to realize on their security.</p>
                    <p>If you apply and you qualify for Section 5 (1) (a), you will be entitled to a financial review and mediation with a stay of proceedings. During the period of the stay of proceedings creditors may not begin or continue recovery or seizure action against your assets. You must also follow <a href="https://www.agr.gc.ca/eng/agricultural-programs-and-services/farm-debt-mediation-service/directives-to-a-guardian-appointed-under-the-farm-debt-mediation-act/?id=1538136635949" target="_blank">guardianship directives</a> such as you cannot incur additional debt, make payments to creditors for debt you had when the stay is put in place, nor sell or transfer assets without prior written permission. Initially, the stay of proceedings is in place for 30 calendar days, but may be extended in 30 day increments to a maximum of 120 calendar days. If you are considering an application under Section 5 (1) (a), you are strongly encouraged to contact the <a href="https://www.agr.gc.ca/eng/agricultural-programs-and-services/farm-debt-mediation-service/contact-information/?id=1538136828038" target="_blank">Farm Debt Mediation Service office</a> nearest you.</p>
                    <h6>Section 5 (1) (b) Mediation only</h6>
                    <p>An application for mediation under this section of the Act is generally used when you can foresee financial difficulties, but have not yet been served with a Notice of Intent by Secured Creditor or any other legal documents from your creditors. The service includes a financial review and mediation without a stay of proceedings. There are no restrictions placed on either you or the creditors. This section normally deals with secured creditors and may include major unsecured creditors essential to an arrangement.</p>
                    <h5>Legal requirements</h5>
                    <h6>For creditors:</h6>
                    <p>Meeting financial obligations has always been important to farmers, but adapting to today's rapidly changing conditions can sometimes make debt repayment difficult. If you and your client have reached a stalemate in your repayment negotiations, the Farm Debt Mediation Service provides a mediation process that encourages a mutually agreeable solution.</p>
                    <p>The Farm Debt Mediation Service includes the development of a recovery plan outlining the steps for moving ahead and the responsibilities of both you and the farmer.</p>
                    <p>If you are a secured creditor, Section 21 of the <a href="https://laws-lois.justice.gc.ca/eng/acts/F-2.27/page-1.html" target="_blank">Farm Debt Mediation Act</a>, requires you to serve a farmer with a Notice of Intent by Secured Creditor before taking legal action to recover debts. You must wait 15 business days after the <a href="https://www.agr.gc.ca/eng/agricultural-programs-and-services/farm-debt-mediation-service/step-3-how-to-apply/?id=1538136734881" target="_blank">Notice of Intent by Secured Creditor</a> is deemed to be effected before starting or continuing collection action. The farmer may choose to apply to the Farm Debt Mediation Service during this period, or at any time afterwards.</p>
                    <h6>For farmers:</h6>
                    <p>If you decide to apply and you qualify for Section 5 (1) (a), you will be offered a financial review and mediation with a stay of proceedings, during which time creditors may not begin or continue recovery or seizure action against your assets. The stay of proceedings is initially issued for 30 calendar days, but may be extended in 30 day increments to a maximum of calendar 120 days. If you decide to apply and you qualify for Section 5 (1) (b), you will be offered a financial review and mediation without a stay of proceedings.</p>
                    <p>Please see the <a href="https://laws-lois.justice.gc.ca/eng/acts/F-2.27/page-1.html" target="_blank">Farm Debt Mediation Act</a> and the <a href="https://laws-lois.justice.gc.ca/eng/regulations/SOR-98-168/page-1.html" target="_blank">Farm Debt Mediation Regulations for more details.</a></p>
                  </div>
                  <div role="tabpanel" class="tab-pane fade grey" id="after" aria-labelledby="after-tab">
                    <h6>Step 4. After you apply</h6>
                    <p>As soon as we confirm that you are eligible for the Service, a qualified financial consultant will be assigned to work with you throughout the mediation process. The consultant will visit your farm to learn how your operation works, then will:</p>
                    <ul>
                      <li>assess your current financial situation</li>
                      <li>explore options and develop a plan for the future</li>
                      <li>collect financial information to prepare a financial statement and recovery plan</li>
                      <li>complete projections to evaluate the viability of the plan</li>
                      <li>contact creditors to verify amounts owed</li>
                      <li>attend the mediation meeting and assist you with negotiations</li>
                    </ul>
                    <h6>Service standards</h6>
                    <p>Due to the extraordinary circumstances caused by COVID-19, it is anticipated that the delivery of services will take longer than the published service standard.</p>
                    <p>Once you submit an application, our goal is to achieve our service standards at a minimum of 80% of the time under normal circumstances:</p>
                    <ul>
                      <li>Our goal is to respond to general inquiries made to our phone number or email address before the end of the next business day;</li>
                      <li>Our goal is to notify you if your application has been accepted or not by the end of the next business day;</li>
                      <li>Our goal is to have all necessary documentation sent to creditors and producer seven (7) business days prior to the mediation meeting;</li>
                      <li>Our goal is to schedule the first mediation meeting within 70 calendar days after acceptance of your application.</li>
                    </ul>
                  </div>
                  <div role="tabpanel" class="tab-pane fade grey" id="contact" aria-labelledby="contact-tab">
                    <h5>Contact information</h5>
                    <h6>Regional offices</h6>
                    <p>For more information, or to submit your application, contact the regional office nearest you.</p>
                    <h6>Regional Office responsible for Alberta, British Columbia, Manitoba, Northwest Territories, Nunavut, Saskatchewan and Yukon</h6>
                    <p>Agriculture and Agri-Food Canada <br>
                      Farm Debt Mediation Service <br>
                      301-2010 12th Avenue <br>
                      Regina SK S4P 0M3</p>
                    <p><strong>Toll Free Number: </strong>1-866-452-5556 <br>
                      <strong>Telephone:</strong> 306-780-5544 <br>
                      <strong>TTY: </strong>613-773-2600 <br>
                      <strong>Fax: </strong>306-780-7353 <br>
                      <strong>Email:</strong> <a href="mailto:aafc.fdmswest-smmeaouest.aac@canada.ca">aafc.fdmswest-smmeaouest.aac@canada.ca</a></p>
                    <h6>Regional Office responsible for New Brunswick, Newfoundland and Labrador, Nova Scotia, Ontario, Prince Edward Island, and Quebec</h6>
                    <p>Agriculture and Agri-Food Canada<br>
                      Farm Debt Mediation Service<br>
                      Jules-Dallaire Complexe<br>
                      2828 Laurier Blvd&nbsp;- Room 640<br>
                      Quebec QC G1V 0B9</p>
                    <p><b>Toll Free Number:</b> 1-866-452-5556<br>
                      <b>Telephone (Atlantic and Quebec):</b> 418-649-8142<br>
                      <b>Telephone (Ontario):</b> 613-773-0878<br>
                      <b>TTY:</b> 613-773-2600<br>
                      <b>Fax (Atlantic and Ontario): </b> 506-452-4975<br>
                      <b>Fax (Quebec):</b> 418-648-2439<br>
                      <strong>Email:</strong> <a href="mailto:aafc.fdmseast-smmeaest.aac@canada.ca">aafc.fdmseast-smmeaest.aac@canada.ca</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="ls section_padding_top_50">
    <div class="container">
      <div class="row pb-20">
        <div class="col-md-12 col-sm-12 grey fintech">
          <hr class="hrborder5">
          <p ><img src="assets/images/FinTech-Agriculture.jpg" alt="FinTech-Agriculture" class="img-responsive" /></p>
          <p class="text-center">2018 5th International Conference on Power and Energy Systems Engineering, CPESE 2018, 19–21 September 2018, Nagoya, Japan</p>
          <h5 class="text-center">Digital Marketplace and FinTech to Support Agriculture Sustainability</h5>
          <p class="text-center"><strong>Muhammad Anshari*, Mohammad Nabil Almunawar, Masairol Masri, Mahani Hamdan</strong> <br>
            <span class="small">Universiti Brunei Darussalam, School of Business & Economics, Jln Tungku Link Gadong, Bandar Seri Begawan, Brunei Darussalam </span></p>
          <h6>1. Introduction</h6>
          <p>The emergence of Financial Technology (FinTech) and digital marketplace gained wide attention from industry,
            research, public or private organization. FinTech is an important concept and application that trigger researches in
            any sectors including farming and agriculture. As digital marketplace enables new forms of communication and
            transactions between actors within agriculture's business process (consumers, suppliers, farmers, investors,
            distributors, etc.), smart connectivity is an essential part for sustainability and productivity. Agriculture is associated
            with agricultural economics and management studies to explore development of food economies and sustainability
            [1]. In traditional business process, farmers especially in developing countries suffer some problems of funding
            deficiencies, capital issues, limited access to financial institutions, and lacking access to the market. There are also
            many layers involved in agriculture's supply chains from farmers to consumers creating additional cost for product.
            Digital marketplace has changed many business processes almost in any sector [2]. Introducing digital marketplace
            with FinTech enabled could advance e-agriculture that has become the action plans in declaration of the World
            Summit on the Information Society (WSIS) where The Food and Agriculture Organization (FAO) has the
            responsibility of implementing e-agriculture [3]. Digital marketplace in agriculture is needed to support market
            demand for quality of agricultural products with a cheaper price from diverse geographical locations. </p>
          <p>Digital marketplace will accelerate changes in demand, leading to a growing interest in foods from diverse
            geographical locations [4]. Therefore, accommodating FinTech in digital marketplace could overcome frequent
            problems of financial issues faced by farmers and encourage public to invest into agriculture [5]. Digital marketplace
            as a platform will empower with the massive information instantly that they can reach out to almost everything
            through the Internet using their smartphone to find any information that they need or going further by making online
            transactions anywhere and anytime [6]. The study proposes a model of digital marketplace with FinTech enabled in
            agricultural setting. Digital marketplace will act as system intermediaries that can be accessed by all the actors
            involved based on their respective roles. The main role of digital marketplace is to link producers and consumers so
            that goods or services can flow from producers to consumers directly with less intermediaries. Similarly, digital
            marketplace in agriculture involves e-commerce transaction linking producers or farmers to customers and
            wholesalers. It may include managing the flow of transactions and distribution for business-to-business (B2B),
            business to consumers (B2C) and consumer-to-consumer (C2C). Then, any financial transaction occurred within
            digital marketplace are supported by its FinTech. </p>
          <h6>2. Literature Review</h6>
          <p>There are several actors in agriculture business linking the first line processors, wholesalers, manufactures, retailers,
            manufacturers, and finally customers. Figure 1 shows the most common actors involved in agribusiness's supply
            chain. The first line processors refers to all actors involved in processing raw agro products, i.e., farmers,
            landowners, and investors. In case of small medium agro enterprises, first line processors sell directly to customers.
            However, the most common type of digital marketplace in the agro product sector is business to business (B2B) [7].
            Sometimes, farmers own land and invest at the same time. However, there are condition where farmers do have land
            but do not have enough funding. Farmers receive funding from investors or bank to buy seeds, fertilizers, and any
            supporting tools. In another conditions, farmers rent a land from landowners and lend venture capital from investors.
            Then, these actors are suppliers or distributors. Suppliers, distributors or business providers act as intermediaries that
            can dominate supply chains activities by controlling the flow of goods as well as prices from first line processors to
            customers, wholesalers, or retailers. These are many layers existed from the first line processors to customers that
            each layer incurs additional cost. The existing model also portrays limited sources of funding and access to the
            market for first line processors. While, Customers have less control over products' option and pricing.</p>
          <p><img src="assets/images/img1.jpg" alt="Literature Review" class="img-responsive" /></p>
          <p><strong>2.1. Digital Marketplace</strong></p>
          <p>There are problems of non-information-based business in agriculture so that the dependency on ICT to conduct
            business operations is lesser than service industries. However, competition's landscape has intensified with the free
            trade zone that allows any goods, products and services to freely move with less restriction among the countries,
            implying that ICT in agriculture will instill high impact on the future growth of agribusiness for sustainability,
            competitiveness and the long-term survival. In addition, businesses has rely on advance supply-chain management
            to support customers in making quick, effective, and efficient reservations. Customers search best available
            agriculture products as well as making reservations use web/Apps. Web/Apps provides convenience, easy access, up
            to date information, price comparisons, time saving. The emergence of digital marketplace for agriculture will no
            longer be a choice for many business organizations rather it has become a competitive necessary for them to stay in
            the market. Digital marketplace will drive innovation including in agribusiness by introducing a platform as
            intermediaries which is a service provider that connects investors, landowners, farmers, and customers in a single
            platform of mobile market. It offers customers to have direct access to the first line processors using their
            smartphones. It will make customers to gain more bargaining power as they can compare prices, quality, delivery,
            and services from multiple suppliers [8]. While, social media within the platform is a powerful tool to support
            internal communication and knowledge sharing as well as the quick dissemination of information [9]. Furthermore,
            social media can be used by service providers to learn people or customers' behavior by extracting data from the
            conversation that takes place in the social networks [10, 11]. </p>
          <p><strong>2.2. Financial Technology (FinTech) </strong></p>
          <p>FinTech is the innovative use of technology to deliver financial services and products offering a user-friendly and
            convenient ways of managing finance for its consumers in apposition with traditional methods. Additionally,
            Fintech is also referred as the future of banking and finance that simply provide the technology to financial service
            providers [12]. FinTech and its financial services can aid agriculture's sector to compete in a global economy
            through crowdfunding, mobile payments, money transfers, loans, fundraising, asset management, and
            payments/billing. FinTech is considered innovative because it can be easily connect all the actors in the business line
            into a single platform. For instance, crowdfunding in FinTech can connect first line processor (Figure 1) to the
            public as investors and at the same time as customers for the products. It enables peer-to-peer investment where
            individuals lend money to first line processors without using an official financial institution as an intermediary.
            Furthermore, FinTech's payment facilitates pay online with a virtual payment account. </p>
          <h6>3. Method </h6>
          <p>Some referred journal publications were used to find all sources for referencing mostly in English languages. The
            methods employed of content analysis of the research on agriculture's business process, digital marketplace and
            FinTech published in peer-reviewed journals. Then, the results were clustered into a thematic of technical,
            conceptual, and modelling of digital marketplace in agriculture. In our analysis, we examined the business process of mobile commerce in agribusiness. Subsequently, we used the results of analyses to formulate model and
            recommendations. </p>
          <h6>4. Discussion</h6>
          <p>Digital marketplace with FinTech enabled are driven with the massive adoption of smartphone so that many
            business transactions including in agriculture are conducted through smartphones, including transactions in
            investment, payment or buying online. Smartphones has become a front-end device of digital marketplace where
            customers, suppliers, and investors engage in business transaction directly or indirectly and also be used as tools to
            manage business remotely. Early business type in the agriculture is business to business (B2B), this is because the
            market is fragmented, the supply chain is inefficient, buyers change sellers regularly and the value of the products is
            usually volatile [13]. </p>
          <p>As smartphones are utilized for many business transactions, then business organizations, including agriculture
            sectors embrace mobile technology developing mobile business platform. Figure 2 shows the model of digital
            marketplace with the FinTech enabled namely AgroPay. Digital marketplace as a platform acts as business
            intermediaries that connects to all actors. The platform can be based on Web or Apps that allows communications
            and transactions among actors can be conducted using smartphones. Double arrow directions indicate that the flow
            of data/information is reciprocal for connecting actors. </p>
          <p><img src="assets/images/img2.jpg" alt="Discussion" class="img-responsive pb-10" /></p>
          <p>As comparison, in conventional business intermediaries, suppliers or business owners control the flow of goods
            and products' pricing from first line processor to their customers (Figure 1). However, digital marketplace as the
            platform changes business process by removing the layers of business owners so that all actors may have direct
            access for transaction and exchange of information [14]. For instance, customers can make transactions directly to
            the farmers by picking up from their Apps and pay the farmers through their AgroPay account. While, farmers could
            buy fertilizers from suppliers from the platform and also pay through AgroPay. B2B transaction in digital
            marketplace has enabled business customers (i.e. Hypermart) to order directly from first line processor whenever
            they need to get stock for agriculture freshly from the farm. Digital marketplace has changed the role of business
            intermediaries by reducing the control of data flows from investors, farmers, and customers. At the same time,
            introducing FinTech through AgroPay will make cashless transaction easy for all the actors. The platform provides
            service for all parties forming a direct set of business line. Furthermore, the niche of the platform is mobile services
            where all parties can participate in business activities at any time and from anywhere. </p>
          <p class="text-center"><img src="assets/images/img3.jpg" alt="Discussion" class="pb-10" /></p>
          <p>The other FinTech initiative in the platform is crowdfunding system (Figure 3). Crowdfunding will allow farmers to
            easily get source of funding from the public. Crowdfunding allows farmers to expand their agribusiness by
            providing everyone the ability to invest and track their investment of their agriculture product from their
            smartphones. The first line processors might offer investors to choose their agriculture products from short-term
            investment on short-cycle plants such as peanut or vegetables to long-term investment on fruit trees such avocado or
            dates. Potential investors can be anyone that invests on agriculture products for specific timeframe from seeding to
            harvesting depends on type of product. </p>
          <h6>Conclusion</h6>
          <p>Smartphone is a general-purpose device that can be used to access, process, present information as well as facilitate
            business transactions. Digital marketplace with FinTech enabled can be a very useful platform for conducting
            business transactions on agriculture as they can be done conveniently at anytime from anywhere. The platform can
            be expected to attract all actors in agribusiness, conducting business transactions transparently and conveniently
            with personalized services. AgroPay provides necessary functions for investors to conduct transactions efficiently
            anytime-anywhere. Investors through crowdfunding can select from the wide-range agriculture products through
            their smartphone to invest. In addition, customers are empowered to access directly on price and making comparison
            on various agriculture products offered and pay directly using AgroPay as other FinTech initiative. This will cause
            an increase price competition among agro products suppliers and improve sustainability of agriculture product
            through FinTech. </p>
          <h6>References</h6>
          <ul>
            <li>[1] Nga, D., & Siebert, J. W. (2009). Toward better defining the field of agribusiness management. International Food and Agribusiness Management Review, 12(4).</li>
            <li>[2] Anshari, M., & Lim, S.A. (2017) E-Government with Big Data Enabled through Smartphone for Public Services: Possibilities and Challenges,
              International Journal of Public Administration, 40:13, 1143-1158. </li>
            <li>[3] WSIS (2005). Tunis Agenda for the Information Society. Retrieved August 16, 2016 from http://www.itu.int/wsis/docs2/tunis/off/6rev1.html</li>
            <li>[4] Da Silva, C. A. (2009). Agro-industries for development. CABI. </li>
            <li>[5] Anshari, M., & Alas, Y. (2015). Smartphones Habits, Necessities, and Big Data Challenges. Journal of High Technology Management
              Research. 26 (2): 177-185. </li>
            <li>[6] Anshari, M., Almunawar, M.N., Ariff Lim, S., Al-mudimigh, A., (2018). Customer Relationship Management and Big Data Enabled:
              Personalization & Customization of Services, Applied Computing and Informatics, doi: https://doi.org/10.1016/j.aci.2018.05.004. </li>
            <li>[7] Kourgiantakis, M., Matsatsinis, N., & Migdalas, A. (2012). E-commerce: A survey for Cretan agro-food sector. URL: http://ressources.
              ciheam. org/om/pdf/c61/00800165. pdf, </li>
            <li>[8] Strachan,D., (2012) The best new travel technology, Retrieved 18 September 2012 from
              http://www.telegraph.co.uk/travel/columnists/7534231/The-best-new-travel-technology.html. </li>
            <li>[9] Hester, A.J. (2010). "Increasing Collaborative Knowledge Management in Your Organization: Characteristics of Wiki Technology and Wiki
              Users." In: Proceedings of the 2010 Special Interest Group on Management Information System's 48th annual conference on Computer
              personnel research on Computer personnel research, SIGMIS-CPR'10, May 20-22, Vancouver, BC, Canada, pp. 158-164.</li>
            <li>[10] Baxter, G.J., Connolly, T.M. and Stansfield, M. H. (2010). "Organisational blogs: benefits and challenges of implementation". The Learning
              Organization. Vol. 17, No. 6, pp. 515-528. </li>
            <li>[11] Boateng, R., Mbarika, V. and Thomas, C. (2010). "When Web 2.0 becomes an organizational learning tool: evaluating Web 2.0 tools".
              Development and Learning in Organizations. Vol. 24, No. 3, pp. 17-20. </li>
            <li>[12] Dorfleitner, G., Hornuf, L., Schmitt, M., & Weber, M. (2017), FinTech in Germany. Springer International Publishing. </li>
            <li>[13] Bejjani G., (2000). E-commerce: The net effect on agribusiness, Capital investment and market makers, Morgan Stanley, 2000. </li>
            <li>[14] Anshari, M., Alas, Y., & Guan, L. S. (2015). Pervasive Knowledge, Social Networks, and Cloud Computing: E-Learning 2.0. Eurasia
              Journal of Mathematics, Science & Technology Education, 2015, 11 (5), p.909-921. DOI: 10.12973/eurasia.2015.1360a. </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="ls section_padding_top_50 section_padding_bottom_50">
    <div class="container">
      <div class="row pb-20">
        <div class="col-md-12 col-sm-12 pt-20 grey">
          <hr class="hrborder5">
          <p><img src="assets/images/img6.jpg" alt="crop insurance" class="img-responsive" /></p>
          <h4 class="text-center">Why crop insurance schemes fail poor farmers when they are needed the most</h4>
          <p class="small"><strong>Synopsis</strong> <br>
            Crop insurance schemes for poor farmers are linked to loan repayments and premium payments. This is what makes it impractical during times of disasters.</p>
          <div>
            <p class="pull-left"><img src="assets/images/img4.jpg" alt="farmer hanging himself" class="img-responsive" /></p>
            <p>If the image of a farmer hanging himself from a tree at Jantar Mantar in the
              Capital haunts India, it is because perhaps for the first time urban India came face
              to face with self-destruction that so far appeared to be happening thousands of
              miles away in a Bharat cityslickers found difficult to put a finger on.</p>
            <p>And if thousands of farmers are killing themselves on their ravaged fields, it's not
              just because the weather gods have been brutal; it's also because the protection
              from such climatic flippancy, in terms of crop insurance, has failed the farmer
              when he needed it the most.</p>
            <p>The suicides that are being reported from farms across the country clearly indicate that these schemes have not found
              enough takers. Worse, even in cases where the farmer has signed up for a policy, he may not be eligible to make a claim
              when he is badly hit on account of loan defaults.</p>
            <p>Research done by ET Magazine suggests two major flaws apart from several lesser evils. The two biggest problems with
              the design of these schemes is that, first, even extremely poor farmers are expected to pay the premium. Second, if the
              farmer gets trapped in a cycle of debt and defaults on his agricultural loan - to which his crop insurance scheme is
              linked - his policy becomes inoperative.</p>
          </div>
          <p>Thousands of farmers who have opened insurance plans through the Kisan Credit Card (KCC) scheme for instance and
            they cannot claim insurance because of unpaid dues on their bank loan.</p>
          <p>Earlier in the week, ET reported that home minister Rajnath Singh is keen to fast-track the much-proposed National
            Crop Income Insurance scheme, which will merge all farm-related insurance schemes and provide cover for productionrelated
            risks and price volatility.</p>
          <p>As India appears headed for yet another below-par monsoon, the home minister's initiative is doubtless welcome, but ifthe new schemes have to be efective, the government may need to plug some basic faws, suggest experts ET Magazinespoke to.</p>
          <div>
            <p class="pull-right"><img src="assets/images/img5.jpg" alt="canada" class="img-responsive" /></p>
            <h6>States with Low Insurance Cover (Source: Crop Insurance officials)</h6>
            <ul>
              <li>Punjab, Haryana, Madhya Pradesh, Western Uttar Pradesh</li>
              <li>Farmers here don't have any knowledge about insurance and remain without cover</li>
              <li>Small farmers have no incentive as they have to pay the premium</li>
              <li>In many cases farmers have written to banks saying they do not want insurance</li>
              <li>The banks have complied with such requests to meet targets although insurance is a compulsory feature of agriculturalloan schemes</li>
            </ul>
            <h6>The Modus Operandi:</h6>
            <ul>
              <li>Networks of farmers, bank oficials and agriculture department oficials run these rackets</li>
              <li>Government oficials show a higher loss while bank oficials help farmers insure the same land repeatedly</li>
            </ul>
          </div>
          <h6>Food For Thought</h6>
          <p>That farmers have to pay the premium has met with a backlash in multiple states with several farmer associations evenwriting to insurance companies to not debit the insurance premium. Given that the banks are under pressure to meettargets for the KCC, they decide to waive of the premium component.</p>
          <p>According to a senior oficial with a public sector insurance company, crop insurance schemes were linked to farm loansto provide comprehensive coverage. But since the premium has to be paid by the farmers, they prefer to do away with theinsurance component.</p>
          <p>"If you tell the farmer that the crop insurance will stop if you default on loan repayments, how is this helping him duringa crisis? This is important, given that the state decided to set up crop insurance schemes not only to help the farmer butalso from a food security perspective. Giving aid to small farmers in terms of financial stability is one way of ensuringfood security," says the oficer.</p>
          <h6>The Big Divide</h6>
          <p>These schemes have also broadly cleaved India into two parts with one set of states, which are under-penetrated ,reporting lack of awareness among farmers about insurance even as multiple frauds are being reported from the secondset of states that have better coverage.</p>
          <p>Talking to various experts who have implemented these schemes on the ground, ET Magazine got a sense of the hugedivide.</p>
          <p>An oficial who has worked on the held in several north Indian states for over two decades said some states that had anearly lead in setting up cooperative banks and other institutions were able to implement crop insurance schemes faster.These states also have had to repeatedly deal with drought, cyclones and other calamities.</p>
          <p>However, strong "local networks" of farmers, bank oficials and agriculture department oficials have cornered all theseschemes by perpetrating various frauds.</p>
          <p>The states where crop insurance frauds are being reported continuously include Gujarat, Maharashtra, Andhra Pradesh,Karnataka and Tamil Nadu. According to this oficial, farmers in some districts in these states pay of oficials handlingthe yield assessment.</p>
          <p>"In Mehsana in Gujarat, there was a case where the farmers had collected claims which suggested the groundnut yieldwas 32 quintals per acre. But when the state government did a re-check, they found the actual yield was around 450quintals," said this oficial.</p>
          <p>"There are districts in the country where the farmers only harvest insurance claims." The districts that report cropinsurance frauds regularly include Dharwad and Haveri in Karnataka, Sivaganga and Nagapattinam in Tamil Nadu,Aurangabad and Jalgaon in Maharashtra, the Saurashtra region of Gujarat and the Rayalaseema region of AndhraPradesh.</p>
          <p>Political infuence has also played a role in the acceptance of multiple claims over the same plot of land, which is themost common form of fraud. "If you have infuence, you can get the bank manager to sanction multiple loans and cropinsurance policies over the same plot under the names of various members of the family. This is almost the norm in thesedistricts," says the oficial.</p>
          <p>In Maharashtra, oficials say, farmers take up insurance only for seasons when they know they would sufer bigger losses.They are able to do so because a high court order has made crop insurance a strictly voluntary component of farm loans.There is also an ample time lag between the last date for acceptance of insurance policy applications and the time forassessing the monsoon pattern.</p>
          <p>Oficials say this afects their business case because the premium collected in the state is extremely low when comparedto the claims.</p>
          <h6>Lack of Awareness</h6>
          <p>Even as the insurance company struggles to deal with fraudulent claims in these states, there exists another set of stateswhere the coverage is poor and the farmers have little awareness. These states are led by Punjab which is yet to sign upfor any crop insurance policy. Haryana, western Uttar Pradesh and Madhya Pradesh are some of the other states thathave a poor record of getting crop insurance schemes implemented.</p>
          <p>When ET Magazine visited Bundelkhand to take stock of the condition of farmers after unseasonal rain and hailstormdestroyed crops across India, more than 20 farmers said that although they did pay premium for a couple of years, theyhave never been able to claim insurance money despite sufering losses year after year.</p>
          <p>An oficer with a local bank that implements the KCC scheme said the farmers were not aware of how the insuranceschemes worked. Most of them, for instance, did not know that the policy becomes inoperative if they default onpayments. "The farmers do not know anything about the guidelines. The government has also not made any efort tomake them aware. This is why these schemes are not too efective," the bank manager said.</p>
          <p>An oficial with the government insurance company said the average small farmer in the country is estimated to earnaround Rs 1,700 per month and a 10% insurance premium — Rs 170 per bi-annual season - is a substantial amount forhim.</p>
          <p>Experts ET Magazine spoke to said India needs to legislate on crop insurance. A crop insurance expert suggested fvebroad points for an efective legislation. Firstly, banks should not be allowed discretionary powers of doing away withcrop insurance as a component of farm loans. Secondly, the premium component for small farmers should be paid for bythe state.</p>
          <p>Also, well-to-do farmers should not get any subsidy for premiums as this will also help curtail frauds pertaining to repeatclaims on the same plot of land. "But most importantly crop insurance policies should not come to a halt when a farmerdefaults on account of the stress on him. When he gets continuous protection from agricultural distress, he will bemotivated to continue with farming," said the expert.</p>
          <h6>How Loans Going Bad Also Result in Unpaid Insurance Premiums</h6>
          <ul>
            <li>Farmer takes a loan to meet cost of cultivation and for working capital; insurance premium is deducted</li>
            <li>Drought, food or, as was seen recently, unseasonal rains damage the crop</li>
            <li>The farmer is unable to repay loan; as a result the insurance premiums can't be paid; insurance goes to waste</li>
            <li>Farmer takes a loan from a moneylender to pay of previous loans</li>
            <li>The moneylender gets aggressive in case of default and threatens him. Many a time, the humiliation causes the farmerto commit suicide</li>
          </ul>
        </div>
      </div>
    </div>
  </section>