import { Component, OnInit } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';
import { EnquiryDetails, SimpleResponse } from '../enquiries/enquiry.service';
import { Otp } from '../enquiries/enquiry.service';
import { EnquiryService } from '../enquiries/enquiry.service';

@Component({
  selector: 'app-corporate',
  templateUrl: './corporate.component.html',
  styleUrls: ['./corporate.component.css']
})
export class CorporateComponent implements OnInit {
  enquiryModel : EnquiryDetails;
  selectedEnquiry : string;
  constructor(private enquiryService: EnquiryService,private renderer2: Renderer2) { }
  response : SimpleResponse;
  clicked: boolean;
  otp: string;
  otpHide: boolean;
  submitHide: boolean;
  goSubmit: boolean;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";  

  ngOnInit(): void {
    console.log('in init');
    const s = this.renderer2.createElement('script');
    s.text = '$(\'.show-less-div-1\').myOwnLineShowMoreLess({showLessLine:4});';
    this.renderer2.appendChild(document.body, s);
    // this.renderer2.appendChild(document.body, s);
    this.clicked=true;
    this.selectedEnquiry = "";
    this.enquiryModel = new EnquiryDetails();
    this.otp="";
    this.otpHide=true;
    this.submitHide=true;
    this.response = new SimpleResponse();

  }
  saveEnquiry(enquiryData : EnquiryDetails) {
    this.goSubmit = true;
    console.log('Your form data : ', enquiryData);
    enquiryData.category = this.selectedEnquiry;
    enquiryData.type = "Solutions";
    if(this.enquiryModel.name==null || this.enquiryModel.name==="" ){
      this.response.message = 'Name is required..';
      this.goSubmit = false;
    }
    // if(this.enquiryModel.phone==null || this.enquiryModel.phone==="" || this.enquiryModel.phone.length !=10 ){
    //   this.response.message = 'Invalid Phone Is ..';
    //   this.goSubmit = false;
    // }
  if(this.goSubmit){  
    this.enquiryService.saveEnquiry(enquiryData).subscribe(data => {
      this.response = data ;
      console.log(this.response);
      this.clicked=true;
      console.log(this.clicked);  
    })
}
  }

  showEnquiry(category){
    this.selectedEnquiry = category;
    console.log(category);
  }
  resetButton(){
    this.enquiryModel =  new EnquiryDetails();
    this.clicked=true;
    this.response.message="";
    this.otpHide=true;
    this.submitHide=true;
  }
  onFocusOutEvent(event: any){
    console.log(event.target.value);
    console.log("mobile number is not valid");
    if(this.enquiryModel.phone!=null && this.enquiryModel.phone.length == 10){
      console.log("mobile number is valid");
      this.enquiryService.sendOtp(this.enquiryModel.phone).subscribe(data => {
        this.response = data ;
        console.log(this.response);
        if(this.response !=null && this.response.messageType === "info"){
          this.otpHide = false;
        }
        
      })
      // disable before uploading
      //this.otpHide = false;
    }
  }
  verifyOtp(){
    console.log(this.otp);
    if(this.otp !=null && this.otp.length==4){
    var phoneOtp = new Otp();
    phoneOtp.phoneNumber = this.enquiryModel.phone;
    phoneOtp.otp = this.otp;
    this.enquiryService.verifyOtp(phoneOtp).subscribe(data => {
      this.response = data ;
      console.log(this.response);
      if(this.response !=null && this.response.messageType === "success"){
        this.otpHide = true;
        this.submitHide=false; 
        this.response.message='OTP Verified Successfully';
        this.clicked=false;
      }
      
    })
    //this.submitHide=false; 
    
    }else{
      this.response.message = 'Invalid OTP';
    }
  }

}
