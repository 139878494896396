<div id="canvas">
    <div id="box_wrapper">
      <section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h2>Investors</h2>
              <ol class="breadcrumb greylinks color4">
                <li> <a href="index.html"> Home </a> </li>
                <li class="active">About Us</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section id="about" class="ls section_padding_top_50 section_padding_bottom_50">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <h4>Australia</h4>
              <p><a href="https://www.artesianinvest.com" target="_new">
                 <img src="assets/images/artesian.png" alt="Artesian" class="img-responsive" /> </a>
                </p>
              <p class="grey">Artesian, is a global alternative investment management firm specializing in debt, venture capital and impact investment strategies. <strong>Artesian</strong> is a signatory to the United Nations Principles for responsible investment.</p>
              <p class="grey">Pitching it to International investors @ Hongkong , Thanks a lot Brinc for supporting "HireMyFarmer" under your portfolio.</p>
            </div>
            <div class="col-md-6 col-sm-12">
              <p><img src="assets/images/brinc-teams.jpg" alt="brinc" class="img-responsive"></p>
            </div>
          </div>
        </div>
      </section>
      <section id="about" class="ls ms section_padding_top_50 section_padding_bottom_50">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <h4>USA</h4>
              <div class="pull-left rightpadding_20">
              <p><a href="https://www.foodfuture.co"> 
                <img src="assets/images/FoodFutureCo.png" alt="FoodFutureCo" /></a>
              </p>
              </div>
              <p class="grey"><strong>FoodFutureCo</strong> is a scale-up accelerator for small, yet established companies that provide unique products and Solutions across their food system. FoodFutureCo focus areas include: Consumer Products, Local Food, Plant-based Food, Sustainable Seafood, AG Tech, Food Tech and Food Waste.</p>
              <h6>Pitching is still On!!! </h6>
              <p class="grey">During the pandemic hit of covid-19 times, struggle to raise second round of funding was successful to HireMyFarmer, though the operations were hampered at Supply chain but we have represented virtually to International investors & partners on planning our company strategy and milestones.</p>
            </div>
            <div class="col-md-6 col-sm-12">
              <p><img src="assets/images/video-clip.jpg" alt="FoodFutureCo" /></p>
            </div>
          </div>
        </div>
      </section>  
      
        </div>
</div>