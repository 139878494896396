<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2>Careers</h2>
        <ol class="breadcrumb greylinks color4">
          <li> <a href="index.html"> Home </a> </li>
          <li class="active">Careers</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50">
  <div class="container">
    <div class="row grey pb-20">
      <h5 class="text-center">IT (Information Technology)</h5>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Application Developer</li>
          <li>Applications Engineer</li>
          <li>Associate Developer</li>
          <li>Java Developer</li>
          <li>Junior Software Engineer</li>
          <li>.NET Developer</li>
          <li>Programmer Analyst</li>
          <li>Senior Applications Engineer</li>
          <li>Senior Programmer Analyst</li>
          <li>Senior Software Engineer</li>
          <li>Senior System Architect</li>
          <li>Software Architect</li>
          <li>Software Quality Assurance Analyst</li>
          <li>Cloud Architect</li>
          <li>Cloud Consultant</li>
        </ul>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Cloud Product and Project Manager</li>
          <li>Cloud Services Developer</li>
          <li>Cloud Software and Network Engineer</li>
          <li>Cloud System Administrator</li>
          <li>Cloud System Engineer</li>
          <li>Customer Support Specialist</li>
          <li>Desktop Support Manager</li>
          <li>Help Desk Specialist</li>
          <li>IT Support Manager</li>
          <li>IT Systems Administrator</li>
          <li>Senior Support Specialist</li>
          <li>Senior System Administrator</li>
          <li>Embedded system engineer </li>
          <li>IT Manager</li>
          <li>Management Information Systems Director</li>
        </ul>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Technical Operations Officer</li>
          <li>Data Center Support Specialist</li>
          <li>Data Quality Manager</li>
          <li>Senior Database Administrator</li>
          <li>Computer and Information Systems Manager</li>
          <li>Computer Network Architect</li>
          <li>Computer Systems Analyst</li>
          <li>IT Analyst</li>
          <li>IT Coordinator</li>
          <li>Graphic design</li>
          <li>IOT engineers</li>
          <li>Software testing</li>
          <li>Satellite engineer</li>
          <li>Server and infrastructure management</li>
        </ul>
      </div>
    </div>
    <div class="row grey pb-20">
      <h5 class="text-center">Agriculture</h5>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Agriculture Engineer</li>
          <li>Agriculture Economist</li>
          <li>Farm manager</li>
          <li>Plant scientist</li>
          <li>Conservation planner</li>
          <li>Agriculture salesperson</li>
        </ul>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Biochemist</li>
          <li>Environmental Engineer</li>
          <li>Bioinformatics Scientist</li>
          <li>Agronomy Sales Manager</li>
          <li>Food Scientist</li>
          <li>Geospatial Analytics Scientist</li>
        </ul>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Irrigation Engineer</li>
          <li>Crop Adjuster</li>
          <li>Food engineers</li>
          <li>Crop processing analysis</li>
        </ul>
      </div>
    </div>
    <div class="row grey pb-20">
      <h5 class="text-center">Fintech</h5>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Financial Analysts</li>
          <li>Risk Control Managers</li>
          <li>Compliance Expert</li>
          <li>Customer support, fintech layer</li>
        </ul>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Product Manager</li>
          <li>Block chain Analyst</li>
          <li>Quality analyst </li>
          <li>Data scientist</li>
        </ul>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Investment banking analyst</li>
          <li>Cybersecurity Analysts/Experts</li>
        </ul>
      </div>
    </div>
    <div class="row grey pb-20">
      <h5 class="text-center">Supply Chain Management</h5>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Supply chain data Analyst</li>
          <li>Inventory Manager</li>
          <li>Supervisor & Employee Trainer</li>
        </ul>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Delivery and Logistics Manager</li>
          <li>Order Management executive</li>
          <li>Customer support management executive </li>
        </ul>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Strategy specialist</li>
          <li>Manufacturing operations manager</li>
          <li>Operations Manager</li>
        </ul>
      </div>
    </div>
    <div class="row grey pb-20">
      <h5 class="text-center">Management</h5>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Business Developer Associate</li>
          <li>Business Developer executive</li>
          <li>Area sales manager</li>
          <li>Marketing</li>
          <li>Operations Head</li>
        </ul>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>HR operations department </li>
          <li>Recruitment manager</li>
          <li>Customer operations head</li>
          <li>Content writer</li>
          <li>Business analyst</li>
        </ul>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Accounts manager</li>
          <li>Product manager</li>
          <li>Strategy& emerging markets head</li>
          <li>Social media marketing</li>
          <li>PR handling</li>
        </ul>
      </div>
    </div>
    <div class="row grey pb-20">
      <h5 class="text-center">Research & Development</h5>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Agriculture research scholar</li>
          <li>IT research scholar</li>
          <li>Legal research</li>
          <li>Market/business research </li>
          <li>Political research</li>
          <li>Policy research</li>
          <li>Agriculture research</li>
        </ul>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Food technology research</li>
          <li>Crop research scholars</li>
          <li>Agronomists</li>
          <li>Big Data research scholars</li>
          <li>Artificial intelligence In precision farming</li>
          <li>Food and nutrition security</li>
          <li>Statistical research</li>
        </ul>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <ul>
          <li>Bioinformatics scientist </li>
          <li>Arborist scientist </li>
          <li>Sustainable seeds (organic) research</li>
          <li>Machine learning in satellite imagery </li>
          <li>Agro forestry research</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<section class="ls ms section_padding_top_50">
  <div class="container">
    <div class="row text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <div class="col-sm-12 col-12 pb-20">
          <a href="#" data-toggle="modal" data-target="#upload-resume"><img src="assets/images/career/IT.jpg"
              alt="cloud" class="img-responsive img-thumbnail" /></a>
          <h6>Information Technology</h6>
        </div>
        <div class="col-sm-12 col-12 pb-20"> <a href="#" data-toggle="modal" data-target="#upload-resume"><img
              src="assets/images/career/Agriculture.png" alt="Agriculture" class="img-responsive img-thumbnail" /></a>
          <h6>Agriculture</h6>
        </div>
        <div class="col-sm-12 col-12 pb-20"> <a href="#" data-toggle="modal" data-target="#upload-resume"><img
              src="assets/images/career/Fintech.jpg" alt="Fintech" class="img-responsive img-thumbnail" /></a>
          <h6>Fintech</h6>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <div class="col-sm-12 col-12 pb-20">
          <h5 class="padding_top_25">An opportunity to be a part of disruptive initiative!!!!</h5>
          <p><img src="assets/images/funny-farmer-cartoon.png" alt="funny-farmer-cartoon" /></p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <div class="col-sm-12 col-12 pb-20"> <a href="#" data-toggle="modal" data-target="#upload-resume"><img
              src="assets/images/career/Supply_Chain_Management.png" alt="supply" class="img-responsive img-thumbnail" /></a>
          <h6>Supply Chain Management</h6>
        </div>
        <div class="col-sm-12 col-12 pb-20"> <a href="#" data-toggle="modal" data-target="#upload-resume"><img
              src="assets/images/career/Management.png" alt="Management" class="img-responsive img-thumbnail" /></a>
          <h6>Management</h6>
        </div>
        <div class="col-sm-12 col-12 pb-20"> <a href="#" data-toggle="modal" data-target="#upload-resume"><img
              src="assets/images/career/Research_and_Development.png" alt="Research" class="img-responsive img-thumbnail" /></a>
          <h6>Research & Development</h6>
        </div>
      </div>
    </div>
  </div>
</section>