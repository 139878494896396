<div id="canvas">
  <div id="box_wrapper">
    <section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h2>Solutions</h2>
            <ol class="breadcrumb greylinks color4">
              <li> <a href="index.html"> Home </a> </li>
              <li class="active">Solutions</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="ls ms section_padding_top_50 section_padding_bottom_50">
      <div class="container">
        <div class="row pb-50">
          <div class="col-md-10 col-md-offset-1 col-sm-12 text-center section_padding_bottom_50">
            <h3>Building Technology For The Farmer</h3>
            <p class="grey">This is unique platform provides incubation layer for the agriculture services where crop dependent farmers displayed to FMCG, Retail, Restaurants, Hotels and Travel industry to order and deliver the stock to their locations direct from the farmers with better prices.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <div class="service-block-five">
              <div class="inner-box">
                <div class="image-box"> <img src="assets/images/pic1.jpg" alt="Government" class="img-responsive img-thumbnail" />
                  <div class="caption">Government</div>
                  <div class="overlay-box">
                    <h3>Government</h3>
                    <div class="text">Real-time Governance with e-agriculture services and Big data integration for a Government...</div>
                    <a class="read-more" href="government.html">READ MORE <span class="fa fa-angle-double-right"></span></a> </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <div class="service-block-five">
              <div class="inner-box">
                <div class="image-box"> <img src="assets/images/pic2.jpg" alt="Corporate" class="img-responsive img-thumbnail" />
                  <div class="caption">Corporate</div>
                  <div class="overlay-box">
                    <h3>Corporate</h3>
                    <div class="text">HireMyFarmer unique service provides a corporate dignity to the farmer service by connecting...</div>
                    <a class="read-more" href="corporate.html">READ MORE <span class="fa fa-angle-double-right"></span></a> </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <div class="service-block-five">
              <div class="inner-box">
                <div class="image-box"> <img src="assets/images/pic3.jpg" alt="Banking" class="img-responsive img-thumbnail" />
                  <div class="caption">Banking</div>
                  <div class="overlay-box">
                    <h3>Banking</h3>
                    <div class="text">These men in promptly escaped from that  a maximum security stockade.</div>
                    <a class="read-more" href="banking.html">READ MORE <span class="fa fa-angle-double-right"></span></a> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <div class="service-block-five">
              <div class="inner-box">
                <div class="image-box"> <img src="assets/images/pic4.jpg" alt="Insurance" class="img-responsive img-thumbnail" />
                  <div class="caption">Insurance</div>
                  <div class="overlay-box">
                    <h3>Insurance</h3>
                    <div class="text">HireMyFarmer make sures every farmer follows the compliance and..</div>
                    <a class="read-more" href="insurance.html">READ MORE <span class="fa fa-angle-double-right"></span></a> </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <div class="service-block-five">
              <div class="inner-box">
                <div class="image-box"> <img src="assets/images/pic5.jpg" alt="Third Party Systems" class="img-responsive img-thumbnail" />
                  <div class="caption">Third Party Systems</div>
                  <div class="overlay-box">
                    <h3>Third Party Systems</h3>
                    <div class="text">HireMyFarmer has pre-harvest service layer where the Bio pesticide companies...</div>
                    <a class="read-more" href="third-party-systems.html">READ MORE <span class="fa fa-angle-double-right"></span></a> </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
            <div class="service-block-five">
              <div class="inner-box">
                <div class="image-box"> <img src="assets/images/pic6.jpg" alt="Business" class="img-responsive img-thumbnail" />
                  <div class="caption">Business</div>
                  <div class="overlay-box">
                    <h3>Business</h3>
                    <div class="text">We are providing farmer services to FMCGs, retailers market dealers, agri based product...</div>
                    <a class="read-more" href="business.html">READ MORE <span class="fa fa-angle-double-right"></span></a> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="ls section_padding_top_50 section_padding_bottom_50">
      <div class="container">
        <div class="row grey">
          <h4 class="text-center">Integrated Solution</h4>
          <div class="col-md-4 col-sm-6 mb-30">
            <p><i class="fa fa-check color-green"></i> Post Harvest Support</p>
            <p><i class="fa fa-check color-green"></i> Market linkages to farmers (B2B / B2C)</p>
            <p><i class="fa fa-check color-green"></i> Processing facilitation</p>
          </div>
          <div class="col-md-4 col-sm-6 mb-30">
            <p><i class="fa fa-check color-green"></i> On-Boarding Farmers to centralized system</p>
            <p><i class="fa fa-check color-green"></i> Third Party Support </p>
          </div>
          <div class="col-md-4 col-sm-6">
            <p><i class="fa fa-check color-green"></i> Monitoring farmlands of individual farmers</p>
            <p><i class="fa fa-check color-green"></i> Alerts to On-Boarded individual farmers</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>