import { Component, OnInit } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';

@Component({
  selector: 'app-global-partners',
  templateUrl: './global-partners.component.html',
  styleUrls: ['./global-partners.component.css']
})
export class GlobalPartnersComponent implements OnInit {

  constructor(private renderer2: Renderer2) { }

  ngOnInit(): void {
    console.log('in init');
    const s = this.renderer2.createElement('script');
    s.text = '$(\'.show-less-div-1\').myOwnLineShowMoreLess({showLessLine:4});';
    this.renderer2.appendChild(document.body, s);

  }

}
