<div id="canvas">
    <div id="box_wrapper">
        <section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h2>Global Partners</h2>
                        <ol class="breadcrumb greylinks color4">
                            <li> <a href="/"> Home </a> </li>
                            <li class="active">About Us</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section id="about" class="ls section_padding_top_50 section_padding_bottom_30">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <div class="pb-20">
                            <p><a href="https://www.brinc.io" target="_new" >
                                <img src="assets/images/brinc.png" alt="Brinc" /> </a> </p>
                            <div class="grey show-less-div-1">Brinc is a different breed of venture capital and
                                accelerator firm. Core to our business is our belief that some of the world's biggest
                                challenges can be solved by entrepreneurs, who we like to call GAME CHANGERS. More game
                                changers will make a positive impact on the world if they are given the right backing.
                                And that's what we're here for. To support and mentor founders and their teams from
                                across the world and provide investment to help them grow. Today, we believe that
                                technology focused startups, with the right backing and support can fundamentally change
                                how we move, what we eat, how we feel and where we live for the better.</div>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://satyukt.com" target="_new" >
                                <img src="assets/images/satyukt.png" alt="Satyukt" /></a>
                            </p>
                            <p class="grey show-less-div-1">Satyukt is an agritech company that provides business to
                                business (B2B) agricultural data and services across providers. Satyukt data and
                                Solutions are built based on the scientific research and physically scalable algorithms
                                validated across the globe. At Satyukt Analytics, they bring you insight into the crop
                                area, health, risk, production at your fingertips.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://www.ccsniam.gov.in" target="_new" >
                                <img src="assets/images/niam.png" alt="National Institute of Agricultural Marketing" /></a>
                            </p>
                            <p class="grey show-less-div-1">The CCS National Institute of Agricultural Marketing (NIAM)
                                is a premier National level Institute set up by the Government of India in August 1988
                                to offer specialized Training, Research, Consultancy and Education in Agricultural
                                Marketing. NIAM is an autonomous body under the aegis of the Ministry of Agriculture,
                                Government of India. It was set up as a Registered Society to cater to the needs of
                                Agricultural Marketing personnel in India as well as from South East Asian countries.
                                The Union Minister for Agriculture is the President of the General body of NIAM and
                                Secretary, Department of Agriculture and Cooperation is the Chairman of the Executive
                                Committee.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://bizrights.com" target="_new" >
                                <img src="assets/images/bizrights.png" alt="Bizrights IP" /></a>
                            </p>
                            <div class="grey show-less-div-1">Bizrights IP is a Intellectual Property Firm that
                                specializes in the provision of professional services related to intellectual property,
                                particularly Trademarks, Patent, Copyrights and Industrial Designs.</div>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://www.startupindia.gov.in" target="_new" >
                                <img src="assets/images/startup-india-hub.png" alt="Startup India Hub" /></a>
                            </p>
                            <p class="grey show-less-div-1">Startup India Hub is a one-stop platform for all
                                stakeholders in the Startup ecosystem to interact amongst each other, exchange knowledge
                                and form successful partnerships in a highly dynamic environment.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://angelhub.io" target="_new" >
                                <img src="assets/images/angelhub.png" alt="AngelHub WHub" /></a>
                            </p>
                            <div class="show-less-div-1">
                                <p class="grey">AngelHub bears a lot of stress on fundraising and negotiation with
                                    different parties and investors so that they can focus on client acquisition and
                                    product development. Moreover, AngelHub can gain access to a much larger pool of
                                    investors and capital with the help of AngelHub. The team was highly attentive to
                                    the details we provided and quick to respond to any investor query.</p>
                                <p>WHub.io being Hong Kong's biggest startup community & power connector, developing a
                                    full service offer around our three main pillars of Showcasing, Connecting and
                                    Empowering</p>
                            </div>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://www.cgiar.org" target="_new" >
                                <img src="assets/images/CGIAR.png" alt="CGIAR" /></a>
                            </p>
                            <p class="grey show-less-div-1">CGIAR is a global partnership that unites international
                                organizations engaged in research about food security. CGIAR research aims to reduce
                                rural poverty, increase food security, improve human health and nutrition, and
                                sustainable management of natural resources.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="http://www.vibrantgujarat.com" target="_new" >
                                <img src="assets/images/vibrant-gujarat.png" alt="Vibrant Gujarat Summit" /></a>
                            </p>
                            <p class="grey show-less-div-1">Vibrant Gujarat Summit was conceptualized in 2003 by Hon'ble
                                Prime Minister of India and the then Hon'ble Chief Minister of Gujarat, Mr. Narendra
                                Modi, to re-establish Gujarat as a preferred investment destination within India. Today,
                                the Summit has evolved into a platform for brainstorming on agendas of global
                                socio-economic development, in addition to being a facilitator for knowledge sharing and
                                forging effective partnerships.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://tie.org" target="_new" >
                                <img src="assets/images/tie.png" alt="TiE" /></a>
                            </p>
                            <p class="grey show-less-div-1">The mission of TiE is to foster entrepreneurship through
                                mentoring, networking, education, funding and incubation. With a focus of giving back to
                                the community, TiE's focus is on generating and nurturing the next generation of
                                Entrepreneurs</p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="pb-20">
                            <p><a href="https://www.geocledian.com" target="_new" >
                                <img src="assets/images/geocledian.png" alt="Brinc" /></a>
                            </p>
                            <p class="grey show-less-div-1">Geocledian an IT company specialized in designing and
                                providing geospatial cloud services. It's goal is to provide high quality, easy-to-use
                                and low cost information products based on open satellite data and other sources.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://www.cii.in" target="_new" >
                                <img src="assets/images/indian-industry.png" alt="Indian Industry" /></a>
                            </p>
                            <p class="grey show-less-div-1">The Confederation of Indian Industry (CII) works to create
                                and sustain an environment conducive to the development of India, partnering industry,
                                Government and civil society, through advisory and consultative processes. For 125
                                years, CII has been working on shaping India's development journey and this year, more
                                than ever before, it will continue to proactively transform Indian industry's engagement
                                in national development.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://www.f6s.com" target="_new" >
                                <img src="assets/images/f6s.png" alt="Funding" /></a>
                            </p>
                            <p class="grey">Connect with Funding from Accelerators, Funds & Investors, Get help with
                                free stuff, talent search & exposure and Grow your Startup in the #1 global community.
                            </p>
                        </div>
                        <div class="pb-20">
                            <p><a href="http://www.sgpassociates.com" target="_new" >
                                <img src="assets/images/sgp.png" alt="SGP" /></a>
                            </p>
                            <p class="grey show-less-div-1">SGP offer consulting, advisory, secretarial, governance,
                                compliance, and representation services to companies in India and abroad. They bring 60
                                + years of combined experience in corporate affairs to our customers and place a strong
                                emphasis on ethics and transparency tomaintain high professional standards.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://ventures.adb.org" target="_new" >
                                <img src="assets/images/adb-ventures.png" alt="ADB Ventures" /></a>
                            </p>
                            <p class="grey show-less-div-1">ADB Ventures invests for commercial returns. They target
                                technology-driven businesses with significant potential to scale and deliver impact in
                                developing Asia Pacific. Then they add value to invested companies by facilitating
                                access to Asian Development Bank expertise, operations, and networks. </p>
                        </div>
                        <div class="pb-20">
                            <p><a href="http://lifeautomation.org" target="_new" >
                                <img src="assets/images/life-automation.png" alt="LIFE AUTOMATION" /></a>
                            </p>
                            <p class="grey">"LIFE AUTOMATION", are Partnership Firm, engaged in manufacturing, trading,
                                wholesaling and retailing a qualitative assortment of Vending Machine, Solid Waste
                                Incinerator, etc.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://t-hub.co" target="_new" >
                                <img src="assets/images/T-Hub.png" alt="T-Hub" /></a>
                            </p>
                            <p class="grey show-less-div-1">T-Hub pioneer India's leading innovation ecosystem. It
                                synergise start-ups, corporations, governments, academia and investors to drive
                                transformative change. T-Hub innovation ecosystem stands firmly on seven key pillars
                                bridging the gap between visionary entrepreneurs and corporates seeking the next big
                                idea, and everyone in between.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://krushiculture.com" target="_new" >
                                <img src="assets/images/KrushiCulture.png" alt="KrushiCulture" /></a>
                            </p>
                            <p class="grey show-less-div-1">KrushiCulture is a platform for farmers to sell and
                                consumers to buy chemical free natural(ZBNF) food products. KrushiCulture is also
                                working towards creating awareness about natural farming and healthy living.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://www.growasia.org" target="_new" >
                                <img src="assets/images/grow-asia.png" alt="Grow Asia" /></a>
                            </p>
                            <p class="grey show-less-div-1">Grow Asia is a unique multi-stakeholder partnership platform
                                that brings together farmers, governments, the private sector, NGOs and other
                                stakeholders in Southeast Asia to lift the productivity, profitability and environmental
                                sustainability of smallholder agriculture in the region</p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="pb-20">
                            <p><a href="https://innmind.com" target="_new" >
                                <img src="assets/images/InnMind.png" alt="InnMind" /></a>
                            </p>
                            <p class="grey show-less-div-1">InnMind is a unique ecosystem of like-minded people,
                                inspired by innovative entrepreneurship, business driven technologies and idea of
                                changing the world. It connects startups, investors, service providers, Industry leaders
                                with startups ready to raise and show themselves to the world.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://www.isb.edu" target="_new" >
                                <img src="assets/images/isb.png" alt="Indian School of Business" /></a>
                            </p>
                            <p class="grey show-less-div-1">The Indian School of Business (ISB) evolved from the need
                                for a world-class business school in Asia. The founders, some of the best minds from the
                                corporate and academic worlds, anticipated the leadership needs of the emerging Asian
                                economies.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://www.nabard.org" target="_new" >
                                <img src="assets/images/nabard.png" alt="nabard" /></a>
                            </p>
                            <p class="grey show-less-div-1">The importance of institutional credit in boosting rural
                                economy has been clear to the Government of India right from its early stages of
                                planning. Therefore, the Reserve Bank of India (RBI) at the insistence of the Government
                                of India, constituted a Committee to Review the Arrangements For Institutional Credit
                                for Agriculture and Rural Development (CRAFICARD) to look into these very critical
                                aspects. The Committee was formed on 30 March 1979, under the Chairmanship of Shri B.
                                Sivaraman, former member of Planning Commission, Government of India. </p>
                        </div>
                        <div class="pb-20">
                            <p><a href="http://www.apis.ap.gov.in" target="_new" >
                                <img src="assets/images/andhra-pradesh.png" alt="Andhra Pradesh" /></a>
                            </p>
                            <p class="grey show-less-div-1">The State of Andhra Pradesh shares a collective dream of a
                                new India where new generation software products would be manufactured creating
                                multiplier effects in growth of the state and nation, employment creation, social
                                transformation. Through the Innovation and Start-up Policy, the government intends to
                                create an ecosystem that produces an entrepreneur in every family</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://www.hkstp.org" target="_new" >
                                <img src="assets/images/HKSTP.png" alt="HKSTP" /></a>
                            </p>
                            <p class="grey show-less-div-1">HKSTP is the city's largest R&D base. They focus on
                                translational research, product development and go-to-market support for both local and
                                overseas markets. In recent years, They have deepened our focus in four strategic areas
                                – Artificial Intelligence and Robotics, Biomedical, Data and Smart City, Fintech – to
                                innovate, educate, and change the world. HKSTP continue to serve the innovation and
                                technology ecosystem through our five clusters: Biomedical Technology, Electronics,
                                Green Technology, Information and Communication Technology, and Material and Precision
                                Engineering.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://www.padang.co" target="_new" >
                                <img src="assets/images/padang.png" alt="Padang and Co " /></a>
                            </p>
                            <p class="grey show-less-div-1">The Padang & Co have been bringing people and organisations
                                together to solve problems, develop ideas and new Solutions. We create the environments
                                for their creativity and innovation to emerge and flourish.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://www.uncdf.org" target="_new" >
                                <img src="assets/images/UNCDF.png" alt="UNCDF" /></a>
                            </p>
                            <p class="grey">UNCDF offers "last mile" finance models that unlock public and private
                                resources, especially at the domestic level, to reduce poverty and support local
                                economic development.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://online.hbs.edu" target="_new" >
                                <img src="assets/images/hbs.png" alt="hbs" /></a>
                            </p>
                            <p class="grey show-less-div-1">The HBS Blog offers insight on Delaware LLCs and
                                corporations as well as information about entrepreneurship, start-ups and other business
                                topics. The right place to form your Delaware LLC or set up a Delaware corporation.</p>
                        </div>
                        <div class="pb-20">
                            <p><a href="https://hysea.in" target="_new" >
                                <img src="assets/images/HYSEA.png" alt="HYSEA" /></a>
                            </p>
                            <p class="grey show-less-div-1">HYSEA is a unique industry initiative and a non-profit
                                organization that brings together the various IT and ITeS Organizations in Telangana
                                under one umbrella to network, share knowledge and work closely with the Government and
                                other industry stake holders for business growth, social development and economic
                                prosperity of the state.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>