import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../oauth2/token.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isUserLoggedIn: boolean;
  title : String;
  constructor(private tokenService: TokenService,private router: Router) { 
    if (this.tokenService.getRefreshToken()) {
      console.log('in init')
      this.isUserLoggedIn = true;   
      this.title = "Logout";   
    }else{
      console.log('in init else')
      this.isUserLoggedIn = false; 
      this.title = "Login";   
    }
  } 

  ngOnInit(): void {
    console.log("header path "+this.router.url)
  }

  logout(){
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    console.log('token is removed')
    
  }
}
