<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h2>Corporate</h2>
          <ol class="breadcrumb greylinks color4">
            <li> <a href="index.html"> Home </a> </li>
            <li> <a href="solutions.html"> Solutions </a> </li>
            <li class="active">Corporate</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="ls section_padding_top_50 section_padding_bottom_50">
    <div class="container">
      <div class="row pb-50">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/rural-indian-food-market-tim-gainey.jpg" alt="market" class="img-responsive"/></a></div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <h5>Farmer market place</h5>
          <ul class="timeline">
            <li class="grey">Hire My Farmer unique service provides a corporate dignity to the farmer service by connecting the cluster of farmers to dedicated enterprise market. </li>
            <li class="grey">Outsourcing of farmers to specific Business not on contractual basis but on production basis,
Cluster based farmer service marketing to Retail industry. Facilitation of Farmers Hub to manage the post-harvest related Third-parties like Rental machinery companies and Logistics management services. </li>
            <!--<li class="grey">Facilitation of Farmers Hub to manage the post-harvest related Third-parties like Rental machinery companies and Logistics management services.</li>-->
          </ul>
          <p class="grey">If you have any queries please feel free to contact us <a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
             (click)="showEnquiry('Farmer market place')">Enquiry Now</a></p>
        </div>
      </div>
      <div class="row pb-50">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <h5>Farmer stock exchange</h5>
          <ul class="timeline">
            <li class="grey">HireMyFarmer provides the transparency of the availability of stock to authorized and authenticated community with a commodity related information, production information, warehouses information, auction services, cold storages availabilities, Micro cold storages include Logistics integration services.</li>
            <li class="grey">Farmers stock is traded in this platform through stock brokers based from agriculture commodity, where it is similar as actual stock market through this service, farmers stock is been traded with commodity exchange and international market to plan for the production and to make sure the consumer basket index doesn’t fluctuates and get control of the supply and demand for a healthy living.</li>
          </ul>
          <p class="grey">If you have any queries please feel free to contact us 
            <a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
             (click)="showEnquiry('Farmer stock exchange')">Enquiry Now</a></p>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/farmer_market_exchange_new.jpg" alt="stock" class="img-responsive"/></a></div>
      </div>
      <div class="row pb-50">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/community.jpg" alt="community" class="img-responsive"/></a></div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <h5>Farmer community centre</h5>
          <ul class="timeline">
            <li class="grey">HireMyFarmer community centre facilitates technology to the service providers where the farmers network will be reached out with a click of a button.</li>
            <li class="grey">Pre-harvest, Post-harvest agri ecosystem information along with cluster specific farmers operations will be tracked through satellite imagery services.</li>
            <li class="grey">These centres will have access to huge network of farmers base covering all available crops for both the seasons. 
</li>
            <li class="grey">These centres will be an integration channel to manage and operate pre/post-harvest activities for a cluster based operation to operate farmers in specific region.</li>
          </ul>
          <p class="grey">If you have any queries please feel free to contact us <a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
             (click)="showEnquiry('Farmer community centre')">Enquiry Now</a></p>
        </div>
      </div>
      <div class="row pb-50">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <h5>Farmer experience store</h5>
          <ul class="timeline">
            <li class="grey">HireMyFarmer experience stores are the actual Farmers store is showcased in Metro cities to making sure to promote “rural to urban” connectivity, where farmers of the nearest cluster will have a dedicated Retail outlet space to show what they grow and how they grow.</li>
            <li class="grey">Urban stores for experiencing the farmers service to every city will have sentimental index for the bond of farmers to urban cities.</li>
            <li class="grey">Each store will have a theatre to show farmer’s story, Kiosk’s to keep track of the updates on the farming operations in a specific cluster, Vending Machines which broadcasts the origin of the production along with Farmers information.</li>
            <li class="grey">Pop up stores for the Third parties to showcase their product / service.</li>
          </ul>
          <p class="grey">If you have any queries please feel free to contact us <a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
            (click)="showEnquiry('Farmer experience store')">Enquiry Now</a></p>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/store.jpg" alt="store" class="img-responsive"/></a></div>
      </div>
      <div class="row pb-50">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/crops.jpg" alt="crops" class="img-responsive"/></a></div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <h5>Incubation on crops</h5>
          <ul class="timeline">
            <li class="grey">HireMyFarmer incubates each farmer and farming land through this platform, any individual who would like to do farming can join this community.</li>
            <li class="grey">Welcome to the real life Farmville, where your farm will be managed by you and HireMyFarmer facilitates you with managed services along with updated real-time reporting.</li>
          </ul>
          <p class="grey">If you have any queries please feel free to contact us 
            <a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
             (click)="showEnquiry('Incubation on crops')">Enquiry Now</a></p>
        </div>
      </div>
      <div class="row pb-50">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <h5>Support on E-market place</h5>
          <ul class="timeline">
            <li class="grey">HireMyFarmer supports online retail food chain industry & retail business entities where they can plan production through us based on the online POS integration.</li>
            <li class="grey">This E-market place facilitates with a larger connectivity to post harvest scope with an access to farmers, infrastructure like cold storages, processing units, and logistics systems.</li>
          </ul>
          <p class="grey">If you have any queries please feel free to contact us <a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
            (click)="showEnquiry('Support on E-market place')">Enquiry Now</a></p>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/on_e_market_place.png" alt="emarket" class="img-responsive"/></a></div>
      </div>
      <div class="row pb-50 text-center">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <p><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/icon1.png" alt="retail" /></a></p>
          <h5>Online retail stores vendors</h5>
          <p class="grey show-less-div-1">Choose your product’s plan for your production and start selling with your own brand and plan your integration with us to overcome the market inflation by opting the transparency of actual product cost with a detailed break up structure of the services by third-party.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/zJDQ_0on2hM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
          <p><a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
             (click)="showEnquiry('Online retail stores vendors')">Enquiry Now</a></p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <p><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/icon2.png" alt="Farmer stock exchange" /></a></p>
          <h5>Systematic investment plan</h5>
          <p class="grey show-less-div-1">HireMyFarmer has an exclusive fintech layer which supports individual investor or a group who can invest in pre/post-harvest interval where an investor can keep track of his investments and get returns for each season respectively.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/pI1O8PQxJ2w" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
          <p><a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
            (click)="showEnquiry('Systematic investment plan')">Enquiry Now</a></p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <p><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/icon3.png" alt="Farmer community centre" /></a></p>
          <h5>Hire a farmer to your farm</h5>
          <p class="grey show-less-div-1">HireMyFarmer brings an opportunity to all landlords or people who has farmlands where they can hire farmers in their cluster of region. We will outsource you the best chosen farmer to your productive lands where you can utilize our services globally. <strong>We cover India as of now !</strong></p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/kQyToXB4sbo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
          <p><a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
             (click)="showEnquiry('Hire a farmer to your farm')">Enquiry Now</a></p>
        </div>
      </div>
      <div class="row pb-50 text-center">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <p><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/icon4.png" alt="market" /></a></p>
          <h5>Rent a farm</h5>
          <p class="grey show-less-div-1">HireMyFarmer facilitates the services which is reachable for every family where they can subscribe to a farming land and get healthy food to their home and services like keeping track of their farming land and their farmer, during weekends families can spend time with their farmers & agriculture lands. We are facilitating a bond between rural and urban to provide better income for a farmer. </p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/ZBUE6YZD338" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
          <p><a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
            (click)="showEnquiry('Rent a farm')">Enquiry Now</a></p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <p><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/icon5.png" alt="Farmer stock exchange" /></a></p>
          <h5>Transparency of services</h5>
          <p class="grey show-less-div-1">HireMyFarmer provides a transparent invoice details to our customers like –farmer product cost, manufacturing & processing cost, packaging cost, facilitating processing units to the customers to make sure the cost of product is showcased at right price to support customers business.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/hPuz8kE8Qx0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
          <p><a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
            (click)="showEnquiry('Transparency of services')">Enquiry Now</a></p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <p><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/icon6.png" alt="Farmer community centre" /></a></p>
          <h5>Farm land navigation services</h5>
          <p class="grey show-less-div-1">HireMyFarmer supports farmland services where it gets navigated to productive land which helps research institutes, departmental bodies, insurance companies to keep track of the productive land data and entire harvest information.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/3tpPU9JbIC8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
          <p><a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
            (click)="showEnquiry('Farm land navigation services')">Enquiry Now</a></p>
        </div>
      </div>
      <div class="row pb-50 text-center">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <p><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/icon7.png" alt="Farmer stock exchange" /></a></p>
          <h5>Integrating policy reforms in agriculture</h5>
          <p class="grey show-less-div-1">HireMyFarmer has a unique solution which unlocks agriculture ecosystem to make sure to sustainability in agriculture where the wireframes gets acknowledges with policy makers and making sure it integrates with technology and government.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/VKd6tc2lzaU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
          <p><a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
            (click)="showEnquiry('Integrating policy reforms in agriculture')">Enquiry Now</a></p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <p><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/icon8.png" alt="Farmer community centre" /></a></p>
          <h5>IOT based Solutions</h5>
          <p class="grey show-less-div-1">HireMyFarmer has come up with a social impact IOT based Vending Machine which creates a disruption In the market where the farmers stock is promoted in automated IOT based Vending machines and connecting payment of the customers directly reaches Into farmers pocket.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/em-Jwh-s6Pk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
          <p><a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
             (click)="showEnquiry('IOT based Solutions')">Enquiry Now</a></p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <p><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/icon9.png" alt="market" /></a></p>
          <h5>Agriculture & IT integration </h5>
          <p class="grey show-less-div-1">HireMyFarmers integrates IT services into Agriculture ecosystem in order to ensure that gaps in ecosystem gets bridged with suffice IT systems and processes.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/-Ut_eRJ2lWQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
          <p><a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
            (click)="showEnquiry('Agriculture & IT integration')">Enquiry Now</a></p>
        </div>
      </div>
      <div class="row pb-50 text-center">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <p><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/icon10.png" alt="Farmer stock exchange" /></a></p>
          <h5>Aggregation of pre and post-harvest services through IT</h5>
          <p class="grey show-less-div-1">HireMyFarmer aggregates for both pre and post-harvest services through IT such as in pre-harvest, the bio pests and fertilizers companies, seed companies, rental machineries will be facilitated to support harvesting .The post-harvest aggregation such as cold storages, manufacturing units, warehouses, logistics , packaging, agriculture marketing.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/zG-L6FrJroE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
          <p><a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
            (click)="showEnquiry('Aggregation of pre and post-harvest services through IT')">Enquiry Now</a></p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <p><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/icon11.png" alt="market" /></a></p>
          <h5>Big data for agriculture services</h5>
          <p class="grey show-less-div-1">HireMyFarmer works on the data sets which covers the prediction satellite imagery production, indication and farmer centric advisory services along with crop detection, pest detection related services with the concrete database which helps during disasters. This will be utilised by research, Government, bank, insurance sectors.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/1DTlxYrihg4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
          <p><a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
            (click)="showEnquiry('Big data for agriculture services')">Enquiry Now</a></p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <p><a href="#" data-toggle="modal" data-target="#enquiry"><img src="assets/images/icon12.png" alt="Farmer community centre" /></a></p>
          <h5>Omni channel integration</h5>
          <p class="grey show-less-div-1">HireMyFarmer orchestrates the farmers data directly to retailer/wholesalers/end consumers which helps these entities to connects to farmers base without any middlemen intervention.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/so1nfBtsPOs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
          <p><a href="#" data-toggle="modal" data-target="#enquiry" class="btn btn-success btn-sm"
            (click)="showEnquiry('Omni channel integration')">Enquiry Now</a></p>
        </div>
      </div>
    </div>
  </section>

<div class="modal trans-05" tabindex="-1" role="dialog" id="enquiry">
                <div class="modal-dialog" role="document">
                      <form id="frmContact" novalidate="novalidate" #myform = "ngForm" >
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">{{selectedEnquiry}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetButton()"> 
                              <span aria-hidden="true">&times;</span> </button>
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                  <input type="text" id="name" [(ngModel)]="enquiryModel.name" name="name" 
                                  placeholder="Full Name" title="Name" class="form-control" aria-required="true" required>
                                </div>
                              </div>
                              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                  <input type="text" id="designation" [(ngModel)]="enquiryModel.designation" 
                                  name="designation" placeholder="Designation" title="Designation" 
                                  class="form-control" aria-required="true" required>
                                </div>
                              </div>
                              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                  <input type="text" id="phone" [(ngModel)]="enquiryModel.phone" name="phone" 
                                  [pattern]="mobNumberPattern" #mobNumber="ngModel"
                                  placeholder="Phone Number" title="Phone" class="form-control phone" aria-required="true" 
                                  (focusout)="onFocusOutEvent($event)" 
                                  required>
                                </div>
                                <div class="form-group" *ngIf="mobNumber.errors" >  
                                  <div *ngIf="mobNumber.errors.pattern">  
                                    Mobile number not valid.  
                                  </div>   
                                </div>
                                <div class="form-group" *ngIf="!otpHide">
                                  <input type="text" id="otp" [(ngModel)]="otp" name="otp" 
                                  placeholder="Enter OTP" title="OTP" class="form-control phone" aria-required="true" required>
                                </div> 
                              </div>
                              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                  <input ngModel type="text" id="email" [(ngModel)]="enquiryModel.emailId" 
                                  name="emailId" placeholder="Email ID" title="Email" class="form-control email" 
                                  aria-required="true" required  [pattern]="emailPattern" #emailPtrn="ngModel">
                                </div>
                                <div class="form-group" *ngIf="emailPtrn.errors" >  
                                  <div *ngIf="emailPtrn.errors.pattern">  
                                    Invalid email is entered.  
                                  </div>   
                                </div>
                                <div class="form-group" *ngIf="!otpHide">
                                  <button class="btn btn-primary" (click)="verifyOtp()" >Veriy OTP</button>
                                </div>
                              </div>
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                  <textarea  id="comment-content" [(ngModel)]="enquiryModel.message" name="message" 
                                  rows="3" placeholder="Message" class="form-control"></textarea>
                                </div>
                              </div>
                            </div>  
                          </div>
                          <span >{{response.message}}</span>
                          <div class="modal-footer" >
                            
                            <button type="submit" class="btn btn-primary" 
                              value="Submit" [disabled]="clicked" (click)="saveEnquiry(myform.value)">Submit</button>
                          </div>
                        </div>
                      </form>
                    </div>
                </div>
                