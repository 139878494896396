<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h2>Systematic Investment Plan</h2>
          <ol class="breadcrumb greylinks color4">
            <li> <a href="index.html"> Home </a> </li>
            <li> <a href="services.html"> Services </a> </li>
            <li class="active">Systematic Investment Plan</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="ls section_padding_top_50 section_padding_bottom_50">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-20"><img src="assets/images/SIP.jpg" alt="Systematic Investment Plan" class="img-responsive"/></div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <ul class="timeline grey">
            <li>HireMyFarmer brings an opportunity to public to invest on crops to save farmers and encourage farming through government bodies.</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="ls ms section_padding_top_50 section_padding_bottom_50">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-12 pb-20"> 
          <h4 class="text-center">Enquiry Now</h4>
          <p class="grey text-center">If you have any queries please feel free to contact us.</p>
        </div>
        <form id="frmContact" novalidate="novalidate" #myform = "ngForm">
          <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div class="form-group">
              <input type="text" id="name" [(ngModel)]="enquiryModel.name" name="name" 
              placeholder="Full Name" title="Name" class="form-control" aria-required="true" required>
            </div> 
          </div>
          <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div class="form-group">
              <input type="text" id="designation" [(ngModel)]="enquiryModel.designation" 
              name="designation" placeholder="Designation" title="Designation" 
              class="form-control" aria-required="true" required>
            </div>  
          </div>
          <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div class="form-group">
              <input type="text" id="phone" [(ngModel)]="enquiryModel.phone" name="phone" 
              [pattern]="mobNumberPattern" #mobNumber="ngModel"
              placeholder="Phone Number" title="Phone" class="form-control phone" aria-required="true" 
              (focusout)="onFocusOutEvent($event)" 
              required>
            </div>
            <div class="form-group" *ngIf="mobNumber.errors" >  
              <div *ngIf="mobNumber.errors.pattern">  
                Mobile number not valid.  
              </div>   
            </div>
            <div  *ngIf="!otpHide">
                 <div class="form-group" *ngIf="!otpHide">
              <input type="text" id="otp" [(ngModel)]="otp" name="otp" 
              placeholder="Enter OTP" title="OTP" class="form-control phone" aria-required="true" required>
            </div>
            <div class="form-group" *ngIf="!otpHide">
              <button class="btn btn-primary" (click)="verifyOtp()" >Veriy OTP</button>
            </div> 
            </div>
         
          </div>
          <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div class="form-group">
              <input ngModel type="text" id="email" [(ngModel)]="enquiryModel.emailId" 
              name="emailId" placeholder="Email ID" title="Email" class="form-control email" 
              aria-required="true" required  [pattern]="emailPattern" #emailPtrn="ngModel">
            </div>
            <div class="form-group" *ngIf="emailPtrn.errors" >  
              <div *ngIf="emailPtrn.errors.pattern">  
                Invalid email is entered.  
              </div>   
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="form-group">
              <textarea  id="comment-content" [(ngModel)]="enquiryModel.message" name="message" 
              rows="3" placeholder="Message" class="form-control"></textarea>
            </div>
          </div>  
          <span >{{response.message}}</span>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="form-group" >
              <button type="submit" class="btn btn-primary" 
            value="Submit" [disabled]="clicked" (click)="saveEnquiry(myform.value)">Submit</button>
            </div>
          </div> 
        </form>
      </div>
    </div>
  </section>