<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2>Journey</h2>
        <ol class="breadcrumb greylinks color4">
          <li> <a href="index.html"> Home </a> </li>
          <li> <a href="gallery.html"> Gallery </a> </li>
          <li class="active">Journey</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50">
  <div class="container">
    <div class="row">
    <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-16.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-16.jpg" data-lightbox="lightbox" data-title="This has came into our Dream,Next day we met a farmer and saw him in corporate look
To make him professional it didnt' took time but to make his profession yes it is diffcult that is what we are trying to achieve!!!
"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-15.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-15.jpg" data-lightbox="lightbox" data-title="we gathered group of friends and supported me in this Mission"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
       <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-21.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-21.jpg" data-lightbox="lightbox" data-title="Met our Nature Doctor  so called Farmers in every part of Southern INDIA to understand their daily life, Few Farmers has gave a good time but Few Farmers in other regions are quite busy in Farming
"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
       <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-24.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-24.jpg" data-lightbox="lightbox" data-title="It was 10,000kms drive to understand the Agriculture ecosystem"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
       <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-13.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-13.jpg" data-lightbox="lightbox" data-title="Gathered Farmers and validated our idea with their support"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
        <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-19.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-19.jpg" data-lightbox="lightbox" data-title="From Dawn till Dusk Friends supported to take this idea to next level"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-17.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-17.jpg" data-lightbox="lightbox" data-title="Understood the dependencies of Rental Machinery for small hold farmers"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-25.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-25.jpg" data-lightbox="lightbox" data-title="we started meeting Leaders in rural places of those farmers clusters"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
       <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-27.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-27.jpg" data-lightbox="lightbox" data-title="Connection was established with Agriculture to our Team"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-35.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-35.jpg" data-lightbox="lightbox" data-title="Techiques brainy work was started to create wireframes for the solution"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-37.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-37.jpg" data-lightbox="lightbox" data-title="No stopping No waiting ,Enterprenerua struggles started!!!"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
       <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-28.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-28.jpg" data-lightbox="lightbox" data-title="HireMyFarmer 
                  represented in Billion Dollar challange, Bangalore"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-36.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-36.jpg" data-lightbox="lightbox" data-title="Met Global startups & Enterpreneurs through out the globe"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-43.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-43.jpg" data-lightbox="lightbox" 
                  data-title="Post-Harvest aggregation is the major hurdle so, planned for a production unit in Hyderabad"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-44.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-44.jpg" data-lightbox="lightbox" data-title="OEM's from Maharashtra supported with best affordable Processing Machineries, which made us to understand the exact refining cost,process involved,SCM, (Pilot was successful)"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-38.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-38.jpg" data-lightbox="lightbox" data-title="Started pitching our model to Customers & Investors to balance core operations"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      
      <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-12.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-12.jpg" data-lightbox="lightbox" data-title="Finally,our voice has reached to Investor,Venture Captial firm has invited &ldquo;HireMyFarmer&rdquo; Team to HongKong for Due-Dillegence & Assesment "><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-22.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-22.jpg" data-lightbox="lightbox" data-title="Representing HireMyFarmer  along with technology leader Kiran in HongKong"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
          <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-23.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-23.jpg" data-lightbox="lightbox" 
                  data-title="Thanks Dr. Dalal for helping HireMyFarmer with your expertise in leveraging the Quality standards(HACCP) in 
                  Food Production through our technology solution to keep track of healthy farm to fork"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>      
      <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-26.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-26.jpg" data-lightbox="lightbox" 
                  data-title="Thankyou Estephania, for helping &ldquo;HireMyFarmer&rdquo; in sharing your knowledge 
                  to understand company secratary laws & best practices to be followed in fund raising, 
                  which helped us in defining Milestones & budgeting 
                  
                  "><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

     
      <div class="col-md-3 col-sm-6">
        <div class="project-col">
          <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-18.jpg" alt="HireMyFarmer" />
            <div class="hover-box-content">
              <ul class="icon">
                <li><a class="gallery" href="assets/images/gallery/gallery-18.jpg" data-lightbox="lightbox" 
                  data-title="Thanks Edwin lee ,Viola & Brinc team for supporting us from Brinc to make sure our 
                  representation at right time"><i class="fa fa-search"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>