<div *ngIf="isUserLoggedIn == false">
  <header class="page_header header_white toggler_right">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 display_table">
            <div class="header_left_logo display_table_cell">
              <a href="/"> <img src="assets/images/hmf-logo.png" alt="Hire My Farmer" width="95" />
                <span class="logo_text"> Hire My Farmer </span></a> </div>
            <div class="header_mainmenu display_table_cell text-right">
              <nav class="mainmenu_wrapper">
                <ul class="mainmenu nav sf-menu">
                  <li><a href="/">About Us </a>
                    <ul>
                      <!-- <li><a routerLink="/investors-component" >Investorswww</a></li> -->
                      <li><a href="investors">Investors</a></li>
                      <li><a href="global-partners">Global Partners</a></li>
                      <li><a href="board-members-advisors">Advisory Board Members</a></li>
                      <li><a href="executive-team">Executive Team</a></li>
                    </ul>
                  </li>
                  <li><a href="solutions">Solutions</a></li>
                  <li><a href="services">Services</a></li>
                  <li><a href="javascript:void(0);">Resources </a>
                    <ul>
                      <li><a href="https://blog.hiremyfarmer.com" target="_new">Blog</a></li>
                      <li><a href="news-letter">Newsletter</a></li>
                      <li><a href="case-study">Case Study</a></li>
                      <li><a href="testimonials;">Testimonials</a></li>
                      <li><a href="gallery.html">Gallery</a></li>
                    </ul>
                  </li>
                  <li><a href="careers">Careers</a></li>
                  <li><a href="connect">Connect</a></li>
                  <!-- <li><a href="connect.html">Connect</a></li> -->
                  <!-- <li >
                      <a href="login" class="btn btn-success" >Login</a></li>  -->
                   <li > 
                    <a href="login" class="btn btn-success" >Login</a></li>                                   
                </ul>
              </nav>
              <span class="toggle_menu"><span></span></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
  
<div *ngIf="isUserLoggedIn == true">
<header class="page_header header_white toggler_right">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 display_table">
          <div class="header_left_logo display_table_cell">
            <a href="/"> <img src="assets/images/hmf-logo.png" alt="Hire My Farmer" width="95" />
              <span class="logo_text"> Hire My Farmer </span></a> </div>
          <div class="header_mainmenu display_table_cell text-right">
            <nav class="mainmenu_wrapper">
              <ul class="mainmenu nav sf-menu">
                <li><a href="/">About Us </a>
                  <ul>
                    <!-- <li><a routerLink="/investors-component" >Investorswww</a></li> -->
                    <li><a href="investors">Investors</a></li>
                    <li><a href="global-partners">Global Partners</a></li>
                    <li><a href="board-members-advisors">Advisory Board Members</a></li>
                    <li><a href="executive-team">Executive Team</a></li>
                  </ul>
                </li>
                <li><a href="solutions">Solutions</a></li>
                <li><a href="services">Services</a></li>
                <li><a href="javascript:void(0);">Resources </a>
                  <ul>
                    <li><a href="https://blog.hiremyfarmer.com" target="_new">Blog</a></li>
                    <li><a href="news-letter">Newsletter</a></li>
                    <li><a href="case-study">Case Study</a></li>
                    <li><a href="testimonials;">Testimonials</a></li>
                    <li><a href="gallery.html">Gallery</a></li>
                  </ul>
                </li>
                <li><a href="careers">Careers</a></li>
                <li><a href="connect">Connect</a></li>
                <!-- <li><a href="connect.html">Connect</a></li> -->
                <!-- <li >
                    <a href="login" class="btn btn-success" >Login</a></li>  -->
                    <li>
                      <a href="secure" class="btn btn-success" >Dashboard</a></li> 
                 <li>
                    <a href="#" class="btn btn-success" (click)="logout()">Logout</a></li>                
              </ul>
            </nav>
            <span class="toggle_menu"><span></span></span>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>
