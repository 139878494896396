import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { AwardsRecognitionComponent } from './awards-recognition/awards-recognition.component';
import { BanksComponent } from './banks/banks.component';
import { BoardMembersAdvisorsComponent } from './board-members-advisors/board-members-advisors.component';
import { BusinessComponent } from './business/business.component';
import { CareersComponent } from './careers/careers.component';
import { CaseStudyComponent } from './case-study/case-study.component';
import { ConnectComponent } from './connect/connect.component';
import { ConnectsComponent } from './connects/connects.component';
import { CorporateComponent } from './corporate/corporate.component';
import { ExecutiveTeamComponent } from './executive-team/executive-team.component';
import { FarmerCommunityCenterComponent } from './farmer-community-center/farmer-community-center.component';
import { FarmerExperienceStoreComponent } from './farmer-experience-store/farmer-experience-store.component';
import { FarmerMarketSpaceComponent } from './farmer-market-space/farmer-market-space.component';
import { FarmerStockExchangeComponent } from './farmer-stock-exchange/farmer-stock-exchange.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FreeVirtualCardsComponent } from './free-virtual-cards/free-virtual-cards.component';
import { GalleryComponent } from './gallery/gallery.component';
import { GlobalPartnersComponent } from './global-partners/global-partners.component';
import { GovernmentComponent } from './government/government.component';
import { HomeComponent } from './home/home.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { InvestorsComponent } from './investors/investors.component';
import { JourneyComponent } from './journey/journey.component';
import { LeadersMeetComponent } from './leaders-meet/leaders-meet.component';
import { LoginComponent } from './login/login.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RegisterComponent } from './register/register.component';
import { SecureComponent } from './secure/secure.component';
import { ServicesComponent } from './services/services.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { SystematicInvestmentPlanComponent } from './systematic-investment-plan/systematic-investment-plan.component';
import { TermsComponent } from './terms/terms.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { ThirdPartySystemsComponent } from './third-party-systems/third-party-systems.component';

const routes: Routes = [
  { path: 'dashboard', redirectTo: 'secure', pathMatch: 'full' },
  { path: 'secure', canActivate: [ AuthGuard ], component: SecureComponent },
  
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  // { path: 'logout', component: LoginComponent },
  //{ path: '404', component: NotFoundComponent },
  //{ path: '**', redirectTo: '404' }
  
  { path: 'home', component: HomeComponent },
  { path: 'investors', component: InvestorsComponent },
  { path: 'solutions', component: SolutionsComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'global-partners', component: GlobalPartnersComponent },
  { path: 'board-members-advisors', component: BoardMembersAdvisorsComponent },
  { path: 'executive-team', component: ExecutiveTeamComponent },
  { path: 'government', component: GovernmentComponent },
  { path: 'corporate', component: CorporateComponent },
  { path: 'banking', component: BanksComponent },
  { path: 'insurance', component: InsuranceComponent },
  { path: 'third-party-systems', component: ThirdPartySystemsComponent },
  { path: 'business', component: BusinessComponent },
  { path: 'business', component: BusinessComponent },
  { path: 'testimonials', component: TestimonialsComponent },
  { path: 'farmer-community-center', component: FarmerCommunityCenterComponent },
  { path: 'farmer-experience-store', component: FarmerExperienceStoreComponent },
  { path: 'farmer-market-space', component: FarmerMarketSpaceComponent },
  { path: 'farmer-stock-exchange', component: FarmerStockExchangeComponent },
  { path: 'free-virtual-cards', component: FreeVirtualCardsComponent },
  { path: 'systematic-investment-plan', component: SystematicInvestmentPlanComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'awards-recognition', component: AwardsRecognitionComponent },
  { path: 'connect', component: ConnectComponent },
  { path: 'connects', component: ConnectsComponent },
  { path: 'journey', component: JourneyComponent },
  { path: 'leaders-meet', component: LeadersMeetComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'news-letter', component: NewsletterComponent },
  { path: 'case-study', component: CaseStudyComponent },
  { path: 'terms', component: TermsComponent },

  {path: 'investors.html', redirectTo: 'investors', pathMatch: 'full'},
  {path: 'solutions.html', redirectTo: 'solutions', pathMatch: 'full'},
  {path: 'services.html', redirectTo: 'services', pathMatch: 'full'},
  {path: 'global-partners.html', redirectTo: 'global-partners', pathMatch: 'full'},
  {path: 'board-members-advisors.html', redirectTo: 'board-members-advisors', pathMatch: 'full'},
  {path: 'government.html', redirectTo: 'government', pathMatch: 'full'},
  {path: 'corporate.html', redirectTo: 'corporate', pathMatch: 'full'},
  {path: 'banking.html', redirectTo: 'banking', pathMatch: 'full'},
  {path: 'insurance.html', redirectTo: 'insurance', pathMatch: 'full'},
  {path: 'third-party-systems.html', redirectTo: 'third-party-systems', pathMatch: 'full'},
  {path: 'business.html', redirectTo: 'business', pathMatch: 'full' },
  {path: 'testimonials.html', redirectTo: 'testimonials', pathMatch: 'full' },
  {path: 'farmer-community-center.html', redirectTo: 'farmer-community-center', pathMatch: 'full' },
  { path: 'farmer-experience-store.html', redirectTo: 'farmer-experience-store', pathMatch: 'full' },
  { path: 'farmer-market-space.html', redirectTo: 'farmer-market-space', pathMatch: 'full' },
  { path: 'farmer-stock-exchange.html', redirectTo: 'farmer-stock-exchange', pathMatch: 'full' },
  { path: 'free-virtual-cards.html', redirectTo: 'free-virtual-cards', pathMatch: 'full' },
  { path: 'systematic-investment-plan.html', redirectTo: 'systematic-investment-plan', pathMatch: 'full' },
  { path: 'gallery.html', redirectTo: 'gallery', pathMatch: 'full' },
  { path: 'awards-recognition.html', redirectTo: 'awards-recognition', pathMatch: 'full' },
  { path: 'connect.html', redirectTo: 'connect', pathMatch: 'full' },
  { path: 'connects.html', redirectTo: 'connects', pathMatch: 'full' },
  { path: 'journey.html', redirectTo: 'journey', pathMatch: 'full' },
  { path: 'leaders-meet.html', redirectTo: 'leaders-meet', pathMatch: 'full' },
  { path: 'careers.html', redirectTo: 'careers', pathMatch: 'full' },
  { path: 'news-letter.html', redirectTo: 'news-letter', pathMatch: 'full' },
  { path: 'case-study.html', redirectTo: 'case-study', pathMatch: 'full' },
  { path: 'terms.html', redirectTo: 'terms', pathMatch: 'full' },
  { path: 'index.html', redirectTo: 'home', pathMatch: 'full' },
  { path: 'secure.html', redirectTo: 'secure', pathMatch: 'full' },

  {path: '', component: HomeComponent},
  {path: '**', redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
