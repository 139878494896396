<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h2>Connect</h2>
                <ol class="breadcrumb greylinks color4">
                    <li> <a href="index.html"> Home </a> </li>
                    <li class="active">Connect</li>
                </ol>
            </div>
        </div>
    </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50">
    <div class="container">
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <h5> <img src="assets/images/hong-kong.png" alt="HONG KONG" /> HONG KONG</h5>
                <p class="grey">Room 1405, 135 Bonham Strand Trade Centre, 135 Bonham Strand, Sheung WAN, HONG KONG</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <h5> <img src="assets/images/united-states.png" alt="USA" /> USA</h5>
                <p class="grey">16192 Coastal Highway Lewes, Delaware 19958, County of Sussex, USA</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <h5> <img src="assets/images/india.png" alt="INDIA" /> INDIA</h5>
                <p class="grey">H.No 235, Chaman bavi, Hayath Nagar Village, Amangal, Hyderabad, Telangana - 501505</p>
            </div>
        </div>
    </div>
</section>
<section class="ls ms section_padding_top_50 section_padding_bottom_50">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-12 pb-20">
                <h4 class="text-center">Get In Touch</h4>
                <p class="grey text-center">Feel free to write us a message.</p>
            </div>
            <form id="frmContact" method="POST" novalidate="novalidate">
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <input type="text" id="name" name="name" placeholder="Name" title="Name" class="form-control"
                            aria-required="true" required>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <input type="text" id="subject" name="subject" placeholder="Subject" title="Subject"
                            class="form-control" aria-required="true" required>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <input type="text" id="phone" name="phone" placeholder="Phone Number" title="Phone"
                            class="form-control phone" aria-required="true" required>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <input type="text" id="email" name="email" placeholder="Email ID" title="Email"
                            class="form-control email" aria-required="true" required>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <textarea id="comment-content" name="content" rows="3" placeholder="Message"
                            class="form-control"></textarea>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <button type="button" class="btn btn-primary">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>