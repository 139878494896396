import { Component, OnInit } from '@angular/core';
import { EnquiryService, Otp, SimpleResponse } from '../enquiries/enquiry.service';
import { UserRegistrationService } from '../user-registration.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private userRegistrationService: UserRegistrationService,
    private enquiryService: EnquiryService) { }
  response: SimpleResponse;
  registrationModel: RegistrationDetails
  clicked: boolean;
  otp: string;
  otpHide: boolean;
  submitHide: boolean;
  goSubmit: boolean;
  nameOk: boolean;
  passwordOk: boolean;
  emailOk: boolean;
  categories: any[];
  showGovernment: boolean;
  showBusiness: boolean;
  showCorporate: boolean;
  showBanking: boolean;
  showInsurance: boolean;
  showThirdPartySystems: boolean;
  selectedCategory: String;
  pDialCode: String;
  pCountryName: String;
  piso2: String;

  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  category: String;
  ngOnInit(): void {
    this.registrationModel = new RegistrationDetails();
    this.response = new SimpleResponse();
    this.otp = "";
    this.otpHide = true;
    this.submitHide = true;
    this.nameOk = false;
    this.passwordOk = false;
    this.emailOk = false;
    this.registrationModel.tncAgree = 'yes';
    this.showGovernment = true;
    this.showBusiness = false;
    this.showCorporate = false;
    this.showBanking = false;
    this.showInsurance = false;
    this.showThirdPartySystems = false; 

    this.categories = [
      { id: 1, name: "Government" },
      { id: 2, name: "Corporate" },
      { id: 3, name: "Banking" },
      { id: 4, name: "Insurance" },
      { id: 5, name: "Third Party Systems" },
      { id: 5, name: "Business" }
    ]
    this.selectedCategory = this.categories[0];
    this.pDialCode ='91';
    this.pCountryName='India';
    this.piso2='in';



  }
  register(registrationData: RegistrationDetails) {
    //this.goSubmit = true;
    console.log('Your form data : ', registrationData);

    if (this.registrationModel.name == null || this.registrationModel.name === "") {
      this.response.message = 'Name is required..';
      //this.goSubmit = false;
    } else {
      this.nameOk = true;
    }
    if (this.registrationModel.emailId == null || this.registrationModel.emailId === "") {
      this.response.message = 'Email is required..';
      //this.goSubmit = false;
    } else {
      this.emailOk = true;
    }
    if (this.registrationModel.password == null || this.registrationModel.password === "") {
      this.response.message = 'Password is required..';
      //this.goSubmit = false;
    }
    if (this.registrationModel.confirmPassword == null || this.registrationModel.confirmPassword === "") {
      this.response.message = 'Confirm Password  is required..';
      //this.goSubmit = false;
    }

    if (this.registrationModel.password === this.registrationModel.confirmPassword) {

      this.passwordOk = true;
    } else {
      this.response.message = 'Password and confirm passwords are not matching ';
      //this.goSubmit = false;
    }


    if (this.nameOk && this.emailOk && this.passwordOk) {
      if (this.registrationModel.tncAgree === 'yes') {
        registrationData.phoneNumber = this.pDialCode+''+registrationData.phoneNumber;
        console.log('phone number'+registrationData.phoneNumber);
        this.userRegistrationService.saveUser(registrationData).subscribe(data => {
          this.response = data;
          console.log(this.response);
          this.clicked = true;
          console.log(this.clicked);
        })
      }
    }
  }

  onPhoneFocusOutEvent(event: any) {
    console.log(event.target.value);
    console.log("mobile number is not valid");
    console.log('dilcode: ',this.pDialCode)
    if (this.registrationModel.phoneNumber != null && this.registrationModel.phoneNumber.length == 10) {
      console.log("mobile number is valid");
      if(this.pDialCode=='91' || this.pDialCode=='+91'){
        this.enquiryService.isPhoneNumberExists(this.registrationModel.phoneNumber).subscribe(data => {
          this.response = data;
          console.log(this.response);
          if (this.response != null 
            && (this.response.messageType === "info" || this.response.messageType === "success")) {
            this.otpHide = false;
          } else if (this.response != null && this.response.messageType === "error") {
            this.otpHide = true;
          } })
      }else {
        this.enquiryService.isPhoneNumberExistsV2(this.registrationModel.phoneNumber,this.pDialCode).subscribe(data => {
          this.response = data;
          console.log(this.response);
          if (this.response != null 
            && ( this.response.messageType === "info" || this.response.messageType === "success")) {
            this.otpHide = true;
          } else if (this.response != null && this.response.messageType === "error") {
            this.otpHide = true;
          } })
      }
      

     
      // disable before uploading
      this.otpHide = false;
    }
  }
  onPhoneFocusOutEventV2(event: any) {
    console.log(event.target.value);
    console.log("mobile number is not valid");
    console.log('dilcode: ',this.pDialCode)
    if (this.registrationModel.phoneNumber != null && this.registrationModel.phoneNumber.length == 10) {
      console.log("mobile number is valid");
      this.enquiryService.isPhoneNumberExists(this.registrationModel.phoneNumber).subscribe(data => {
        this.response = data;
        console.log(this.response);
        if (this.response != null && this.response.messageType === "info") {
          this.otpHide = false;
        } else if (this.response != null && this.response.messageType === "error") {
          this.otpHide = true;
        }else if( this.pDialCode != '+91'){

        }
      })
      // disable before uploading
      this.otpHide = false;
    }
  }
  verifyOtp() {
    console.log(this.otp);
    if (this.otp != null && this.otp.length == 4) {
      var phoneOtp = new Otp();
      phoneOtp.phoneNumber = this.registrationModel.phoneNumber;
      phoneOtp.otp = this.otp;
      this.enquiryService.verifyOtp(phoneOtp).subscribe(data => {
        this.response = data;
        console.log(this.response);
        if (this.response != null && this.response.messageType === "success") {
          this.otpHide = true;
          this.submitHide = false;
        }
      })
      // comment this one before deploy
      // this.submitHide = false;
      // this.otpHide = true;
      // this.response.message = 'OTP Verified Successfully';
    } else {
      this.response.message = 'Invalid OTP';
    }
  }
  onEmailFocusOutEvent(event: any) {
    console.log('new phone number: ', this.registrationModel.phoneNumber);
    console.log(event.target.value);
    console.log(" this.emailOk  " + this.emailOk)
    if (this.registrationModel.emailId != null && this.registrationModel.emailId.length != null ) {
      console.log("email id is valid");
      this.enquiryService.isEmailExists(this.registrationModel.emailId).subscribe(data => {
        this.response = data;
        console.log(this.response);
        if (this.response != null && this.response.messageType === "info") {
          this.emailOk = true;
        } else if (this.response != null && this.response.messageType === "error") {
          this.emailOk = false;
        }
      })

    }
  }

  onCountryChange(event){
      console.log('country is changed');      
      this.pDialCode =  event.dialCode;
      this.pCountryName = event.name;
      this.piso2 = event.iso2;
      console.log(this.pDialCode);
      console.log(this.pCountryName);
      console.log(this.piso2); 
  }



  showOptionvalues(event: any) {
    console.log(event);
    this.showCategories(event.name);
  }

  showCategories(selectedCategory: String) {
    this.showGovernment = false;
    this.showBusiness = false;
    this.showCorporate = false;
    this.showBanking = false;
    this.showInsurance = false;
    this.showThirdPartySystems = false;
    console.log(selectedCategory)
    if (selectedCategory.includes('Government')) {
      console.log('Government is matched')
      this.showGovernment = true;
    } else if (selectedCategory.includes("Corporate")) {
      this.showCorporate = true;
    } else if (selectedCategory.includes("Banking")) {
      this.showBanking = true;
    } else if (selectedCategory.includes("Insurance")) {
      this.showInsurance = true;
    } else if (selectedCategory.includes("Third Party Systems")) {
      this.showThirdPartySystems = true;
    } else if (selectedCategory.includes("Business")) {
      this.showBusiness = true;
    }else{
      console.log('No matching')
    }
  }
}
export class CategoryType{
  name: string;
  id: number;
}
export class RegistrationDetails {
  name: String;
  emailId: String;
  phoneNumber: String;
  iPhoneNumber: String;
  category: String;
  categoryItems: String;
  password: String;
  confirmPassword: String;
  tncAgree: String;
  consent: String;

}
