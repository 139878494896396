import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { EnquiryService } from './enquiries/enquiry.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {
  title = 'hiremyfarmer.com';
  emailId: String;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"; 

  private readonly notifier: NotifierService;
  constructor(notifierService: NotifierService, private enquiryService: EnquiryService){
    this.notifier = notifierService;
  }

  ngOnInit() { 
    this.emailId = '';
  }

  notifyUser(){
    console.log('emailId: ',this.emailId)
    if(this.emailId!=null && this.emailId.length > 0){
      this.notifier.notify("success", "Thank you for Subscribing!");
      this.enquiryService.subscribeUser(this.emailId).subscribe(data => {
        
        console.log(data);});
    }else{
      this.notifier.notify("error", "Invalid Email Id!");
    }
    
  }

  
}
  