import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-executive-team',
  templateUrl: './executive-team.component.html',
  styleUrls: ['./executive-team.component.css']
})
export class ExecutiveTeamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
