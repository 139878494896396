<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h2>Connects</h2>
                <ol class="breadcrumb greylinks color4">
                    <li> <a href="index.html"> Home </a> </li>
                    <li> <a href="gallery.html"> Gallery </a> </li>
                    <li class="active">Connects</li>
                </ol>
            </div>
        </div>
    </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6">
                <div class="project-col">
                    <div class="hover-box">
                        <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-5.jpg"
                            alt="HireMyFarmer" />
                        <div class="hover-box-content">
                            <ul class="icon">
                                <li><a class="gallery" href="assets/images/gallery/gallery-5.JPG"
                                        data-lightbox="lightbox" data-title="Birth of &ldquo;HireMyFarmer&rdquo; as a word marks finally registered"><i
                                            class="fa fa-search"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="project-col">
                    <div class="hover-box"> <img class="fixed-hight280 fixed-hight210"
                            src="assets/images/gallery/thumb-6.jpg" alt="HireMyFarmer" />
                        <div class="hover-box-content">
                            <ul class="icon">
                                <li><a class="gallery" href="assets/images/gallery/gallery-31.jpg"
                                        data-lightbox="lightbox" data-title="One more Impact Investors community has recognized us and this time &ldquo;HireMyFarmer&rdquo; is a part of US Team, Representing during our pandemic times, 
                                        Thankyou FFC Team for supporting us virtually"><i
                                            class="fa fa-search"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="project-col">
                    <div class="hover-box"> <img class="fixed-hight280 fixed-hight210"
                            src="assets/images/gallery/thumb-4.jpeg" alt="HireMyFarmer" />
                        <div class="hover-box-content">
                            <ul class="icon">
                                <li><a class="gallery" href="assets/images/gallery/gallery-4.jpg"
                                        data-lightbox="lightbox" data-title="National Institute of Agriculture Marketing has recognized &ldquo;HireMyFarmer&rdquo; and also to incubate under Central government support for extension of Agri-business innovation in Agriculutre Marketing services"><i
                                            class="fa fa-search"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-3 col-sm-6">
                <div class="project-col">
                    <div class="hover-box"> <img class="fixed-hight280 fixed-hight210"
                            src="assets/images/gallery/thumb-10.jpg" alt="HireMyFarmer" />
                        <div class="hover-box-content">
                            <ul class="icon">
                                <li><a class="gallery" href="assets/images/gallery/gallery-10.jpg"
                                        data-lightbox="lightbox" data-title=""><i
                                            class="fa fa-search"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-md-3 col-sm-6">
                <div class="project-col">
                    <div class="hover-box"> <img class="fixed-hight280 fixed-hight210"
                            src="assets/images/gallery/thumb-11.jpg" alt="HireMyFarmer" />
                        <div class="hover-box-content">
                            <ul class="icon">
                                <li><a class="gallery" href="assets/images/gallery/gallery-11.jpg"
                                        data-lightbox="lightbox" data-title=""><i
                                            class="fa fa-search"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-md-3 col-sm-6">
                <div class="project-col">
                    <div class="hover-box"> <img class="fixed-hight280 fixed-hight210"
                            src="assets/images/gallery/thumb-39.jpg" alt="HireMyFarmer" />
                        <div class="hover-box-content">
                            <ul class="icon">
                                <li><a class="gallery" href="assets/images/gallery/gallery-39.jpeg"
                                        data-lightbox="lightbox" data-title="Parnam Kisan helped us to understand the Pre-harvest practices and planned for a collaborative venture to cover Northern part of INDIA"><i
                                            class="fa fa-search"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="project-col">
                    <div class="hover-box"> <img class="fixed-hight280 fixed-hight210"
                            src="assets/images/gallery/thumb-32.jpg" alt="HireMyFarmer" />
                        <div class="hover-box-content">
                            <ul class="icon">
                                <li><a class="gallery" href="assets/images/gallery/gallery-32.jpg"
                                        data-lightbox="lightbox" data-title="A click with Kadapa district AP Mayor, boosting us on our project milestones."><i
                                            class="fa fa-search"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="project-col">
                    <div class="hover-box"> <img class="fixed-hight280 fixed-hight210"
                            src="assets/images/gallery/thumb-20.jpg" alt="HireMyFarmer" />
                        <div class="hover-box-content">
                            <ul class="icon">
                                <li><a class="gallery" href="assets/images/gallery/gallery-20.jpeg"
                                        data-lightbox="lightbox" data-title="Met few dynamic personalities & Founders of the companies in the same path,who are working hard to bring sustainable practices,innovation with in Agriculture ecosystem ( Parikshit : ParnamKisaan, Yash: Agro2o, Arunima: Tiny Terrarium)"><i
                                            class="fa fa-search"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</section>