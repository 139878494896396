import { Component, OnInit } from '@angular/core';
import 'ol/ol.css';
import ImageLayer from 'ol/layer/Image';
import Tile from 'ol/layer/Tile';
import Map from 'ol/Map';
import Projection from 'ol/proj/Projection';
import Static from 'ol/source/ImageStatic';
import View from 'ol/View';
import OSM from 'ol/source/OSM';
import Stamen from 'ol/source/Stamen';
import GeoJSON from 'ol/format/GeoJSON';
import Vector from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { getCenter } from 'ol/extent';
import { fromLonLat } from 'ol/proj';
import { transform } from 'ol/proj';
import { transformExtent, toLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon'
import Overlay from 'ol/Overlay';
//import Geometry from 'ol/geom/Geometry';
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill';
import Circle from 'ol/style/Circle';
import Stroke from 'ol/style/Stroke';
import Point from 'ol/geom/Point';
import Icon from 'ol/style/Icon';
import XYZ from 'ol/source/XYZ';
import { FarmLandsService } from '../farm-lands.service';
import { Observable } from 'rxjs';
import { style } from '@angular/animations';
import { $ } from 'protractor';
import { SatDetails, SatServiceService } from '../sat-service.service';
import { TileGridComponent } from 'ngx-openlayers';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.css']
})
export class SecureComponent implements OnInit {
  private readonly notifier: NotifierService;
  constructor(private farmLandService: FarmLandsService, public satServiceService: SatServiceService,
    notifierService: NotifierService) { 
      this.notifier = notifierService;
    }
  farmLandArray: Number[][][];
  farmerId: Number;
  map: Map;
  vectorLayer: Vector;
  tileLayer: Tile;
  ngOnInit(): void {
    // var extent = [0, 0, 1024, 968];
    // var projection = new Projection({
    //   code: 'xkcd-image',
    //   units: 'pixels',
    //   extent: extent,

    // });

    var osm = new Tile({
      source: new OSM()
    });

    this.getFarmLandDetails();
  }

  getFarmLandDetails() {
    var globalRef = this;
    this.farmLandService.getLandDetails().subscribe(
      (data) => {
        this.farmLandArray = data;
        //var features: any[];
        var features = [];
        //console.log(this.farmLandArray.length)
        var style1 = new Style({
          image: new Icon(({
            scale: 0.7,
            rotateWithView: false,
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            opacity: 1,
            src: 'https://raw.githubusercontent.com/jonataswalker/map-utils/master/images/marker.png'
          })),
          zIndex: 5
        });
        for (var landDetailsIndex in this.farmLandArray) {
          var landCoordinates = this.farmLandArray[landDetailsIndex]
          var points = landCoordinates['coordinates'];
          var plotId = landCoordinates['id'];
          var farmerId = landCoordinates['farmerId'];
          for (var i in points[0]) {

            points[0][i] = fromLonLat(points[0][i]);
          }
          var markerOne = new Feature({
            geometry: new Point(points[0][0]),
          });
          markerOne.setStyle(style1);
          var polyone = new Polygon([points[0]]);
          var featureone = new Feature(polyone);
          featureone.setId(landDetailsIndex);
          featureone.set('plotId', plotId)
          featureone.set('farmerId', farmerId)
          featureone.setStyle(new Style({
            fill: new Fill({
              color: 'green'
            })
          }));
          features.push(featureone);
          features.push(markerOne);
        }

        // console.log(features);
        var vectorSource = new VectorSource({
          features: features
        });
        globalRef.vectorLayer = new Vector({
          source: vectorSource
        });

        globalRef.tileLayer = new Tile({
          source: new XYZ({
            url: 'https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=QtztWAucl2vJwXj1WR7Y',
            tileSize: 512,
          })
        });
        var view = new View({
          center: fromLonLat([83.44716858118774, 18.201168844769036]),
          zoom: 5
        });
        var overlayElement = document.getElementById('popup');
        var content = document.getElementById('popup-content');
        var popupcloser = document.getElementById('popup-closer');
        var popupFarmerDetails = document.getElementById('popup-farmer-details');
        var popupFarmerDetailsImg = document.getElementById('popup-farmer-details-img');
        var popupFarmerDetailsSoilType = document.getElementById('popup-farmer-details-soil-type');
        var popupFarmerDetailsLandInfo = document.getElementById('popup-farmer-details-land-info');
        var popupFarmerDetailsCropHistory = document.getElementById('popup-farmer-details-crop-history');
        var popupFarmerDetailsIrrigationType = document.getElementById('popup-farmer-details-irrigation-type');
        var popupFarmerDetailsCropInsurance = document.getElementById('popup-farmer-details-crop-insurance');

        var markerOverlay = new Overlay({
          positioning: 'center-center',
          element: overlayElement
        });
        // markerOverlay.set
        this.map = new Map({
          layers: [globalRef.tileLayer, globalRef.vectorLayer],
          overlays: [markerOverlay],
          target: 'lmap',
          view: view
        });
        console.log(globalRef.vectorLayer.getSource());
        this.map.on('click', function (evt) {
          var feature = globalRef.map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            var coords = feature.getGeometry().getCoordinates()
            var featureType = feature.getGeometry().getType();

            if ("Point" === featureType) {
              globalRef.map.getView().setCenter(fromLonLat(toLonLat(coords)));
              globalRef.map.getView().setZoom(16);
            } else if ("Polygon" === featureType) {
              console.log(feature)
              var polygonPlotId = feature.get('plotId')
              var polygonFarmerId = feature.get('farmerId')
              var overlayPosition = coords[0][0];

              markerOverlay.setPosition(overlayPosition);
              popupcloser.onclick = function () {
                markerOverlay.setPosition(undefined);
                popupcloser.blur();
                return false;
              };
              //content.innerHTML = '<img src=\'https://micro.satyukt.com/tiles/image/NDVI_20201109T051029/1233?key=LLOz6xAeWAIX1vxIgVLjKaHWSlQNASLqH_wRORcxOkY=\' />'

              var satDetails: SatDetails[];

              globalRef.satServiceService.getSatDetails(1233).subscribe(data => {
                satDetails = data;
                //console.log(satDetails)
                var sm = satDetails[0]['png']['sm']
                console.log(sm)
                //content.innerHTML = '<img src=\''+sm+'\' />' 
                content.innerHTML = '<img src=\'https://micro.satyukt.com/tiles/image/SM_20200812T002218/1233?key=LLOz6xAeWAIX1vxIgVLjKaHWSlQNASLqH_wRORcxOkY=\' />'
              });
              globalRef.farmLandService.getFarmerByFarmerId(polygonFarmerId).subscribe(data => {
                var farmerDetails = data;
                console.log(farmerDetails)
                popupFarmerDetailsImg.innerHTML = '<img src=\'' + farmerDetails['farmerPhoto'] + '\' />';
                popupFarmerDetailsSoilType.innerHTML = '<strong>Soil Type: </strong>' + farmerDetails['soilType'];
                popupFarmerDetailsLandInfo.innerHTML = '<strong>Land Info: </strong>' + farmerDetails['landInfo'];
                popupFarmerDetailsCropHistory.innerHTML = '<strong>Crop History: </strong>' + farmerDetails['cropHistory'];
                popupFarmerDetailsIrrigationType.innerHTML = '<strong>Irriigation Type: </strong>' + farmerDetails['irrigationType'];
                popupFarmerDetailsCropInsurance.innerHTML = '<strong>Crop Insurance: </strong>' + farmerDetails['cropInsurance'];

              });
            }
          });
        })
      }
    );
  }
  getSatDetails(plotId: Number) {
    var details = this.satServiceService.getSatDetails(915);
    console.log(details);
  }
  resetForm() {
    this.farmerId = 0;
  }
  getFarmerLandDetailsDetailsById() {
    console.log('getFarmerDetails  ');

    var globalRef = this;
    var layers = globalRef.map.getLayers();
    //console.log(layers);
    if (isNaN(+this.farmerId)) {
      this.notifier.notify("error", "Please enter a valid Farmer Id !");
      console.log('not a number')
    }
    else {
      if (layers) {
        globalRef.map.removeLayer(globalRef.vectorLayer);
        globalRef.map.getView().setZoom(5);

        this.farmLandService.getOnboardedLandDetailsV2ByFarmerId(this.farmerId).subscribe(
          (data) => {
            this.farmLandArray = data;
            //console.log(data);
            var features = [];
            var style1 = new Style({
              image: new Icon(({
                scale: 0.7,
                rotateWithView: false,
                anchor: [0.5, 1],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                opacity: 1,
                src: 'https://raw.githubusercontent.com/jonataswalker/map-utils/master/images/marker.png'
              })),
              zIndex: 5
            });
            for (var landDetailsIndex in this.farmLandArray) {
              var landCoordinates = this.farmLandArray[landDetailsIndex]
              var points = landCoordinates['coordinates'];
              var plotId = landCoordinates['id'];
              var farmerId = landCoordinates['farmerId'];
              for (var i in points[0]) {

                points[0][i] = fromLonLat(points[0][i]);
                //console.log(points[0][i])
              }
              var markerOne = new Feature({
                geometry: new Point(points[0][0]),
              });
              markerOne.setStyle(style1);
              var polyone = new Polygon([points[0]]);
              var featureone = new Feature(polyone);
              featureone.setId(landDetailsIndex);
              featureone.set('plotId', plotId)
              featureone.set('farmerId', farmerId)
              featureone.setStyle(new Style({
                fill: new Fill({
                  color: 'green'
                })
              }));
              features.push(featureone);
              features.push(markerOne);
            }

            // console.log(features);
            var vectorSource = new VectorSource({
              features: features
            });
            globalRef.vectorLayer = new Vector({
              source: vectorSource
            });

            globalRef.tileLayer = new Tile({
              source: new XYZ({
                url: 'https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=QtztWAucl2vJwXj1WR7Y',
                tileSize: 512,
              })
            });
            var view = new View({
              center: fromLonLat(points[0][0]),
              zoom: 15
            });

            //globalRef.vectorLayer.getSource().changed();
            //globalRef.map.updateSize();
            var overlayElement = document.getElementById('popup');
            var content = document.getElementById('popup-content');
            var popupcloser = document.getElementById('popup-closer');
            var popupFarmerDetails = document.getElementById('popup-farmer-details');
            var popupFarmerDetailsImg = document.getElementById('popup-farmer-details-img');
            var popupFarmerDetailsSoilType = document.getElementById('popup-farmer-details-soil-type');
            var popupFarmerDetailsLandInfo = document.getElementById('popup-farmer-details-land-info');
            var popupFarmerDetailsCropHistory = document.getElementById('popup-farmer-details-crop-history');
            var popupFarmerDetailsIrrigationType = document.getElementById('popup-farmer-details-irrigation-type');
            var popupFarmerDetailsCropInsurance = document.getElementById('popup-farmer-details-crop-insurance');

            var markerOverlay = new Overlay({
              positioning: 'center-center',
              element: overlayElement
            });
            console.log(globalRef.vectorLayer)
            // globalRef.map = new Map({
            //   layers: [globalRef.tileLayer],
            //   overlays:[markerOverlay], 
            //   target: 'lmap',
            //   view: view
            // });        
            globalRef.map.addLayer(globalRef.vectorLayer);
            globalRef.map.addOverlay(markerOverlay);
            //globalRef.vectorLayer.getSource().changed();
            globalRef.map.on('click', function (evt) {
              var feature = globalRef.map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
                var coords = feature.getGeometry().getCoordinates()
                var featureType = feature.getGeometry().getType();

                if ("Point" === featureType) {
                  globalRef.map.getView().setCenter(fromLonLat(toLonLat(coords)));
                  globalRef.map.getView().setZoom(16);
                } else if ("Polygon" === featureType) {
                  console.log(feature)
                  var polygonPlotId = feature.get('plotId')
                  var polygonFarmerId = feature.get('farmerId')
                  console.log(coords[0][0])
                  var overlayPosition = [9289296.314598277, 2061108.4935651927];

                  markerOverlay.setPosition(overlayPosition);

                  popupcloser.onclick = function () {
                    markerOverlay.setPosition(undefined);
                    popupcloser.blur();
                    return false;
                  };
                  //content.innerHTML = '<img src=\'https://micro.satyukt.com/tiles/image/NDVI_20201109T051029/1233?key=LLOz6xAeWAIX1vxIgVLjKaHWSlQNASLqH_wRORcxOkY=\' />'

                  var satDetails: SatDetails[];

                  globalRef.satServiceService.getSatDetails(1233).subscribe(data => {
                    satDetails = data;
                    //console.log(satDetails)
                    var sm = satDetails[0]['png']['sm']
                    console.log(sm)
                    //content.innerHTML = '<img src=\''+sm+'\' />' 
                    content.innerHTML = '<img src=\'https://micro.satyukt.com/tiles/image/SM_20200812T002218/1233?key=LLOz6xAeWAIX1vxIgVLjKaHWSlQNASLqH_wRORcxOkY=\' />'
                  });
                  globalRef.farmLandService.getFarmerByFarmerId(polygonFarmerId).subscribe(data => {
                    var farmerDetails = data;
                    console.log('farmer details', farmerDetails)
                    popupFarmerDetailsImg.innerHTML = '<img src=\'' + farmerDetails['farmerPhoto'] + '\' />';
                    popupFarmerDetailsSoilType.innerHTML = '<strong>Soil Type: </strong>' + farmerDetails['soilType'];
                    popupFarmerDetailsLandInfo.innerHTML = '<strong>Land Info: </strong>' + farmerDetails['landInfo'];
                    popupFarmerDetailsCropHistory.innerHTML = '<strong>Crop History: </strong>' + farmerDetails['cropHistory'];
                    popupFarmerDetailsIrrigationType.innerHTML = '<strong>Irriigation Type: </strong>' + farmerDetails['irrigationType'];
                    popupFarmerDetailsCropInsurance.innerHTML = '<strong>Crop Insurance: </strong>' + farmerDetails['cropInsurance'];

                  });
                }
              });
            })
          }
        );
      }
    }
  }
}