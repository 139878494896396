<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2>Services</h2>
        <ol class="breadcrumb greylinks color4">
          <li> <a href="index.html"> Home </a> </li>
          <li class="active">Services</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50">
  <div class="container">
    <div class="row pb-50">
      <div class="col-md-10 col-md-offset-1 col-sm-12 text-center section_padding_bottom_50">
        <h3>Building Technology For The Farmer</h3>
        <p class="grey">This is unique platform provides incubation layer for the agriculture services where crop dependent farmers displayed to FMCG, Retail, Restaurants, Hotels and Travel industry to order and deliver the stock to their locations direct from the farmers with better prices.</p>
      </div>
    </div>
    <div class="row pb-50">
      <div class="col-md-8 col-sm-12 pt-20">
        <h5 class="color-green">Crop Land Monitoring</h5>
        <p class="bold grey">Satellite API's, Images, Diagnostic Reports, crop Season Pattern History.</p>
        <p class="grey">We used an object-oriented approach in conjunction with the Random Forest algorithm to classify agricultural practices, including tillage (till or no-till (NT)), crop intensity, and grassland-based conservation reserve (CR). The object-oriented approach allowed for per-field classifications and the incorporation of contextual elements in addition to spectral features. Random Forest is a classification tree-based advanced classifier that avoids data over-fitting associated with many tree-based models and incorporates an unbiased internal classification accuracy assessment. Landsat satellite imagery was chosen for its continuous coverage, cost effectiveness, and image accessibility. </p>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-services" class="btn btn-success btn-sm"
           (click)="showEnquiry('Crop Land Monitoring')">Enquiry Now</a></p>
      </div>
      <div class="col-md-4 col-sm-12 pt-20"> <img src="assets/images/CropLandMonitoring.jpg" alt="Onboarding Farmer" class="img-responsive" /> </div>
    </div>
    <div class="row">
    <h5 class="pb-20 text-center">Pre-Harvest Digitization</h5>
        <div class="services-progress text-center">
            <i class="flaticon-agricultural-1"></i>
            <h4>Onboarding Farmer</h4>
        </div>
        <div class="services-progress text-center">
            <i class="flaticon-farm"></i>
            <h4>Onboarding Farming Lands</h4>
        </div>
        <div class="services-progress text-center">
            <i class="flaticon-satellite"></i>
            <h4>Satellite Imagery Services</h4>
        </div>
        <div class="services-progress text-center">
            <i class="flaticon-warehouse-2"></i>
            <h4>Near by Warehouses</h4>
        </div>
        <div class="services-progress text-center">
            <i class="flaticon-warehouse"></i>
            <h4>Near by Cold Storages</h4>
        </div>
        <div class="services-progress text-center">
            <i class="flaticon-business-report"></i>
            <h4>Stock Inventory Management</h4>
        </div>
    </div>
    <div class="row">
    <h5 class="pb-20 text-center">Post-Harvest Digitization</h5>
        <div class="services-progress text-center">
            <i class="flaticon-location-1"></i>
            <h4>Mills & Processing Units Geo tagging</h4>
        </div>
        <div class="services-progress text-center">
            <i class="flaticon-mill"></i>
            <h4>Cluster Wise Mills</h4>
        </div>
        <div class="services-progress text-center">
            <i class="flaticon-water-mill"></i>
            <h4>Nearest Mills to Farmer</h4>
        </div>
    </div>
  </div>
</section>
<section class="ls ms section_padding_top_50 section_padding_bottom_50">
  <div class="container">
    <div class="row pb-50">
      <div class="col-md-6 col-sm-12 pt-20">
        <h5 class="color-green">Farmers Professional Network</h5>
        <p class="bold grey">Jobs, Salary Credits - Daily Transaction, Labour and Machine Checklist.</p>
        <p class="grey">The Global Farmer Network is a non-profit advocacy group led by farmers who support free trade and farmers' freedom to choose the tools, technologies, and strategies they need to maximize productivity and profitability in a sustainable manner.</p>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-services" class="btn btn-success btn-sm" 
          (click)="showEnquiry('Farmers Professional Network')">Enquiry Now</a></p>
      </div>
      <div class="col-md-6 col-sm-12 pt-20"> <img src="assets/images/FarmersProfessionalNetwork.jpg" alt="Farmers Professional Network" class="img-responsive" /> </div>
    </div>
    <div class="row">
        <div class="network-progress text-center">
            <i class="flaticon-data-analytics-upgoing-bars-chart"></i>
            <h4>Acquiring cutting edge information through participation in educational conferences</h4>
        </div>
        <div class="network-progress text-center">
            <i class="flaticon-network-1"></i>
            <h4>Building a network with fellow farmers and agricultural enthusiasts</h4>
        </div>
        <div class="network-progress text-center">
            <i class="flaticon-human-resources"></i>
            <h4>Earning recognition for their achievements in business excellence in business and leadership success</h4>
        </div>
        <div class="network-progress text-center">
            <i class="flaticon-sprout"></i>
            <h4>Continuing their professional development and personal growth</h4>
        </div>
    </div>
  </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50">
  <div class="container">
    <div class="row pb-50">
      <div class="col-md-6 col-sm-12 pt-20">
        <h5 class="color-green">Season Based Insurance</h5>
        <p class="grey">There is provision for estimation of crop damage at the end of
           season on the basis of crop production obtained from crop harvesting experiments in
            fixed number in Gram Panchayat. In case of crop damages by local calamities-hail stone,
             land slide and water logging, there is provision of estimation of estimation of loss at
              personal level and in other risks at Gram Panchayat level.</p>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-services" class="btn btn-success btn-sm"
           (click)="showEnquiry('Season Based Insurance')">Enquiry Now</a></p>
      </div>  
      <div class="col-md-6 col-sm-12 pt-20"> <img src="assets/images/SeasonBasedInsurance.jpg" alt="Season Based Insurance" class="img-responsive" /> </div>
    </div>
    <div class="row">
        <div class="network-progress text-center">
            <i class="flaticon-fund"></i>  
            <h4>Stabilisation of farmer income</h4>
        </div>
        <div class="network-progress text-center">
            <i class="flaticon-tractor"></i>
            <h4>Farmer to manage argicultural risk better</h4>
        </div>
        <div class="network-progress text-center">
            <i class="flaticon-alert"></i>
            <h4>Quick and accurate yield estimation leading to faster claims settlement</h4>
        </div>
        <div class="network-progress text-center">
            <i class="flaticon-plants"></i>
            <h4>Proposed to be implemented from Kharif 2020 season</h4>
        </div>
    </div>
  </div>
</section>
<section class="ls ms section_padding_top_50 section_padding_bottom_50 text-center">
  <div class="container">
    <h4 class="pb-20 text-center">Benefits of having this platform</h4>
    <div class="col-md-3 col-sm-6 mb-30 benefits-ht">
      <p><img src="assets/images/banks-insurance.png" alt="Additional revenue to Govt" /></p>
      <p class="grey">Additional revenue to Govt by facilitating data to banks and insurance</p>
    </div>
    <div class="col-md-3 col-sm-6 mb-30 benefits-ht">
      <p><img src="assets/images/pre-harvest.png" alt="Pre-harvest and post-harvest can be planned" /></p>
      <p class="grey">Pre-harvest and post-harvest can be planned</p>
    </div>
    <div class="col-md-3 col-sm-6 mb-30 benefits-ht">
      <p><img src="assets/images/reports-generated.png" alt="Reports can be generated for department bodies" /></p>
      <p class="grey">Reports can be generated for department bodies</p>
    </div>
    <div class="col-md-3 col-sm-6 mb-30 benefits-ht">
      <p><img src="assets/images/job-creation.png" alt="Job creation at Rural Places" /></p>
      <p class="grey">Job creation at Rural Places</p>
    </div>
  </div>
</section>
<div class="modal trans-05" tabindex="-1" role="dialog" id="enquiry-services">
  <div class="modal-dialog" role="document">
    <form id="frmContact" novalidate="novalidate" #myform = "ngForm" >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{selectedEnquiry}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetButton()"> 
            <span aria-hidden="true">&times;</span> </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="name" [(ngModel)]="enquiryModel.name" name="name" 
                placeholder="Full Name" title="Name" class="form-control" aria-required="true" required>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="designation" [(ngModel)]="enquiryModel.designation" 
                name="designation" placeholder="Designation" title="Designation" 
                class="form-control" aria-required="true" required>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="phone" [(ngModel)]="enquiryModel.phone" name="phone" 
                [pattern]="mobNumberPattern" #mobNumber="ngModel"
                placeholder="Phone Number" title="Phone" class="form-control phone" aria-required="true" 
                (focusout)="onFocusOutEvent($event)" 
                required>
              </div>
              <div class="form-group" *ngIf="mobNumber.errors" >  
                <div *ngIf="mobNumber.errors.pattern">  
                  Mobile number not valid.  
                </div>   
              </div>
              <div class="form-group" *ngIf="!otpHide">
                <input type="text" id="otp" [(ngModel)]="otp" name="otp" 
                placeholder="Enter OTP" title="OTP" class="form-control phone" aria-required="true" required>
              </div> 
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input ngModel type="text" id="email" [(ngModel)]="enquiryModel.emailId" 
                name="emailId" placeholder="Email ID" title="Email" class="form-control email" 
                aria-required="true" required  [pattern]="emailPattern" #emailPtrn="ngModel">
              </div>
              <div class="form-group" *ngIf="emailPtrn.errors" >  
                <div *ngIf="emailPtrn.errors.pattern">  
                  Invalid email is entered.  
                </div>   
              </div>
              <div class="form-group" *ngIf="!otpHide">
                <button class="btn btn-primary" (click)="verifyOtp()" >Veriy OTP</button>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <textarea  id="comment-content" [(ngModel)]="enquiryModel.message" name="message" 
                rows="3" placeholder="Message" class="form-control"></textarea>
              </div>
            </div>
          </div>  
        </div>
        <span >{{response.message}}</span>
        <div class="modal-footer" > 
          
          <button type="submit" class="btn btn-primary" 
            value="Submit" [disabled]="clicked" (click)="saveEnquiry(myform.value)">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>