<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h2>Newsletter</h2>
          <ol class="breadcrumb greylinks color4">
            <li> <a href="index.html"> Home </a> </li>
            <li> Resources</li>
            <li class="active">Newsletter</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="ls section_padding_top_50 section_padding_bottom_50">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pb-20"> <img src="assets/images/newsletter-pic.jpg" class="img-responsive" alt="HireMyFarmer"/> </div>
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 pb-20">
          <p><img src="assets/images/newsletter/1.jpg" class="img-responsive" alt="HireMyFarmer"/></p>
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pb-20">
              <div class="testimonial-block gray-bg">
                <div class="bg-white outline pinside30">
                  <a href="https://blog.brinc.io/welcome-to-our-spring-2019-accelerator-teams-63812ec8351f" target="_blank">
                  <img src="assets/images/newsletter/1.jpg" class="img-responsive" alt="HireMyFarmer" /></a>
                  <!-- <h6>Bloqcube (USA)</h6> -->
                  
                  <p class="grey">Believing in the Founder’s vision and supporting for new impact investment 
                    with a social impact Investor’s Network is the Biggest gift for a struggling 
                    Entrepreneur. <b>HireMyFarmer</b> was recognized as Social impact Agri-Tech Start-up and raised 
                    First round of VC Fund (Artesian) in “Hong Kong” with Support of Brinc. 
                    They created an ecosytem to connect the business directly with farmers.  </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pb-20">
              <div class="testimonial-block gray-bg">
                <div class="bg-white outline pinside30">
                  <a href="https://fb.watch/WIfYgYX48/" target="_blank">
                    <img src="assets/images/newsletter/4.jpg" class="img-responsive" alt="HireMyFarmer" /></a>
                   
                  <!-- <h6>Hire My Farmer (India)</h6> -->
                  <p class="grey">During the pandemic hit of covid-19 times, struggle to raise second round of 
                    funding was successful to HireMyFarmer, though the operations were 
                    hampered at Supply chain but we have represented virtually to International 
                    investors & partners on planning our company strategy and milestones</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-20">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pb-20">
          <div class="testimonial-block gray-bg">
            <div class="bg-white outline pinside30">
              <!-- <h6>SeeknCheck (France)</h6> -->
              <a href="https://www.business-standard.com/article/pti-stories/punjab-govt-launches-e
              stac-programme-for-startups-119120601446_1.html" target="_blank">
                    <img src="assets/images/newsletter/4.png" class="img-responsive" alt="HireMyFarmer" /></a>
              <p class="grey">Punjab Chief Minister Amarinder Singh on Friday launched Enable Startup Track 
                Acceleration (ESTAC) programme during Progressive Punjab Investors 
                Summit-2019, with an aim to foster entrepreneurship and innovation in the state. 
                HireMyFarmer is happy to be a part of this programme!!! given the opportunity to 
                participate in the ESTAC programme and secure pilot projects with the government of 
                Punjab 
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pb-20">
          <div class="testimonial-block gray-bg">
            <div class="bg-white outline pinside30">
              <a href="https://www.youtube.com/watch?v=ySYHYRgmqv4" target="_blank">
                    <img src="assets/images/newsletter/5.png" class="img-responsive" alt="HireMyFarmer" /></a>
              <!-- <h6>Datalytics (Argentina)</h6> -->
              <p class="grey">Pitching it to International investors @ Hongkong, Thanks a lot Brinc for 
                supporting “HireMyFarmer” under your portfolio. HireMyFarmer has created an ecosystem and platform that 
                connects farmers directly to retailers. It enables Retailers to make direct orders from farmers without the intervention 
                of middlemen.                
                </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pb-20">
          <div class="testimonial-block gray-bg">
            <div class="bg-white outline pinside30">
              <a href="https://pitchbook.com/profiles/company/268185-34 " target="_blank">
                    <img src="assets/images/newsletter/7.jpg" class="img-responsive" alt="HireMyFarmer" /></a>
              <!-- <h6>Oropocket (India)</h6> -->
              <p class="grey">Developer of a service-oriented platform intended to connect farmers directly to retailers. 
                The company's solution enables better foresight and planning of agricultural production and retailers to make direct orders from farmers without the intervention of middlemen, 
                enabling clients in smoothening the process of connecting with farmers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pb-20">
          <div class="testimonial-block gray-bg">
            <div class="bg-white outline pinside30">
              <!-- <h6>eSusFarm (South Africa)</h6> -->
              <a href="https://twitter.com/ICRISAT/status/1185750263941849088?s=20" target="_blank">
                <img src="assets/images/newsletter/2.jpg" class="img-responsive" alt="HireMyFarmer" /></a>
              <p class="grey">We have been invited by ICRISAT 
                and CGIAR SUMMIT for the 
                dedicated workshop to explore the 
                impact of Data in Agriculture 
                ecosystem to bring impact in small 
                hold farmers life’s, HireMyFarmer 
                shared experiences with 
                international expert’s with support 
                of CGIAR initiative </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pb-20">
          <div class="testimonial-block gray-bg">
            <div class="bg-white outline pinside30">
              <!-- <h6>ProgressMe (Sweden)</h6> -->
              <a href="https://www.aicisb.org/en/ourstartups-portfolios.html" target="_blank">
                <img src="assets/images/newsletter/3.jpg" class="img-responsive" alt="HireMyFarmer" /></a>
              <p class="grey">
                HireMyFarmer is also a part of ISB initiative curated organization.
                Considering we are the right fit for implementing the initiative of  
                ISB Mohali. Punjab Government launches ‘ESTAC’ start-up accelerator programme
                The first of the series of ESTAC programme focuses on agri-tech
                </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pb-20">
          <div class="testimonial-block gray-bg">
            <div class="bg-white outline pinside30">
              <!-- <h6>Ionoterra (Israel)</h6> -->
              <a href="https://twitter.com/yourstoryco/status/1226828512973078530" target="_blank">
                <img src="assets/images/newsletter/6.jpg" class="img-responsive" alt="HireMyFarmer" /></a>
              <p class="grey">VISA Everywhere, why not in SMALLHOLD Farmers life’s  
                <b>HireMyFarmer</b> Fintech layer supports payments to farmers with Free virtual 
                credit cards and access to post-harvest management where their payments can 
                be managed by Individual’s and plan for the agri-investment return’s, this was 
                one solution been represented for collaborating with VISA services to create 
                the omni-channel for fintech layer of HireMyFarmer with support of Financial 
                institution.It enables zero budget farming for farmers and reduces the cost of farming activites.
               
                </div>
          </div>
        </div>
      </div>
    </div>
  </section>