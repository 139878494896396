<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h2>Gallery</h2>
          <ol class="breadcrumb greylinks color4">
            <li> <a href="index.html"> Home </a> </li>
            <li class="active">Gallery</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="ls section_padding_top_50 section_padding_bottom_50">
    <div class="container">
      <div class="row">
       <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
          <a href="journey.html"><img src="assets/images/gallery/thumb-38.jpg" alt="Government" class="img-responsive img-thumbnail" /></a>
          <h6 class="text-center"><a href="journey.html">Journey</a></h6>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
          <a href="connects.html"><img src="assets/images/gallery/thumb-6.jpg" alt="Connects" class="img-responsive img-thumbnail" /></a>
          <h6 class="text-center"><a href="connects.html">Connects</a></h6>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
          <a href="awards-recognition.html"><img src="assets/images/gallery/thumb-1.jpg" alt="Awards Recognition" class="img-responsive img-thumbnail" /></a>
          <h6 class="text-center"><a href="awards-recognition.html">Awards & Recognition</a></h6>
        </div>
       <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
          <a href="leaders-meet.html"><img src="assets/images/gallery/thumb-9.jpg" alt="Leaders Meet" class="img-responsive img-thumbnail" /></a>
          <h6 class="text-center"><a href="leaders-meet.html">Leaders Meet</a></h6>
        </div>
      </div>
    </div>
  </section>