<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2>Third Party Systems</h2>
        <ol class="breadcrumb greylinks color4">
          <li> <a href="index.html"> Home </a> </li>
          <li> <a href="solutions.html"> Solutions </a> </li>
          <li class="active">Third Party Systems</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50">
  <div class="container">
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-third-party"><img src="assets/images/icon55.png"
              alt="Logistics Integration" /></a></p>
        <h5>Logistics Integration</h5>
        <p class="grey show-less-div-1">HireMyFarmer Integrates third party service operations to facilitate farmer
          during post- harvest activities for in-bound/out-bound logistic services to aggregate processing stock,
          warehouse stock, customer stockin order to make sure that the delivery goes at right place in right time by
          following quality standards along with telemetric supporting services.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/_4jGRjcvlDI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
            <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span class="close-video"></span>
            </div>
            </div>
            
            
        <p><a href="#" data-toggle="modal" data-target="#enquiry-third-party" class="btn btn-success btn-sm"
          (click)="showEnquiry('Logistics Integration')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-third-party"><img src="assets/images/icon1.png"
              alt="Small hold retail outlets" /></a></p>
        <h5>Small hold retail outlets</h5>
        <p class="grey show-less-div-1">HireMyFarmer brings opportunity to small hold retail outlets to access the
          post-harvest infrastructure facilities like processing units in order to reduce the burden to small hold
          farmers. These retail outlets will have access to good farmers base for their business, if retailers come up
          with a brand, we connect them to market place through our portal services to E-commerce engine and these
          retail outlets can make their own businesses.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/1OxKQaDAP4o" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
            <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span class="close-video"></span>
            </div>
            </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-third-party" class="btn btn-success btn-sm"
          (click)="showEnquiry('Small hold retail outlets')">Enquiry
            Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-third-party"><img src="assets/images/icon56.png"
              alt="Warehouses and cold storages services" /></a></p>
        <h5>Warehouses and cold storages services</h5>
        <p class="grey show-less-div-1">HireMyFarmer on-boards warehouses and cold storages for the post-harvest service
          layer, we connect farmers to their nearby warehouses and cold storages services to facilitate farmers stock to
          the nearest unit. Stock inventory will be managed through our system, if a farmer wants to promote their own
          brand, HireMyFarmer support team will help that farmer to label a brandand connect with market to sell out his
          stock for a better margin.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/KijHN7kfNQ4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-third-party" class="btn btn-success btn-sm"
          (click)="showEnquiry('Warehouses and cold storages services')">Enquiry
            Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-third-party"><img src="assets/images/icon19.png"
              alt="Crop dependent retail units" /></a></p>
        <h5>Crop dependent retail units</h5>
        <p class="grey show-less-div-1">HireMyFarmer POS (point of sale) predicts the exact sale of each individual
          commodity and trigger backs the production request to manage the orders for the crop dependent retailers. We
          facilitate the data to farmers to make sure their plan of harvest is based on the POS of Urban consumers.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/y2dXeW8BpPs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-third-party" class="btn btn-success btn-sm"
          (click)="showEnquiry('Crop dependent retail units')">Enquiry
            Now</a></p>
      </div>
      <!-- <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-third-party"><img src="assets/images/icon57.png"
              alt="Satellite imagery services" /></a></p>
        <h5>Satellite imagery services</h5>
        <p class="grey show-less-div-1">HireMyFarmer integrates satellite imagery services with partners which helps to
          navigate productive farming lands, crop land conditions, weather alerts as such will be provided to multiple
          departmental bodies and research institutes which in turn helps out to support farmer hubs and assist farmers
          for taking corresponding steps during pre/post-harvest.</p>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-third-party" class="btn btn-success btn-sm"
          (click)="showEnquiry('Satellite imagery services')">Enquiry
            Now</a></p>
      </div> -->
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-third-party"><img src="assets/images/icon58.png"
              alt="Rental Machinery Companies" /></a></p>
        <h5>Rental Machinery Companies</h5>
        <p class="grey show-less-div-1">HireMyFarmer enables location based services at rural places for farmers during
          their pre/post-harvest, Farmer community centre will acknowledge the respective farmers in need of machinery
          during every season, we keep track of the activities through our Geo-fencing technology to allocate the list
          of machinery equipment through a centralized hub to respective farming lands.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/Wd0hC4cXxM4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-third-party" class="btn btn-success btn-sm"
          (click)="showEnquiry('Rental Machinery Companies')">Enquiry
            Now</a></p>
      </div>
    </div>

    <div class="row pb-50">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"><a href="#" data-toggle="modal"
          data-target="#enquiry-third-party"><img src="assets/images/pesticide.jpg" alt="Pesticide"
            class="img-responsive" /></a></div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <h5>Pesticide companies</h5>
        <ul class="timeline">
          <li class="grey">HireMyFarmer has pre-harvest service layer where the Bio pesticide companies can add value by
            providing their services directly to our farmers &farm lands, necessary fertilizers & pesticides as per the
            farm land will be guided to farmers through farmers hub which helps to maintain healthy agriculture
            practices and also these companies can avail benefits like where they can reduce cost of their Distribution
            & Marketing by partnering with HireMyFarmer.</li>
          <li class="grey">Report of the harvested crop and its utilization of pesticide & fertilizers are tracked
            through our technology making sure the results of pests & fertilizers shared to the farmers hub every season
            so, that decision will be taken whether to recommending it for next season or not.</li>
        </ul>
        <p class="grey">If you have any queries please feel free to contact us <a href="#" data-toggle="modal"
            data-target="#enquiry-third-party" class="btn btn-success btn-sm"
            (click)="showEnquiry('Pesticide companies')">Enquiry Now</a></p>
      </div>
    </div>
    <div class="row pb-50">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <h5>Seed input companies</h5>
        <ul class="timeline">
          <li class="grey">HireMyFarmer has pre-harvest service layer where the seed companies can add value by
            providing their services directly to our farmers & farm lands, necessary seeds as per the farm land will be
            guided to farmers through farmers hub which helps to maintain healthy agriculture practices and also these
            companies can avail benefits, where they can reduce cost of their Distribution & Marketing by partnering
            with HireMyFarmer.</li>
          <li class="grey">Report of the harvested crop and its actual yield estimates are tracked through our
            technology making sure the actual crop results will be shared with farmers hub every season, based on that
            decision will be taken either to recommend it to next season or not.</li>
        </ul>
        <p class="grey">If you have any queries please feel free to contact us <a href="#" data-toggle="modal"
            data-target="#enquiry-third-party" class="btn btn-success btn-sm"
            (click)="showEnquiry('Seed input companies')">Enquiry Now</a></p>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"><a href="#" data-toggle="modal"
          data-target="#enquiry-third-party"><img src="assets/images/seed.jpg" alt="seeds" class="img-responsive" /></a>
      </div>
    </div>
  </div>
</section>
<div class="modal trans-05" tabindex="-1" role="dialog" id="enquiry-third-party">
  <div class="modal-dialog" role="document">
    <form id="frmContact" novalidate="novalidate" #myform = "ngForm" >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{selectedEnquiry}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetButton()"> 
            <span aria-hidden="true">&times;</span> </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="name" [(ngModel)]="enquiryModel.name" name="name" 
                placeholder="Full Name" title="Name" class="form-control" aria-required="true" required>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="designation" [(ngModel)]="enquiryModel.designation" 
                name="designation" placeholder="Designation" title="Designation" 
                class="form-control" aria-required="true" required>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="phone" [(ngModel)]="enquiryModel.phone" name="phone" 
                [pattern]="mobNumberPattern" #mobNumber="ngModel"
                placeholder="Phone Number" title="Phone" class="form-control phone" aria-required="true" 
                (focusout)="onFocusOutEvent($event)" 
                required>
              </div>
              <div class="form-group" *ngIf="mobNumber.errors" >  
                <div *ngIf="mobNumber.errors.pattern">  
                  Mobile number not valid.  
                </div>   
              </div>
              <div class="form-group" *ngIf="!otpHide">
                <input type="text" id="otp" [(ngModel)]="otp" name="otp" 
                placeholder="Enter OTP" title="OTP" class="form-control phone" aria-required="true" required>
              </div> 
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input ngModel type="text" id="email" [(ngModel)]="enquiryModel.emailId" 
                name="emailId" placeholder="Email ID" title="Email" class="form-control email" 
                aria-required="true" required  [pattern]="emailPattern" #emailPtrn="ngModel">
              </div>
              <div class="form-group" *ngIf="emailPtrn.errors" >  
                <div *ngIf="emailPtrn.errors.pattern">  
                  Invalid email is entered.  
                </div>   
              </div>
              <div class="form-group" *ngIf="!otpHide">
                <button class="btn btn-primary" (click)="verifyOtp()" >Veriy OTP</button>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <textarea  id="comment-content" [(ngModel)]="enquiryModel.message" name="message" 
                rows="3" placeholder="Message" class="form-control"></textarea>
              </div>
            </div>
          </div>  
        </div>
        <span >{{response.message}}</span>
        <div class="modal-footer" >
          
          <button type="submit" class="btn btn-primary" 
            value="Submit" [disabled]="clicked" (click)="saveEnquiry(myform.value)">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>