<!-- <div class="preloader">
  <div class="preloader_image"></div>
</div> -->
<div id="canvas">
  <div id="box_wrapper">
    <app-header></app-header>
    <router-outlet></router-outlet>
    
    <footer class="page_footer ds section_padding_top_50 section_padding_bottom_50 columns_margin_bottom_10">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 text-sm-left">
            <div class="widget widget_text greylinks color2">
              <h5> Services</h5>
              <ul class="footer-menu">
                <li><a href="systematic-investment-plan">Systematic Investment Plan</a></li>
                <li><a href="free-virtual-cards">Free Virtual Cards</a></li>
                <li><a href="farmer-stock-exchange">Farmer Stock Exchange</a></li>
                <li><a href="farmer-market-space">Farmer Market Place</a></li>
                <li><a href="farmer-community-center">Farmer Community Center</a></li>
                <li><a href="farmer-experience-store">Farmer Experience Store</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 text-sm-left">
            <div class="widget widget_text greylinks color2">
              <h5> Solutions </h5>
              <ul class="footer-menu">
                <li><a href="government">Government </a></li>
                <li><a href="corporate">Corporate </a></li>
                <li><a href="banking">Banking</a></li>
                <li><a href="insurance">Insurance</a></li>
                <li><a href="third-party-systems">Third Party Systems</a></li>
                <li><a href="business">Business</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 text-sm-left">
            <div class="widget widget_recent_posts">
              <h5 class="pb-20">Recent Posts</h5>
              <ul>
                <li class="media">
                  <div class="media-left media-middle"> <img src="assets/images/recent_post1.jpg" alt=""> </div>
                  <div class="media-body media-middle">
                    <p class="darklinks"> <a href="javascript:void(0)">A platform which enables the user to navigate
                        farming lands...</a> </p>
                    <span class="small-text highlightlinks"> <a href="javascript:void(0)">
                        <time datetime="2017-10-03T08:50:40+00:00"> 14 Oct, 2020</time>
                      </a> </span>
                  </div>
                </li>
                <li class="media">
                  <div class="media-left media-middle"> <img src="assets/images/recent_post2.jpg" alt=""> </div>
                  <div class="media-body media-middle">
                    <p class="darklinks"> <a href="javascript:void(0)">Showcasing of Agriculture services in a
                        platform...</a> </p>
                    <span class="small-text highlightlinks"> <a href="javascript:void(0)">
                        <time datetime="2017-10-03T08:50:40+00:00"> 30 Sep, 2020</time>
                      </a> </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 text-sm-left">
            <div class="widget widget_text greylinks color2">
              <!-- <div id="subscribeNow"></div> -->
              <h5 > Subscribe Now</h5>
              <p>Join our newsletter to stay updated.</p>
              <form action="" method="post">
                <div class="input-group ">
                  <input type="email" [(ngModel)]= "emailId" class="form-control border-0 shadow-none" id="newsletter" name="newsletter"
                    placeholder="Write E-Mail Address" [pattern]="emailPattern" #emailPtrn="ngModel">
                  <span class="input-group-btn">
                    <button class="theme_button color2 block_button"  
                    (click)="notifyUser()">Go!</button>
                    <notifier-container></notifier-container>
                    <div class="form-group" *ngIf="emailPtrn.errors" >  
                    <div *ngIf="emailPtrn.errors.pattern">  
                      Invalid email is entered.  
                    </div> 
                  </div>
                  </span> </div>
              </form>
              <ul class="footer-social">
                <li><a href="https://www.facebook.com/hireMyFarmer" target="_blank"><i class="fa fa-lg fa-facebook"></i></a></li>
                <li><a href="https://instagram.com/hmf.india?igshid=vjlnslpdw9ma" target="_blank"><i class="fa fa-lg fa-instagram"></i></a>
                </li>
                <li><a href="https://mobile.twitter.com/hiremyfarmer" target="_blank"><i class="fa fa-lg fa-twitter"></i></a></li>
                <li><a href="https://www.linkedin.com/company/hiremyfarmer/" target="_blank"><i class="fa fa-lg fa-linkedin"></i></a></li>
                <li><a href="mailto:info@hiremyfarmer.com" target="_blank">
                  <i class="fa fa-lg fa-envelope"></i></a></li>                
              </ul>
              <div class="f-contact">
              <p >
                Contact us : +91 9880562082<br/>                
                H.No 235, Chaman bavi, Hayath Nagar Village, Amangal<br/>Hyderabad, Telangana - 501505<br/>
                <i class="fa fa-lg fa-envelope"></i> hello@hiremyfarmer.com
              </p>              
            </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <section class="ds ms page_copyright section_padding_20">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <p>&copy; 2021 HireMyFarmer. All Rights Reserved.  | <a href="terms">Terms &amp; Conditions</a>              | 
            </p>
          </div>
        </div>
      </div>
    </section>
    
  </div>
</div>