import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { InvestorsComponent } from './investors/investors.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { ServicesComponent } from './services/services.component';
import { GlobalPartnersComponent } from './global-partners/global-partners.component';
import { BoardMembersAdvisorsComponent } from './board-members-advisors/board-members-advisors.component';
import { ExecutiveTeamComponent } from './executive-team/executive-team.component';
import { GovernmentComponent } from './government/government.component';
import { CorporateComponent } from './corporate/corporate.component';
import { BanksComponent } from './banks/banks.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { ThirdPartySystemsComponent } from './third-party-systems/third-party-systems.component';
import { BusinessComponent } from './business/business.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { FarmerCommunityCenterComponent } from './farmer-community-center/farmer-community-center.component';
import { FarmerExperienceStoreComponent } from './farmer-experience-store/farmer-experience-store.component';
import { FarmerMarketSpaceComponent } from './farmer-market-space/farmer-market-space.component';
import { FarmerStockExchangeComponent } from './farmer-stock-exchange/farmer-stock-exchange.component';
import { FreeVirtualCardsComponent } from './free-virtual-cards/free-virtual-cards.component';
import { SystematicInvestmentPlanComponent } from './systematic-investment-plan/systematic-investment-plan.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AwardsRecognitionComponent } from './awards-recognition/awards-recognition.component';
import { ConnectComponent } from './connect/connect.component';
import { ConnectsComponent } from './connects/connects.component';
import { JourneyComponent } from './journey/journey.component';
import { LeadersMeetComponent } from './leaders-meet/leaders-meet.component';
import { CareersComponent } from './careers/careers.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { CaseStudyComponent } from './case-study/case-study.component';
import { TermsComponent } from './terms/terms.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SecureComponent } from './secure/secure.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HeaderComponent } from './header/header.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';

const customNotifierOptions: NotifierOptions = {
  position: {
      horizontal: {
          position: 'right',
          distance: 50
      },
      vertical: {
          position: 'bottom',
          distance: 150,
          gap: 10
      }
  },
  theme: 'material',
  behaviour: {
      autoHide: 5000,
      onClick: false,
      onMouseover: 'pauseAutoHide',
      showDismissButton: true,
      stacking: 4
  },
  animations: {
      enabled: true,
      show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease'
      },
      hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50
      },
      shift: {
          speed: 300,
          easing: 'ease'
      },
      overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    InvestorsComponent,
    SolutionsComponent,
    ServicesComponent,
    GlobalPartnersComponent,
    BoardMembersAdvisorsComponent,
    ExecutiveTeamComponent,
    GovernmentComponent,
    CorporateComponent,
    BanksComponent,
    InsuranceComponent,
    ThirdPartySystemsComponent,
    BusinessComponent,
    TestimonialsComponent,
    FarmerCommunityCenterComponent,
    FarmerExperienceStoreComponent,
    FarmerMarketSpaceComponent,
    FarmerStockExchangeComponent,
    FreeVirtualCardsComponent,
    SystematicInvestmentPlanComponent,
    GalleryComponent,
    AwardsRecognitionComponent,
    ConnectComponent,
    ConnectsComponent,
    JourneyComponent,
    LeadersMeetComponent,
    CareersComponent,
    NewsletterComponent,
    CaseStudyComponent,
    TermsComponent,
    LoginComponent,
    RegisterComponent,
    SecureComponent,
    NotFoundComponent,
    HeaderComponent,
    ForgotPasswordComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,    
    HttpClientModule,
    NotifierModule.withConfig(customNotifierOptions),
    Ng2TelInputModule,
    NgxSmartModalModule.forRoot()
  ],
  providers: [  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})


export class AppModule { }
