import { Component, OnInit } from '@angular/core';
import { EnquiryService, SimpleResponse } from '../enquiries/enquiry.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private enquiryService : EnquiryService) { }
  goSubmit: boolean;
  emailId: String;
  response: SimpleResponse;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  ngOnInit(): void {
    this.response= new SimpleResponse()
  }


  sendForgotPasswordDetails(){
    console.log("bbbmmnb"+this.emailId)
    if (this.emailId != null || this.emailId != "") {
      
      this.goSubmit = true;
    }else{
      this.response.message = 'Emailid is required..';
    }
    if(this.goSubmit){
      this.enquiryService.sendForgotpasswordDetails(this.emailId).subscribe(data => {
        this.response = data;
        console.log(this.response);        
      });
    }
  }
}
