<div id="canvas">
  <div id="box_wrapper">
    <section class="intro_section page_mainslider ds light_md_bg_color all-scr-cover">
      <div class="flexslider" data-dots="true" data-nav="true">
        <ul class="slides">
          <li>
            <div class="slide-image-wrap">
              <div class="rounded-container"> <img src="assets/images/slide01.jpg" alt="Introducing farmer as a service" /> </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <div class="slide_description_wrapper">
                    <div class="slide_description">
                      <div class="intro-layer" data-animation="fadeInUp">
                        <h3 class="text-shadow">Introducing farmer as a service to the enterprise with corporate dignity.</h3>
                      </div>
                      <div class="intro-layer" data-animation="fadeInUp">
                        <p class="grey text-shadow des"> Every service in enterprise transforming to a professional service through technology integration, it's time to represent farmers service in enterprise.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="slide-image-wrap">
              <div class="rounded-container"> <img src="assets/images/slide02.jpg" alt="farmers service is promoted" /> </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <div class="slide_description_wrapper">
                    <div class="slide_description">
                      <div class="intro-layer" data-animation="fadeInUp">
                        <h3 class="text-shadow">A place where farmers service is promoted and farmers make business.</h3>
                      </div>
                      <div class="intro-layer" data-animation="fadeInUp">
                        <p class="grey text-shadow des"> Virtual Market-place for Agriculture ecosystem during post-harvest resolves many problems to farmers, It's the place where farmers need technology intervention to make business to sell their stock at right prices.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="slide-image-wrap">
              <div class="rounded-container"> <img src="assets/images/slide03.jpg" alt="One stop solution for all farmers" /> </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <div class="slide_description_wrapper">
                    <div class="slide_description">
                      <div class="intro-layer" data-animation="fadeInUp">
                        <h3 class="text-shadow">One stop solution for all farmers to claim their insurances &amp; validation reports.</h3>
                      </div>
                      <div class="intro-layer" data-animation="fadeInUp">
                        <p class="grey text-shadow des"> Every farmer has a different farming land, Mother nature always responds to the way you talk to your farming land, Insurance's were archived based on the cluster as a unit, there is a strong need for Farmers to know what exactly the reason for their loss, This solution from us will provision lot of data for departmental and Third-party systems to acknowledge on status of the farmers crop failure.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="slide-image-wrap">
              <div class="rounded-container"> <img src="assets/images/slide04.jpg" alt="Next generation farmers network" /> </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <div class="slide_description_wrapper">
                    <div class="slide_description">
                      <div class="intro-layer" data-animation="fadeInUp">
                        <h3 class="text-shadow">Next generation farmers network which will access virtual enterprise services (FinTech).</h3>
                      </div>
                      <div class="intro-layer" data-animation="fadeInUp">
                        <p class="grey text-shadow des"> Farmer's network and cluster specific farmers, small-hold farmers, seasonal farmers, farmers looking for pre/post-harvest supports and Fintech inclusions for the technology to plan your business with farmers in a single platform.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="slide-image-wrap">
              <div class="rounded-container"> <img src="assets/images/slide05.jpg" alt="Hire farmers for your farmlands" /> </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <div class="slide_description_wrapper">
                    <div class="slide_description">
                      <div class="intro-layer" data-animation="fadeInUp">
                        <h3 class="text-shadow">Hire farmers for your farmlands.</h3>
                      </div>
                      <div class="intro-layer" data-animation="fadeInUp">
                        <p class="grey text-shadow des"> Facilitation of farmers based on your location of your farming land is the biggest step, where your farming land will be useful for the needy and you can grow in parallel with our system.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="slide-image-wrap">
              <div class="rounded-container"> <img src="assets/images/slide06.jpg" alt="Facilitation of post-harvest aggregation" /> </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <div class="slide_description_wrapper">
                    <div class="slide_description">
                      <div class="intro-layer" data-animation="fadeInUp">
                        <h3 class="text-shadow">Facilitation of post-harvest aggregation to customers in favour of farmers.</h3>
                      </div>
                      <div class="intro-layer" data-animation="fadeInUp">
                        <p class="grey text-shadow des"> Third-parties, processing units, packaging services, logistics are very far reach for a small-hold farmer to approach and to make business, we are facilitating these to cluster specific farmers for a unit wise service aggregation for betterment of farmers service.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="slide-image-wrap">
              <div class="rounded-container"> <img src="assets/images/slide07.jpg" alt="Invest on a farm to support your business" /> </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <div class="slide_description_wrapper">
                    <div class="slide_description">
                      <div class="intro-layer" data-animation="fadeInUp">
                        <h3 class="text-shadow">Invest on a farm to support your business.</h3>
                      </div>
                      <div class="intro-layer" data-animation="fadeInUp">
                        <p class="grey text-shadow des"> We say no to contract farming but our approach is simple that we encourage Agri-investments with a very limited risk for end customers, where they can make business along with farmers or they can be a direct customer to farmers by investing in pre-production planning based on their business instead of getting exploited by unwanted Middlemen Networks.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="slide-image-wrap">
              <div class="rounded-container"> <img src="assets/images/slide08.jpg" alt="Know your farmer" /> </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <div class="slide_description_wrapper">
                    <div class="slide_description">
                      <div class="intro-layer" data-animation="fadeInUp">
                        <h3 class="text-shadow">Know your farmer</h3>
                      </div>
                      <div class="intro-layer" data-animation="fadeInUp">
                        <p class="grey text-shadow des"> Find out who is your farmer growing for you, you can reach out to his land and plan your schedule accordingly to spend a good time to make a good relationship with your farmer and understand much better about the ecosystem.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="slide-image-wrap">
              <div class="rounded-container"> <img src="assets/images/slide09.jpg" alt="Monitor your farmlands" /> </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <div class="slide_description_wrapper">
                    <div class="slide_description">
                      <div class="intro-layer" data-animation="fadeInUp">
                        <h3 class="text-shadow">Monitor your farmlands &amp; get real time satellite data.</h3>
                      </div>
                      <div class="intro-layer" data-animation="fadeInUp">
                        <p class="grey text-shadow des"> Keep track of your agri-business along with real time predictive data where you can know the status of your farming land from anywhere of the globe. We support not only farmer but also we protect your farming land.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="ls section_offset_teasers section_padding_top_50 section_padding_bottom_10">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-6 to_animate no_appear_delay" data-animation="fadeInDown" data-delay="600">
            <div class="teaser top_offset_icon main_bg_color rounded text-center">
              <div class="teaser_icon size_small round main_bg_color"> <a href="government.html">
                <img src="assets/images/government.png" class="mt-20 pb-10" width="54" alt="government" /></a> </div>
              <h5 class="topmargin_0"> <a href="government.html">Government</a> </h5>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 to_animate no_appear_delay" data-animation="fadeInDown" data-delay="300">
            <div class="teaser top_offset_icon main_bg_color2 rounded text-center">
              <div class="teaser_icon size_small round main_bg_color2"> <a href="corporate.html"><img src="assets/images/corporate-icon.png" width="54" class="mt-20 pb-10" alt="corporate" /></a> </div>
              <h5 class="topmargin_0"> <a href="corporate.html">Corporate</a> </h5>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 to_animate no_appear_delay" data-animation="fadeInDown" data-delay="300">
            <div class="teaser top_offset_icon main_bg_color3 rounded text-center">
              <div class="teaser_icon size_small round main_bg_color3"> <a href="banking.html"><img src="assets/images/banking-icon.png" width="54" class="mt-20 pb-10" alt="Banking" /></a> </div>
              <h5 class="topmargin_0"> <a href="banking.html">Banking</a> </h5>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 to_animate no_appear_delay" data-animation="fadeInDown" data-delay="600">
            <div class="teaser top_offset_icon main_bg_color4 rounded text-center">
              <div class="teaser_icon size_small round main_bg_color4"> <a href="insurance.html"><img src="assets/images/ecology.png" width="54" class="mt-20 pb-10" alt="Insurance" /></a></div>
              <h5 class="topmargin_0"> <a href="insurance.html">Insurance</a> </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about" class="ls section_padding_top_30 section_padding_bottom_50">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1 col-sm-12 text-center section_padding_bottom_50">
           <!-- <h2 class="section_header">A Digital Platform</h2>-->
            <p class="bold grey digi pb-20">A Digital platform which integrates the Agriculture services and provides a public layer to navigate the farming lands and services of farmers through user friendly web interface along with Satellite reporting services.</p>
            <p class="black pb-20">This is a unique platform provides incubation layer for the Agriculture services where crop dependent farmers are showcased to Businesses directly from the farmers with better prices when compared to the market.</p>
            <p><img src="assets/images/farmer.png" class="img-responsive" alt="Digital platform" /></p>
          </div>
        </div>
      </div>
    </section>
    <section class="ds parallax services section_padding_top_50 section_padding_bottom_50">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h3 class="section_header text-center">Our Eco System Services</h3>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">A platform which enables the user to navigate farming lands and services of farmers in a real-time geo spatial view.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">Incubation layer for agriculture services through crop dependent business owners by promoting agri-investments.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">Showcasing of Agriculture services in a platform enables to trigger the production plan from productive farming lands and farmers.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">Reason behind the raise of prices on the consumable products.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">Middlemen eradication in the market and gain control on supply and demand.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">To encourage and save the farmer service to overcome the market crisis.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">To make farmer and farming as a service accountable and recognized as a corporate service.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">To showcase the farming services in IT application to have better understanding for the actual consumers.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">To save farmers/consumers annual expenditure by delivering the quality of products direct from farm to consumer line.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">We believe in complete transparency of all activities and transactions. To ensure complete transparency, you will be provided with regular updates on the progress of the crops, stage of the crop, photographs of the farmers involved, photographs of the actual fields etc.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">The main risk involved in farming is improper management of the farm. Usually many people think that the common risks are water scarcity, pest, diseases etc. But in reality, there are control measures for all these and could be avoided by taking necessary precautions measures.</div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">In addition to the update of the crop status, we also conduct regular field visits during and we generate dashboard reports depending on the data we collect.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">Selection of land plays a major role in averting the risk in farming. We make a careful selection of land to take up agriculture and we consider various factors like availability of irrigation source, soil factors, social conditions prevailing in the nearby area and we considered only the lands which has major productivity.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">We group the cluster of farmers as a service enabler if there is any prediction is calculated due to nature calamities, we take the load from other cluster of farmers from other region for the same crop and the disaster can be managed.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">We also take up a stringent selection process when onboarding farmers. Various factors like farming experience, past crop yields, social status of the farmer, creditworthiness and reference check are considered when onboarding a farmer for engagement with us. Considering various factors, we arrive at a point evaluating a farmer on variety of criteria. Only farmer who have a minimum score are onboarded as members in this engagement.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">From a financial perspective of your investment, though the likelihood of incurring a major loss is very remote, a loss in a season can be compensated by a profit in the other two seasons of a year. So, on an annual basis, our customers will end up in a profit at the year end, though this is not a guarantee that you will receive profit.</div>
            </div>
            <div class="media small-media">
              <div class="media-left"> <i class="fa fa-check icon"></i> </div>
              <div class="media-body">We trigger the operations of the order pushed by business and make sure we cover the lands during the disaster situations as well.</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" class="ls section_padding_top_50 section_padding_bottom_50">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-sm-4"> <!--<span class="small-text big highlight2"> Testimonials </span>-->
            <h2 class="section_header">Testimonials</h2>
            <div id="testimonials-dots" class="owl-dots topmargin_50"></div>
          </div>
          <div class="col-sm-8 col-lg-9">
            <div class="owl-carousel" data-responsive-lg="3" data-responsive-md="2" data-nav="false" data-dots="true" data-dots-container="#testimonials-dots">
              <blockquote class="loop-color">
                <div class="media bottommargin_20">
                  <div class="media-left media-middle">
                    <div class="avatar"> <img src="assets/images/DineshKumar.png" alt="DineshKumar" > </div>
                  </div>
                  <div class="media-body media-middle">
                    <h6>Dinesh Kumar IAS</h6>
                    <span class="small-text highlight">Managing Director, AP Fibernet</span> </div>
                </div>
                <p class="black">Showcasing farmer profiles with their services helps departmental bodies for aggregating services...</p>
              </blockquote>
              <blockquote class="loop-color">
                <div class="media bottommargin_20">
                  <div class="media-left media-middle">
                    <div class="avatar"><img src="assets/images/Sarabjot.png" alt="Sarabjot" > </div>
                  </div>
                  <div class="media-body media-middle">
                    <h6>Sarabjot Singh Anand</h6>
                    <span class="small-text highlight">Co-Founder & Chief Data Scientist</span> </div>
                </div>
                <p class="black">Collaboration of Agriculture and Fintech in one single ecosystem for farmer benefits...</p>
              </blockquote>
              <blockquote class="loop-color">
                <div class="media bottommargin_20">
                  <div class="media-left media-middle">
                    <div class="avatar"> <img src="assets/images/ysjagan.png" alt="ysjagan" > </div>
                  </div>
                  <div class="media-body media-middle">
                    <h6>Y S Jagan Mohan Reddy</h6>
                    <span class="small-text highlight">Hon'ble Chief Minister, Andhra Pradesh</span> </div>
                </div>
                <p class="black">This kind of a system needs to be integrated to RBK centres to 
                  administer and control...</p>
              </blockquote>
              <blockquote class="loop-color">
                <div class="media bottommargin_20">
                  <div class="media-left media-middle">
                    <div class="avatar"> <img src="assets/images/ShenTong1.png" alt="Shen Tong" > </div>
                  </div>
                  <div class="media-body media-middle">
                    <h6>Shen Tong</h6>
                    <span class="small-text highlight">Managing Partner, Founder, Investor</span> </div>
                </div>
                <p class="black">This is the project which can a global access to PRI and social impact funds where...</p>
              </blockquote>
              <blockquote class="loop-color">
                <div class="media bottommargin_20">
                  <div class="media-left media-middle">
                    <div class="avatar"> <img src="assets/images/Anjaneyulu.png" alt="Anjaneyulu" > </div>
                  </div>
                  <div class="media-body media-middle">
                    <h6>Anjaneyulu</h6>
                    <span class="small-text highlight">Farmer</span> </div>
                </div>
                <p class="black">Supporting small hold farmers during the agriculture marketing layer at post-harvest...</p>
              </blockquote>
              <blockquote class="loop-color">
                <div class="media bottommargin_20">
                  <div class="media-left media-middle">
                    <div class="avatar"> <img src="assets/images/Sahara.png" alt="M Sahara Jabeen" > </div>
                  </div>
                  <div class="media-body media-middle">
                    <h6>M Sahara Jabeen</h6>
                    <span class="small-text highlight">School Teacher & Founder's Mother</span> </div>
                </div>
                <p class="black">Being a school teacher, I feel really proud that my son being an engineer is creating...</p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="ls ms section_padding_top_50 section_padding_bottom_50">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
            <a href="awards-recognition.html"><img src="assets/images/gallery/thumb-1.jpg" alt="Awards Recognition" class="img-responsive img-thumbnail" /></a>
            <h6 class="text-center"><a href="awards-recognition.html">Awards & Recognition</a></h6>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
            <a href="connects.html"><img src="assets/images/gallery/thumb-11.jpg" alt="Connects" class="img-responsive img-thumbnail" /></a>
            <h6 class="text-center"><a href="connects.html">Connects</a></h6>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
            <a href="journey.html"><img src="assets/images/gallery/thumb-38.jpg" alt="Government" class="img-responsive img-thumbnail" /></a>
            <h6 class="text-center"><a href="journey.html">Journey</a></h6>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
            <a href="leaders-meet.html"><img src="assets/images/gallery/thumb-9.jpg" alt="Leaders Meet" class="img-responsive img-thumbnail" /></a>
            <h6 class="text-center"><a href="leaders-meet.html">Leaders Meet</a></h6>
          </div>
        </div>
      </div>
    </section>
    
  </div>
</div>