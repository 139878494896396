<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2>Banking</h2>
        <ol class="breadcrumb greylinks color4">
          <li> <a href="index.html"> Home </a> </li>
          <li> <a href="solutions.html"> Solutions </a> </li>
          <li class="active">Banking</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50">
  <div class="container">
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking"><img src="assets/images/icon42.png"
              alt="Farmland Navigation" /></a></p>
        <h5>Free Virtual cards</h5>
        <p class="grey show-less-div-1">HireMyFarmer is one of the solutions to integrate the payment gateway services
          with a free flow and ease to access to customer, and farmer with Virtual credit/debit card services to make
          sure the optimized supply chain.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/CKHi_KfDOKU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking" class="btn btn-success btn-sm"
          (click)="showEnquiry('Free Virtual cards')">Enquiry Now</a>
        </p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking"><img src="assets/images/icon43.png"
              alt="Farmer community centre" /></a></p>
        <h5>Payment gateway integration services</h5>
        <p class="grey show-less-div-1">Pre & Post-harvest management services includes, the availing of lot of
          third-party payments which revolves around the farmers, HireMyFarmer has designed the scope for payment
          gateway services. Looking for the right partner with API integration to make sure both retail customers and
          farmers gets covered in this layer to optimize the supply chain operations.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/7047B58rewg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking" class="btn btn-success btn-sm"
          (click)="showEnquiry('Payment gateway integration services')">Enquiry Now</a>
        </p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking"><img src="assets/images/icon44.png"
              alt="market" /></a></p>
        <h5>Micro finance service integration</h5>
        <p class="grey show-less-div-1">Line of Credits, customer order management and managing the commercials to make
          sure there is no delay either from customer’s end or from farmer’s end. HireMyFarmer service integration with
          a financial institution will optimize the banking services and scope of value-added features can be promoted
          in parallel with HireMyFarmer solution which brings business to Financial institution.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/B_wedcWA36U" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking" class="btn btn-success btn-sm"
          (click)="showEnquiry('Micro finance service integration')">Enquiry Now</a>
        </p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking"><img src="assets/images/icon45.png"
              alt="Stabilization of farm income" /></a></p>
        <h5>Integration of Line of credit</h5>
        <p class="grey show-less-div-1">Export markets can be targeted with this integration of service from
          HireMyFarmer, where payments make ease to access for business customers & supporting farmers.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/0cVaFM25LrA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking" class="btn btn-success btn-sm"
          (click)="showEnquiry('Integration of Line of credit')">Enquiry Now</a>
        </p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking"><img src="assets/images/icon46.png"
              alt="market" /></a></p>
        <h5>Payment to farmers</h5>
        <p class="grey show-less-div-1">Payments to farmers is not happening directly with end customers or end
          businesses, HireMyFarmer is filling this gap to make sure each farmers payout happens at timely intervals.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/CKHi_KfDOKU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking" class="btn btn-success btn-sm"
          (click)="showEnquiry('Payment to farmers')">Enquiry Now</a>
        </p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking"><img src="assets/images/icon1.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>Farmer-retail banking services</h5>
        <p class="grey show-less-div-1">Farmers can be promoted through financial institutions, where HireMyFarmer
          curates the exact farmers who are in need of investments for doing their business. Retail-Bankers can actually
          help these farmers to encourage Agri-investments from their customer base and increase the scope of services,
          for more information you can get in touch with HireMyFarmer team to understand our services and synergies.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/FrQ2aGBB1Vg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking" class="btn btn-success btn-sm"
          (click)="showEnquiry('Farmer-retail banking services')">Enquiry Now</a>
        </p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking"><img src="assets/images/icon47.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>KYC services integration</h5>
        <p class="grey show-less-div-1">KYC services to understand the type of customer and farmer where the relation
          needs to be in a secured platform with Authentication, Authorization, Audit happens at right intervals to make
          sure the healthy risk-free bonding between the Agri supply chain.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/ZZqz8R7aarI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking" class="btn btn-success btn-sm"
          (click)="showEnquiry('KYC services integration')">Enquiry Now</a>
        </p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking"><img src="assets/images/icon48.png"
              alt="Farmer community centre" /></a></p>
        <h5>Commodity trading services</h5>
        <p class="grey show-less-div-1">HireMyFarmer and Bankers can make wonders where the scope of Agriculture
          Marketing can be utilized by Banking/Financial institutions for a healthy trading network through our
          services, making sure the Bandwidth of these Agri-Marketing Extension services are integrated within Banking
          services.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/mmnKKyA35Z8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking" class="btn btn-success btn-sm"
          (click)="showEnquiry('Commodity trading services')">Enquiry Now</a>
        </p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking"><img src="assets/images/icon49.png"
              alt="market" /></a></p>
        <h5>Agri-fintech services</h5>
        <p class="grey show-less-div-1">Agri Investments are the futuristic vision of HireMyFarmer where it is so clear
          that in future farmers service is also chargeable it was the prediction of HireMyFarmer and making sure Farmer
          as a service gets promoted through this solution, for collaboration ventures, HireMyFarmer Team is always
          there to support Agri/Farmer/Farm investment solutions.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/cFPhZyYi0Nk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-banking" class="btn btn-success btn-sm"
          (click)="showEnquiry('Agri-fintech services')">Enquiry Now</a>
        </p>
      </div>
    </div>
  </div>
</section>
<div class="modal trans-05" tabindex="-1" role="dialog" id="enquiry-banking">
    <div class="modal-dialog" role="document">
    <form id="frmContact" novalidate="novalidate" #myform="ngForm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{selectedEnquiry}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetButton()">
            <span aria-hidden="true">&times;</span> </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="name" [(ngModel)]="enquiryModel.name" name="name" placeholder="Full Name"
                  title="Name" class="form-control" aria-required="true" required>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="designation" [(ngModel)]="enquiryModel.designation" name="designation"
                  placeholder="Designation" title="Designation" class="form-control" aria-required="true" required>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="phone" [(ngModel)]="enquiryModel.phone" name="phone" [pattern]="mobNumberPattern"
                  #mobNumber="ngModel" placeholder="Phone Number" title="Phone" class="form-control phone"
                  aria-required="true" (focusout)="onFocusOutEvent($event)" required>
              </div>
              <div class="form-group" *ngIf="mobNumber.errors">
                <div *ngIf="mobNumber.errors.pattern">
                  Mobile number not valid.
                </div>
              </div>
              <div class="form-group" *ngIf="!otpHide">
                <input type="text" id="otp" [(ngModel)]="otp" name="otp" placeholder="Enter OTP" title="OTP"
                  class="form-control phone" aria-required="true" required>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input ngModel type="text" id="email" [(ngModel)]="enquiryModel.emailId" name="emailId"
                  placeholder="Email ID" title="Email" class="form-control email" aria-required="true" required
                  [pattern]="emailPattern" #emailPtrn="ngModel">
              </div>
              <div class="form-group" *ngIf="emailPtrn.errors">
                <div *ngIf="emailPtrn.errors.pattern">
                  Invalid email is entered.
                </div>
              </div>
              <div class="form-group" *ngIf="!otpHide">
                <button class="btn btn-primary" (click)="verifyOtp()">Veriy OTP</button>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <textarea id="comment-content" [(ngModel)]="enquiryModel.message" name="message" rows="3"
                  placeholder="Message" class="form-control"></textarea>
              </div>
            </div>
          </div>
        </div>
        <span>{{response.message}}</span>
        <div class="modal-footer" >

          <button type="submit" class="btn btn-primary" value="Submit" [disabled]="clicked"
            (click)="saveEnquiry(myform.value)">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>