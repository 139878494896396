<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2>Business</h2>
        <ol class="breadcrumb greylinks color4">
          <li> <a href="index.html"> Home </a> </li>
          <li> <a href="solutions.html"> Solutions </a> </li>
          <li class="active">Business</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="ls section_padding_top_50 section_padding_bottom_50">
  <div class="container">
    <div class="row pb-50">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p class="text-center"><a href="#" data-toggle="modal" data-target="#enquiry-business"><img
              src="assets/images/icon1.png" alt="retail" /></a></p>
        <h5 class="text-center">Farm to retail</h5>
        <ul class="grey">
          <li>Dedicated farmers are getting assigned to retailers</li>
          <li>Supporting digital payments to farmers</li>
          <li>Better price to retailers</li>
          <li>Helps to track of logistics and deliver </li>
        </ul>
        <div class="vid-slider">
          <div class="vid-wrapper">
            <div class="vid item">
                <iframe width="213" height="119" src="https://www.youtube.com/embed/s6IQDwgF1NE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="video-popup">
          <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span class="close-video"></span>
          </div>
        </div>
        <p class="text-center"><a href="#" data-toggle="modal" data-target="#enquiry-business"
            class="btn btn-success btn-sm"
            (click)="showEnquiry('Farm to retail')">Enquiry Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p class="text-center"><a href="#" data-toggle="modal" data-target="#enquiry-business"><img
              src="assets/images/icon36.png" alt="Farm fintech (B2B)" /></a></p>
        <h5 class="text-center">Farm fintech</h5>
        <ul class="grey">
          <li>A corporate or an individual can invest on farming </li>
          <li>The returns will be shared between farmer and an investor with better margins for crop.</li>
          <li>Investors can keep track of their investment and expenditure </li>
        </ul>
        <div class="vid-slider">
          <div class="vid-wrapper">
            <div class="vid item">
                <iframe width="213" height="119" src="https://www.youtube.com/embed/Q8cs2AKmtCw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="video-popup">
          <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span class="close-video"></span>
          </div>
        </div>
        <p class="text-center"><a href="#" data-toggle="modal" data-target="#enquiry-business"
            class="btn btn-success btn-sm"
            (click)="showEnquiry('Farm fintech')">Enquiry Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p class="text-center"><a href="#" data-toggle="modal" data-target="#enquiry-business"><img
              src="assets/images/icon37.png" alt="retail" /></a></p>
        <h5 class="text-center">Vending Machine </h5>
        <ul class="grey">
          <li>A social impact IOT based vending machine where farmers will be promoted</li>
          <li>Farmers get direct access to end consumers with great marginal rate</li>
          <li>Easy accessible and affordable to end consumers</li>
          <li>A consumer can know the origin of produced harvest</li>
        </ul>
        <div class="vid-slider">
          <div class="vid-wrapper">
            <div class="vid item">
                <iframe width="213" height="119" src="https://www.youtube.com/embed/3kz9nmLjbkQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="video-popup">
          <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span class="close-video"></span>
          </div>
        </div>
        <p class="text-center"><a href="#" data-toggle="modal" data-target="#enquiry-business"
            class="btn btn-success btn-sm"
            (click)="showEnquiry('Vending Machine')">Enquiry Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business"><img src="assets/images/icon4.png"
              alt="Farmland Navigation" /></a></p>
        <h5>Farmland Navigation</h5>
        <p class="grey show-less-div-1">HireMyFarmer integrates satellite imagery services where a productive farm land
          can be navigated used to keep track source of farming.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/3tpPU9JbIC8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business" class="btn btn-success btn-sm"
          (click)="showEnquiry('Farmland Navigation')">Enquiry Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business"><img src="assets/images/icon38.png"
              alt="Farmer community centre" /></a></p>
        <h5>Order management with transparent invoice</h5>
        <p class="grey show-less-div-1">HireMyFarmer provides transparent invoice to the consumers, consumers will have
          access to know the updates on crop harvest, allocated farmer, farmer procurement cost, refining cost,
          packaging cost, logistics cost and origin of the produced harvest. Buyers can keep track of their order
          through our technology which helps them for their stock management before season with better price.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/z-6BqQFpwis" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business" class="btn btn-success btn-sm"
          (click)="showEnquiry('Order management with transparent invoice')">Enquiry Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business"><img src="assets/images/icon27.png"
              alt="market" /></a></p>
        <h5>Systematic investment plan</h5>
        <p class="grey show-less-div-1">HireMyFarmer brings an opportunity to public to invest on crops to save farmers
          and encourage farming through government bodies.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/7yoU9GC9Zlo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business" class="btn btn-success btn-sm"
          (click)="showEnquiry('Systematic investment plan')">Enquiry Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business"><img src="assets/images/icon32.png"
              alt="Stabilization of farm income" /></a></p>
        <h5>Stabilization of farm income</h5>
        <p class="grey show-less-div-1">HireMyFarmer connects farmers to actual business enterprises to bring
          stabilization of farm income in small hold farmers life.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/docaQkXFjt4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business" class="btn btn-success btn-sm"
          (click)="showEnquiry('Stabilization of farm income')">Enquiry Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business"><img src="assets/images/icon3.png"
              alt="market" /></a></p>
        <h5>Agri investments</h5>
        <p class="grey show-less-div-1">An individual or a group can invest on pre or post-harvest production through
          HireMyFarmer agri inputs where our market place will add value to it which in turn gives fruitful returns to
          both the farmer and investor.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/LpGdEFUmJrE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business" class="btn btn-success btn-sm"
          (click)="showEnquiry('Agri investments')">Enquiry Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business"><img src="assets/images/icon39.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>Consumable plans for retailers</h5>
        <p class="grey show-less-div-1">HireMyFarmer understands retailers point of sale for their business consumption
          and help them by providing with monthly consumable plan to overcome demand & supply in market. HireMyFarmer
          also inspects retailer’s yearly sale of Agri-products and gives a predictive data which helps to take decision
          in planning the harvest of crop for every season.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/aXJbxAeRJS4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business" class="btn btn-success btn-sm"
          (click)="showEnquiry('Consumable plans for retailers')">Enquiry Now</a></p>
      </div>
    </div>
    <div class="row pb-50 text-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business"><img src="assets/images/icon20.png"
              alt="Farmer stock exchange" /></a></p>
        <h5>Crop land monitoring</h5>
        <p class="grey show-less-div-1">HireMyFarmer has integrated crop monitoring services through which a productive
          crop land can be monitored and studied by research scholars and institutes, also the data can be facilitated
          to banks, insurance, government and departmental bodies.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/3tpPU9JbIC8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business" class="btn btn-success btn-sm"
          (click)="showEnquiry('Crop land monitoring')">Enquiry Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business"><img src="assets/images/icon40.png"
              alt="Farmer community centre" /></a></p>
        <h5>Quality assurance</h5>
        <p class="grey show-less-div-1">HireMyFarmer maintains quality standards right from farm to fork through
          technological integration by implementing best practices followed across pre and post-harvest layers.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/KNTASRzBU5I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business" class="btn btn-success btn-sm"
          (click)="showEnquiry('Quality assurance')">Enquiry Now</a></p>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business"><img src="assets/images/icon41.png"
              alt="market" /></a></p>
        <h5>Retail store locators</h5>
        <p class="grey show-less-div-1">HireMyFarmer facilitates the nearest retailer to the farmer where the farmer can
          directly connect to retailer after post-harvest services.</p>
          <div class="vid-slider">
            <div class="vid-wrapper">
              <div class="vid item">
                  <iframe width="213" height="119" src="https://www.youtube.com/embed/XXAVBrCcoLE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="video-popup">
            <div class="iframe-wrapper"><iframe width="700" height="400" src="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <span class="close-video"></span>
            </div>
          </div>
        <p><a href="#" data-toggle="modal" data-target="#enquiry-business" class="btn btn-success btn-sm"
          (click)="showEnquiry('Retail store locators')">Enquiry Now</a></p>
      </div>
    </div>
    <div class="row pb-50">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <h5>Revenue sharing</h5>
        <ul class="timeline grey">
          <li><strong>Production Model:</strong> We are providing farmer services to FMCGs, retailers market dealers ,
            agri based product manufacturers can actually plan their production of dependent crops with us here we will
            be facilitating farmers and farms for their dedicated business with this their production or procurement
            cost can be reduced.</li>
          <li><strong>Advertising + Commission Model:</strong>Business entities such as cleaning, Machinery, pesticides,
            fertilizers, labours, seeds promoting the farmers and farmer services will be promoted directly through
            HireMyFarmer channel.</li>
          <li><strong>Fee for service Model:</strong> Third party Inspection, Monitoring of crop land with report,
            Incubation profit ROI, advertising model are such service models where the commission fee can be generated.
            This is the estimated minimum profit for the service operations.</li>
          <li><strong>Auction Model:</strong> HireMyFarmern can have 40-50% of profit only when the excess stock is
            managed properly and showcases the warehouses and cold storages.</li>
          <li><strong>Data Subscription Model:</strong> Can be served for our customers and users on request through
            subscribing in our website where our internal operations team will acknowledge depending on the query
            whether it is crop or region to get the farmer details. Farmers on-boarding ,subscription 100/year/farmer to
            keep track of the asset for the corporates (Banks/Insurance companies)</li>
          <li><strong>Online Retail stores:</strong> We are partnering with online-commerce where we are promoting
            individual farmer brands by facilitating farmers to end consumer market and also promoting farmers with a
            new brand.</li>
          <li><strong>Organic Farming Companies:</strong> A new exclusive premium agriculture marketing layer where we
            are collaborating with ZBNF kind of organizations where by facilitating premium organic farming lands and
            farm practices to organic vendors to make sure farmers get a premium margins by connecting to premium
            vendors directly. Considering the market of Organic HireMyFarmer is also trying to make sure that Organic
            should be accessible to common man at affordable price for a healthy lifestyl.</li>
          <li><strong>Small hold retail outlets:</strong> Facilitating line of credits to payment gateway integration
            and also facilitating production units to small hold retails outlets where they can bear the production cost
            of the product by reducing the burden to a farmer and also by gaining good margin profit for their small
            hold business.</li>
          <li><strong>Crop dependent retail manufactures:</strong> HireMyFarmer connects farmers through material
            management layer which helps crop dependent retailer manufactures to overcome contact farming related
            issues.</li>
          <li><strong>Bio pesticide & Bio Fertilizer companiess:</strong> In pre-harvesting layer we connect these bio
            pesticide companies by reducing their distribution and marketing channel burdens where Hiremyfarmer
            facilitates direct farmers to bio pesticide and fertilizer company services.</li>
          <li><strong>Seed Input companies:</strong> HireMyFarmer encourages quality seed manufacturers to farmers only
            if they pass the assessment and valuation right from the beginning of pre-harvesting services , HireMyFarmer
            Technology keep track of the sourced production land with a report of the manufacturer along with sourced
            cluster of farming lands to make sure the relationship of quality is managed for long-term and during
            cluster based decisions we promote these seed manufacturers to Government bodies as well.</li>
          <li><strong>Rental Machinery:</strong> When farmers gets on-boarded to HireMyFarmer system then through
            farmer’s hub the farmers will be facilitated to nearby location based rental machineries for pre and
            post-harvest services.</li>
          <li><strong>Logistics:</strong> HireMYFarmer partners with well-maintained and organized logistics firm who
            has access to connect the rural and urban cities for availing the services of transport to move stock from
            warehouses, farmers, processing units and also to deliver it to end consumers free a hassle free, risk
            coverage logistic services.</li>
          <li><strong>Crop land Monitoring (Individual service):</strong> This unique service in HireMyFarmer
            facilitates an individual / Business entity to avail crop land monitoring service where vegetation, harvest,
            crop validation indices can be tracked and report will be facilitated seasonally.</li>
          <li><strong>Do you need a farmer for your Crop land:</strong> HireMyFarmer corporate layer covers in sourcing
            the best community farmers to your farmlands where their expertise and dedication of service will return
            revenues from your un utilized farming lands , this service can be availed by both individual or by business
            entity.</li>
          <li><strong>ROI (Invest on a crop):</strong> Agri-investment covers seasonal crops which are open for
            investment with a financial institution& Government support, This service of revenue can be availed by
            individual/business.</li>
          <li><strong>Third party contracts:</strong> HireMyFarmer MoU’s & Service level agreements are very strict
            especially dealing with Third-parties as a part of service integrator “HireMyFarmer” generates mutual
            business for every resourcing partner, we are looking for a long-term business partners to handle the hassle
            free operations without any customer escalations ,feedback integration plays a major role for assessment of
            Third-party services which will be routed directly to HireMyFarmer to make sure the renewal of service at
            right intervals.</li>
        </ul>
        <p class="text-center"><a href="#" data-toggle="modal" data-target="#enquiry-business"
            class="btn btn-success btn-sm"
            (click)="showEnquiry('Revenue sharing')">Enquiry Now</a></p>
      </div>
    </div>
  </div>
</section>
<div class="modal trans-05" tabindex="-1" role="dialog" id="enquiry-business">
  <div class="modal-dialog" role="document">
    <form id="frmContact" novalidate="novalidate" #myform="ngForm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{selectedEnquiry}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetButton()">
            <span aria-hidden="true">&times;</span> </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="name" [(ngModel)]="enquiryModel.name" name="name" placeholder="Full Name"
                  title="Name" class="form-control" aria-required="true" required>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="designation" [(ngModel)]="enquiryModel.designation" name="designation"
                  placeholder="Designation" title="Designation" class="form-control" aria-required="true" required>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input type="text" id="phone" [(ngModel)]="enquiryModel.phone" name="phone" [pattern]="mobNumberPattern"
                  #mobNumber="ngModel" placeholder="Phone Number" title="Phone" class="form-control phone"
                  aria-required="true" (focusout)="onFocusOutEvent($event)" required>
              </div>
              <div class="form-group" *ngIf="mobNumber.errors">
                <div *ngIf="mobNumber.errors.pattern">
                  Mobile number not valid.
                </div>
              </div>
              <div class="form-group" *ngIf="!otpHide">
                <input type="text" id="otp" [(ngModel)]="otp" name="otp" placeholder="Enter OTP" title="OTP"
                  class="form-control phone" aria-required="true" required>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <input ngModel type="text" id="email" [(ngModel)]="enquiryModel.emailId" name="emailId"
                  placeholder="Email ID" title="Email" class="form-control email" aria-required="true" required
                  [pattern]="emailPattern" #emailPtrn="ngModel">
              </div>
              <div class="form-group" *ngIf="emailPtrn.errors">
                <div *ngIf="emailPtrn.errors.pattern">
                  Invalid email is entered.
                </div>
              </div>
              <div class="form-group" *ngIf="!otpHide">
                <button class="btn btn-primary" (click)="verifyOtp()">Veriy OTP</button>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="form-group">
                <textarea id="comment-content" [(ngModel)]="enquiryModel.message" name="message" rows="3"
                  placeholder="Message" class="form-control"></textarea>
              </div>
            </div>
          </div>
        </div>
        <span>{{response.message}}</span>
        <div class="modal-footer" >

          <button type="submit" class="btn btn-primary" value="Submit" [disabled]="clicked"
            (click)="saveEnquiry(myform.value)">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>