import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EnquiryService {
  
  apiUrl: string = 'https://api.hiremyfarmer.com';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  errorMsg: string; 

  constructor(private http: HttpClient) { }

  saveEnquiry(enquiryDetails : EnquiryDetails) : Observable<any>{
    console.log(enquiryDetails);
    return this.http.post<EnquiryDetails>(this.apiUrl+'/enquiry', enquiryDetails)
      // .subscribe(
      //   data  => {

      //     console.log("POST Request is successful ", data);
      //     return data;
      //     },error => {

      //       console.log("error", error);
            
      //       }
      // );
     // return null;
   }   


  

  // error(error: HttpErrorResponse) {
  //   let errorMessage = '';
  //   if (error.error instanceof ErrorEvent) {
  //     errorMessage = error.error.message;
  //   } else {
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   console.log(errorMessage);
  //   return throwError(errorMessage);
  // }

  sendOtp(phone : String) : Observable<any>{
    console.log(phone);
   return this.http.post<EnquiryDetails>(this.apiUrl+'/sendOtp', {"phoneNumber":phone})
   return null;
  }

  isPhoneNumberExists(phone : String) : Observable<any>{
    console.log(phone);
   return this.http.get<SimpleResponse>(this.apiUrl+'/isPhoneExists/'+phone)
  // return null;
  }

  isPhoneNumberExistsV2(phone : String,dialCode: String) : Observable<any>{
    console.log(phone);
   return this.http.get<SimpleResponse>(this.apiUrl+'/isPhoneExistsV2/'+phone+'/dialCode/'+dialCode)
  // return null;
  }

  isEmailExists(email : String) : Observable<any>{
    console.log(email);
   return this.http.get<SimpleResponse>(this.apiUrl+'/isEmailExists/'+email)
  // return null;
  }

  verifyOtp(otp : Otp) : Observable<any>{
    console.log(otp);
    return this.http.post<EnquiryDetails>(this.apiUrl+'/verifyOtp', otp)
  }

  sendForgotpasswordDetails(email : String) : Observable<any>{
    console.log(email);
   return this.http.get<SimpleResponse>(this.apiUrl+'/forgotPassword/'+email)
  // return null;
  }

  subscribeUser(emailId: String) : Observable<any>{
    console.log(emailId);
    return this.http.post<any>(this.apiUrl+'/subscribe', {"emailId":emailId})      
   }
}
export class EnquiryDetails{

    name: String;
    phone: String;
    emailId: String;
    category: String;
    type: String;
    message: String;
    designation: String;
}
export class Otp{

  otp: String;
  phoneNumber: String;
  
}
export class SimpleResponse{

  messageType: String;
  message: String;
  code: String
}