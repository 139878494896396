import { Component, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { EnquiryDetails, EnquiryService } from '../enquiries/enquiry.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  enquiryModel : EnquiryDetails;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";  
  showbutton = false;
  
  constructor(private location: Location,private renderer2: Renderer2,private router : Router,
    private ngxSmartModalService: NgxSmartModalService,private cookie: CookieService,
    private enquiryService: EnquiryService) {
   location.replaceState("/")
  }
  ngOnInit(): void {
    this.enquiryModel = new EnquiryDetails();
  }
  ngAfterViewInit(){
    if(this.cookie.get("skipped")!=="true"){
      this.ngxSmartModalService.getModal('sampleModal').open();
    }
    
  }
  showPage(){
    this.ngxSmartModalService.getModal('sampleModal').close();
    this.cookie.set("skipped","true");
    this.enquiryService.saveEnquiry(this.enquiryModel).subscribe(data => {
      
    })
  }
    

  }
