<section class="page_breadcrumbs ds parallax section_padding_top_40 section_padding_bottom_40">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h2>Leaders Meet</h2>
          <ol class="breadcrumb greylinks color4">
            <li> <a href="index.html"> Home </a> </li>
            <li> <a href="gallery.html"> Gallery </a> </li>
            <li class="active">Leaders Meet</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="ls section_padding_top_50 section_padding_bottom_50">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="project-col">
            <div class="hover-box"> 
              <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-9.jpg" alt="HireMyFarmer" />
              <div class="hover-box-content">
                <ul class="icon">
                  <li><a class="gallery" href="assets/images/gallery/gallery-9.jpg" 
                    data-lightbox="lightbox" data-title="Met Hon'ble Chief Minister YS Jagan Mohan Reddy sir in Andhra Pradesh and took few steps ahead to implement this solution in Andhra Pradesh, 
                    we have got good support and sign off to support our project from supreme leader of Andhra Pradesh to get it implemented with in state"><i class="fa fa-search"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
       <div class="col-md-3 col-sm-6">
          <div class="project-col">
            <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-34.jpg" alt="HireMyFarmer" />
              <div class="hover-box-content">
                <ul class="icon">
                  <li><a class="gallery" href="assets/images/gallery/gallery-34.jpg" data-lightbox="lightbox" data-title="Your title"><i class="fa fa-search"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div> 
        <div class="col-md-3 col-sm-6">
          <div class="project-col">
            <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-30.jpg" alt="HireMyFarmer" />
              <div class="hover-box-content">
                <ul class="icon">
                  <li><a class="gallery" href="assets/images/gallery/gallery-30.jpg" data-lightbox="lightbox" 
                    data-title="Met few leaders in Punjab, who has supported our idea and business and looking for a change 
                    in farmers life"><i class="fa fa-search"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="project-col">
            <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-33.jpg" alt="HireMyFarmer" />
              <div class="hover-box-content">
                <ul class="icon">
                  <li><a class="gallery" href="assets/images/gallery/gallery-33.jpg" data-lightbox="lightbox" 
                    data-title="Met the leader 	&ldquo;Sardar V.M Singh ji	&rdquo; in Uttar Pradesh who has invited us to join 	
                    &ldquo;Kisan Morcha&rdquo; to raise voice to out reach the Government to prioritize and to resolve problems 
                    faced by farmers in Delhi to support farmers and to help farmers community"><i class="fa fa-search"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="project-col">
            <div class="hover-box"> <img class="fixed-hight280 fixed-hight210" src="assets/images/gallery/thumb-42.jpg" alt="HireMyFarmer" />
              <div class="hover-box-content">
                <ul class="icon">
                  <li><a class="gallery" href="assets/images/gallery/gallery-42.jpg" data-lightbox="lightbox" 
                    data-title="Met few leaders in Andhra pradesh, who has supported our idea and business"><i class="fa fa-search"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>