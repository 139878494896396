import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FarmLandsService {
  apiUrl: string = 'https://api.hiremyfarmer.com';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  errorMsg: string;

constructor(private http: HttpClient) { }

getLandDetails():Observable<Number[][][]>{
  return this.http.get<Number[][][]>(this.apiUrl+'/onboardedLandDetailsV2').pipe();
}

getOnboardedLandDetailsV2ByFarmerId(farmerId: Number):Observable<Number[][][]>{
  return this.http.get<Number[][][]>(this.apiUrl+'/onboardedLandDetailsV2/farmerId/'+farmerId).pipe();
}

getFarmerByFarmerId(farmerId: Number):Observable<any>{
  return this.http.get<any>(this.apiUrl+'/farmerV2/'+farmerId).pipe();
}
}