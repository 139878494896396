import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RegistrationDetails } from './register/register.component';

@Injectable({
  providedIn: 'root'
})
export class UserRegistrationService {
  apiUrl: string = 'https://api.hiremyfarmer.com';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  errorMsg: string;
  constructor(private http: HttpClient) { }
  
 saveUser(registrationDetails : RegistrationDetails) : Observable<any>{
    console.log(registrationDetails);  
    return this.http.post<RegistrationDetails>(this.apiUrl+'/selfregistration', registrationDetails)
      
   }   
}
